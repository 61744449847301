import { callInternalApi, getErrorMessage } from "@kanpla/system";
import {
  SubmitAdyenAuthenticationEndpointArgs,
  SubmitAdyenAuthenticationEndpointResponse,
} from "@kanpla/types";
import { message } from "@kanpla/ui";
import { useMutation } from "@tanstack/react-query";
import { useHandleAdyenThreeDSResultCode } from "./AdyenPaymentComponent";

export const useSubmitAdyenAuthenticationResult = (cardOptions?: {
  isAddingCard?: boolean;
  closePaymentDrawer?: () => void;
}) => {
  const { mutate: handleResultCode } =
    useHandleAdyenThreeDSResultCode(cardOptions);

  return useMutation({
    mutationFn: ({ threeDSResult }: SubmitAdyenAuthenticationEndpointArgs) => {
      return callInternalApi<
        SubmitAdyenAuthenticationEndpointArgs,
        SubmitAdyenAuthenticationEndpointResponse
      >("payment/submit-adyen-authentication", { threeDSResult });
    },
    onSuccess: (data) => {
      handleResultCode(data, {
        onSuccess: (resultString) => {
          message.success(resultString, { toastId: "payment-result-code" });
        },
        onError: (err) => {
          message.error(getErrorMessage(err), {
            toastId: "payment-result-code",
          });
        },
      });
    },
  });
};
