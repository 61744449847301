import { setNativeKeyboardOffset } from "@kanpla/system";
import Drawer from "@mui/material/Drawer";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import classNames from "classnames";
import React, { useCallback, useEffect } from "react";
import { CloseButton } from "../CloseButton";
import { Actions } from "./Actions";
import { BottomDrawerOrModalCommonProps } from "./DrawerOrModal";

export interface BottomDrawerProps extends BottomDrawerOrModalCommonProps {
  children: React.ReactChild;
  drawerClassName?: string;
  floatingActions?: boolean;
  drawerWrapperClassName?: string;
}

export const BottomDrawer = (props: BottomDrawerProps) => {
  const {
    open,
    setOpen,
    children,
    title,
    subtitle,
    showCloseButton = false,
    drawerClassName = "bg-background-primary",
    zIndex = 40,
    actions = [],
    noPadding,
    floatingActions = false,
    zMax,
    closableOutside = true,
    actionsClassName = "",
    noPaddingOnActions = false,
    drawerWrapperClassName,
  } = props;

  const hasActions = actions.length > 0;

  // Reset to default offset when umounting the component
  useEffect(() => {
    return () => {
      setNativeKeyboardOffset(-90);
    };
  }, []);

  const measuredRef = useCallback((node) => {
    if (!node) return;

    const elementHeight = node.getBoundingClientRect().height;
    if (elementHeight >= 400) return;
    if (elementHeight <= 280) {
      setNativeKeyboardOffset(-10);
      return;
    }

    setNativeKeyboardOffset(-50);
  }, []);

  const Component = closableOutside ? SwipeableDrawer : Drawer;

  return (
    <Component
      anchor="bottom"
      open={open}
      onClose={() => closableOutside && setOpen(false)}
      onOpen={() => setOpen(true)}
      className={classNames("relative", drawerWrapperClassName)}
      style={{ zIndex: zMax ? "999" : zIndex }}
      PaperProps={{
        elevation: 0,
        className: "!border-none",
        style: {
          backgroundColor: "transparent",
          paddingTop: "10rem",
          zIndex: 9,
        },
      }}
      variant="temporary"
    >
      <div
        className={`${drawerClassName} relative w-full pb-6`}
        style={{ borderRadius: "24px 24px 0 0" }}
        ref={measuredRef}
      >
        {open && showCloseButton && (
          <CloseButton
            className="bg-background-secondary "
            onClick={() => setOpen(false)}
          />
        )}
        {closableOutside ? (
          <header className="p-3 flex items-center justify-center relative z-50">
            <span className="block bg-background-secondary rounded-lg w-12 h-1"></span>
          </header>
        ) : null}
        <main className="w-full flex flex-col justify-center mt-3 relative text-text-primary">
          {title && (
            <h1 className="title-secondary text-text-primary text-center px-6 relative z-10">
              {title}
            </h1>
          )}
          {subtitle && (
            <h3 className="text-text-secondary text-center px-6">{subtitle}</h3>
          )}
          <div className={`${noPadding ? "p-0" : "p-6"}`}>{children}</div>
        </main>
        {open && hasActions && (
          <div
            className={`${
              floatingActions ? "sticky" : "relative"
            } bottom-0 w-full bg-gradient-to-b from-transparent to-background-primary`}
            style={{
              paddingBottom: `calc(var(--safe-inset-bottom) + 20px + 6rem)`,
            }}
          >
            <div
              className={classNames(
                "flex flex-wrap justify-center whitespace-nowrap w-full p-2 overflow-hidden",
                actionsClassName
              )}
            >
              <Actions actions={actions} noPadding={noPaddingOnActions} />
            </div>
          </div>
        )}
      </div>
    </Component>
  );
};
