import { callInternalApi, useFetch, useSubmit } from "@kanpla/system";
import { SurveyForm, SurveyResponse } from "@kanpla/types";
import { FetchSurveyByIdProps } from "apps/frontend/pages/api/internal/feedback/fetchSurveyById";
import { FetchSurveyResponsesReturn } from "apps/frontend/pages/api/internal/feedback/fetchSurveyResponses";
import { SubmitSurveyResponseProps } from "apps/frontend/pages/api/internal/submit/submitSurveyResponse";
import { atom, useSetAtom } from "jotai";
import { orderBy } from "lodash";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { feedbackModalAtom } from "./Modal";

export const isOpeningFeedbackModalAtom = atom(false);

export const useFeedback = () => {
  const { childId } = useContainer(AppContext);

  const setFeedbackModal = useSetAtom(feedbackModalAtom);
  const setOpeningModal = useSetAtom(isOpeningFeedbackModalAtom);

  const { data: fetchResponsesData, setData } = useFetch<
    Record<string, never>,
    FetchSurveyResponsesReturn
  >(
    "feedback/fetchSurveyResponses",
    {},
    { revalidateOnFocus: false, revalidateIfStale: false }
  );

  const surveysResponded =
    orderBy(
      fetchResponsesData?.responses?.map((response) => ({
        surveyId: response.surveyFormId,
        dateSeconds: response.dateSecond,
      })),
      "dateSeconds",
      "desc"
    ) || [];

  const { submit: submitSurveyResponse } = useSubmit<
    SubmitSurveyResponseProps,
    SurveyResponse,
    typeof setData
  >({
    path: "submit/submitSurveyResponse",
    setData,
    requestConstructor: (response) => ({
      surveyResponse: response,
      childId,
    }),
    responseDestructor: (newResponse) => {
      return {
        responses: [...(fetchResponsesData?.responses || []), newResponse],
      };
    },
  });

  const openSurveyModal = async (surveyId: string) => {
    try {
      setOpeningModal(true);
      const surveyForm = await callInternalApi<
        FetchSurveyByIdProps,
        SurveyForm
      >("feedback/fetchSurveyById", { surveyId });
      setFeedbackModal({ isOpen: true, surveyForm: surveyForm });
    } catch (e) {
      console.log("No survey found");
    } finally {
      setOpeningModal(false);
    }
  };

  return {
    surveysResponded,
    submitResponse: submitSurveyResponse,
    openSurveyModal,
  };
};
