import { t } from "..";
import danishErrors from "./errors";
import { getReepayErrorText as getReepayErrorTextFn } from "./getReepayErrorText";

export class KanplaError extends Error {
  code: string;

  constructor(code: string, message: string) {
    const errorMessage = code
      ? `${message.startsWith("Error:") ? "" : "Error: "}${
          t(danishErrors[code]) || message
        }`
      : message;

    super(errorMessage);
  }
}

export const getReepayErrorText = getReepayErrorTextFn;
