import { T } from "@kanpla/system";
import { Modal } from "@kanpla/ui";
import React from "react";
import { ButtonProps } from "../../button/Button";

interface Props {
  open: boolean;
  onSubmit: (state: any) => void;
  onClose: () => void;
  title: string;
  children: React.ReactElement;
  cancelButtonText?: React.ReactElement;
  okButtonText?: React.ReactElement;
  okButtonType?: ButtonProps["type"];
}

const Confirm = ({
  open,
  onSubmit,
  onClose,
  title,
  children,
  cancelButtonText,
  okButtonText,
  okButtonType,
}: Props) => {
  const [state, setState] = React.useState();

  return (
    <Modal
      open={open}
      setOpen={(o) => !o && onClose()}
      title={title}
      containerClassName="z-50"
      className="z-50"
      style={{ maxWidth: "24rem" }}
      zIndex={99999}
      actions={[
        {
          onClick: onClose,
          label: cancelButtonText || <T _str="Cancel" />,
          type: "secondary",
        },
        {
          onClick: () => onSubmit(state),
          label: okButtonText || <T _str="Confirm" />,
          type: okButtonType || "primary",
        },
      ]}
    >
      {children &&
        React.cloneElement(children, {
          ...children.props,
          setState,
        })}
    </Modal>
  );
};

const ConfirmationServiceContext = React.createContext(Promise.reject);

export const useConfirmation = () =>
  React.useContext(ConfirmationServiceContext);

export const ConfirmProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = React.useState(null);

  const awaitingPromiseRef = React.useRef();

  const openConfirmation = (options) => {
    setConfirmationState(options);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = (res) => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve(res);
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />
      <Confirm
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
