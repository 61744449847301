import { isNative, isStaging, tx } from "@kanpla/system";
import { BooleanParam, useQueryParam } from "next-query-params";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useState } from "react";

const languageDictionary = {
  da: "da-dk",
  en: "en-us",
};

const CookiesLegalMonster = () => {
  const [show, setShow] = useState(false);
  const [disableCookie] = useQueryParam("disableCookie", BooleanParam);

  const languageCode =
    languageDictionary[tx.getCurrentLocale() || "da"] || "da-dk";

  useEffect(() => {
    if (typeof window === "undefined") return;

    let isPWAinBrowser = true;
    if (
      matchMedia("(display-mode: standalone)").matches ||
      // @ts-ignore
      ("standalone" in navigator && navigator.standalone)
    ) {
      // Android and iOS 11.3+
      isPWAinBrowser = false;
    } else {
      // @ts-ignore
      let standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad|Macintosh/.test(userAgent);

      if (ios) {
        if (!standalone && !safari) {
          isPWAinBrowser = false;
        }
      } else {
        if (userAgent.includes("wv")) {
          isPWAinBrowser = false;
        }
      }
    }

    if (isNative) return setShow(false);

    setShow(isPWAinBrowser);
  }, []);

  if (typeof window === "undefined") return null;

  if ((typeof window !== `undefined` && window?.["Cypress"]) || !show)
    return null;

  if (isStaging) return null;
  if (process.env.NODE_ENV !== "production") return null;

  return (
    <>
      <Script id="legal-moster" strategy="lazyOnload">{`
          !function(){var i,e,t,s=window.legal=window.legal||[];if(s.SNIPPET_VERSION="3.0.0",i="https://widgets.legalmonster.com/v1/legal.js",!s.__VERSION__)if(s.invoked)window.console&&console.info&&console.info("legal.js: The initialisation snippet is included more than once on this page, and does not need to be.");else{for(s.invoked=!0,s.methods=["cookieConsent","document","ensureConsent","handleWidget","signup","user"],s.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);return e.unshift(t),s.push(e),s}},e=0;e<s.methods.length;e++)t=s.methods[e],s[t]=s.factory(t);s.load=function(e,t){var n,o=document.createElement("script");o.setAttribute("data-legalmonster","sven"),o.type="text/javascript",o.async=!0,o.src=i,(n=document.getElementsByTagName("script")[0]).parentNode.insertBefore(o,n),s.__project=e,s.__loadOptions=t||{}},s.widget=function(e){s.__project||s.load(e.widgetPublicKey),s.handleWidget(e)}}}();
          
          legal.widget({
            type: "cookie",
            widgetPublicKey: "rw61FTyLuf7WBq77ge26xcRj",
            brandBackgroundColor: "#48BB78",
            brandTextColor: "white",
            brandInteractionColor: "#38A169",
            locale: "${languageCode}"
          });
        `}</Script>
      <Head>
        <style key="legal-monster-styling">{`
      .legalmonster-cleanslate {
        pointer-events: none !important;
        opacity: ${disableCookie ? "0" : "1"} !important;
      }
      
      .legalmonster-cleanslate .lm-shield-container {
        display: none !important;
        visibility: hidden !important;
        transform: scale(0) !important;
        pointer-events: none !important;
        transition: transform 0.3s ease !important;
      }`}</style>
      </Head>
    </>
  );
};

export default CookiesLegalMonster;
