import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import { RefillCancelRequest } from "@kanpla/services";
import { callInternalApi, useRequest, useT } from "@kanpla/system";
import { Card, User } from "@kanpla/types";
import {
  Button,
  ModalConfirm,
  Radio,
  activeCurrencyAtom,
  message,
} from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { Dispatch, SetStateAction } from "react";
import { useContainer } from "unstated-next";
import CardPreview from "./CardPreview";
interface Props {
  card: Card;
  isSelected: boolean;
  setSelectedId: Dispatch<SetStateAction<string>>;
  setOpen: (nextState: boolean) => void;
  setLoading: (nextState: boolean) => void;
}

const CardItem = (props: Props) => {
  const { card, isSelected, setSelectedId, setLoading } = props;
  const t = useT();
  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const { userId, loadCards, paymentGatewayId, setCards } =
    useContainer(OrderingContext);
  const { request: cancelRefillRequest } = useRequest<
    RefillCancelRequest,
    User
  >("payment/cancelAutomaticRefill");

  const deleteCard = async () => {
    try {
      setLoading(true);

      // Remove reference from the customer document
      const newDefaultCardId = await callInternalApi<
        unknown,
        string | undefined
      >("payment/cardRemove", {
        cardId: card.cardId,
        cardDocId: card.id,
        userId,
        paymentGatewayId,
      });

      message.success(t("Card removed"));

      // Cancel automatic refill
      await cancelRefillRequest({
        userId,
        walletCurrency: activeCurrency,
      });

      setCards((cards) => cards.filter((c) => c.cardId !== card.cardId));
      setSelectedId(newDefaultCardId || "");
    } catch (err) {
      console.error(err);
      message.error(t("Unable to remove card"));
    } finally {
      setLoading(false);
      await loadCards();
    }
  };

  return (
    <div className="flex items-center mb-4">
      <label className="items-center flex flex-1 hover:cursor-pointer">
        <Radio
          value={card.cardId}
          onChange={() => {
            setSelectedId(card.cardId);
          }}
          defaultChecked={isSelected}
          name="select-card"
        />
        <div className="bg-background-secondary rounded-l py-4 px-5 ml-4 flex-1">
          <CardPreview card={card} />
        </div>
      </label>
      <ModalConfirm
        content={t("Are you sure you want to remove the card?")}
        onConfirm={deleteCard}
        danger
        zIndex={1000}
      >
        <Button
          className="rounded-l-none h-auto rounded-r flex items-center justify-center py-[22px] px-6 w-fit"
          type="danger"
          name={card.metadata.last4}
          aria-label={t("Delete credit card")}
          icon={faTrash}
          dataCy="btn-delete-card"
        />
      </ModalConfirm>
    </div>
  );
};

export default CardItem;
