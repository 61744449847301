import {
  _FrontendModule,
  CombinedOfferItem,
  LoadOfferReturn,
  Week,
} from "@kanpla/types";
import { atom, useSetAtom } from "jotai";
import { useEffect, useState } from "react";

type Props = {
  dayIndex: number;
  week: Week;
  offer: LoadOfferReturn;
  isBulk: boolean;
  dateSeconds: number;
  module: _FrontendModule;
};

export const meetingOverwriteOfferAtom = atom<{
  [moduleId: string]: LoadOfferReturn | undefined;
}>({});

export const useOffer = (props: Props) => {
  const { isBulk, dateSeconds, offer: defaultOffer, module } = props;

  /** Meeting modules can load the offer on their own if they order for many weeks ahead */
  const setMeetingOverwriteOffer = useSetAtom(meetingOverwriteOfferAtom);
  const offer = defaultOffer;

  const [filteredLines, setFilteredLines] = useState(offer?.items);
  useEffect(() => {
    const moduleType = module?.type;

    if (moduleType !== "flex") {
      setFilteredLines(offer?.items);
      return;
    }

    const allLines: CombinedOfferItem[] =
      (offer?.items as CombinedOfferItem[]) || [];

    if (moduleType === "flex" && !isBulk) {
      setFilteredLines(
        allLines.filter((item) => item.availableFor?.individual ?? true)
      );
      return;
    }

    if (moduleType === "flex" && isBulk) {
      setFilteredLines(
        allLines.filter((item) => item.availableFor?.admin ?? true)
      );
      return;
    }
  }, [isBulk, module?.type, offer?.items]);

  const {
    holidayDates = [] as LoadOfferReturn["holidayDates"],
    mealOptions = [] as LoadOfferReturn["mealOptions"],
  } = offer || {};
  const allItems = offer?.items || [];

  // it is now possible for the items to be all filtered out, hence fallback
  const items = ((allItems as CombinedOfferItem[]) || []).filter((i) => {
    const isAvailable =
      i.dates?.[dateSeconds]?.available || i.dates?.[dateSeconds]?.reason;
    return isAvailable;
  });

  const noMealplan = items?.length === 0;

  // Add option with holidays
  const activeHoliday = holidayDates?.[dateSeconds];

  const returnValue = {
    offer: {
      ...(props.offer || {}),
      items: filteredLines,
    },
    noMealplan,

    activeHoliday,
    mealOptions,
    holidayDates,
    items: offer?.items || [],
    setMeetingOverwriteOffer,
  };

  return returnValue;
};
