import { db, fetchDocument } from "@kanpla/system";
import { User } from "@kanpla/types";
import { isEmpty } from "lodash";
import moment from "moment";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { isDesktop, isIOS } from "react-device-detect";
import { UserAuth } from "../auth/UseAuth";
import { isExpoPushToken } from "./isExpoPushToken";
import { useFCMNotifications } from "./useFCMNotifications";
import useWebPushNotifications from "./useWebPushNotifications";

interface Props {
  user: User;
  auth: UserAuth;
  setNotificationBadge?: Dispatch<SetStateAction<boolean>>;
}

interface PushToken {
  token: string;
  type: "fcm" | "expo";
}

export const useNotifications = (props: Props) => {
  const { user, auth, setNotificationBadge = () => null } = props;

  const [pushToken, setPushToken] = useState<PushToken | null>(null);

  const { retrieveFCMToken } = useWebPushNotifications(user);

  const { notification } = useFCMNotifications({
    user,
    setNotificationBadge,
  });

  useEffect(() => {
    if (isIOS) return;
    if (!user?.id || !auth?.user) return;

    (async () => {
      try {
        // If existing token is not valid or token is not found, let's fetch a new FCM token
        const fcmToken = await retrieveFCMToken();
        if (!fcmToken) return;

        setPushToken({
          token: fcmToken,
          type: "fcm",
        });
      } catch (err) {
        console.error(err);
      }
    })();
  }, [user?.id, auth?.user]);

  // Fetch token from iOS
  useEffect(() => {
    if (typeof window === "undefined" || !isIOS) return;

    const timer = setInterval(() => {
      const fcmToken = window.iOSToken;
      const expoToken = window.iOSTokenExpo;

      if (fcmToken && !isExpoPushToken(fcmToken)) {
        setPushToken({ token: fcmToken, type: "fcm" });
        clearInterval(timer);
      }

      // Temporarly checking if fcmToken is an Expo token since in the published Expo wrapper of some apps `window.iOSToken` has the same name of the swift wrapper
      if (expoToken || (fcmToken && isExpoPushToken(fcmToken))) {
        setPushToken({
          token: isExpoPushToken(fcmToken) ? fcmToken : expoToken,
          type: "expo",
        });
        clearInterval(timer);
      }
    }, 1000);

    // Clear interval after 5 minutes if no token is detected
    setTimeout(() => {
      clearInterval(timer);
    }, 300000);

    return () => clearInterval(timer);
  }, []);

  // Save to firestore when Token or User changes
  useEffect(() => {
    if (!pushToken || isEmpty(pushToken) || !user || !auth.user) return;
    const { token, type } = pushToken;

    (async () => {
      try {
        const currentTokens =
          (type === "fcm" ? user.fcmTokens : user.expoTokens) || {};

        if (token && !currentTokens[token]) {
          const userDoc = await fetchDocument<User>(
            db.collection("users").doc(auth.user.uid),
            true
          );

          if (type === "fcm") {
            const now = moment.now();

            const platform = isDesktop ? "desktop" : "mobile";
            const oppositePlatform = isDesktop ? "mobile" : "desktop";

            const otherToken = Object.entries(currentTokens || {}).reduce(
              (acc, [token, val]) => {
                if (val?.platform === oppositePlatform) return { [token]: val };

                return acc;
              },
              {}
            );

            const tokens = {
              ...(otherToken || {}),
              [token]: {
                createdAt: now,
                platform,
              },
            };

            await userDoc?.ref?.update({ fcmTokens: tokens });
          } else {
            const tokens = { ...currentTokens, [token]: true };
            await userDoc?.ref?.update({ expoTokens: tokens });
          }
        }
      } catch (err) {
        console.error(err);
      }
    })();
  }, [pushToken, user, auth.user]);

  return { token: pushToken?.token, notification };
};

export default useNotifications;
