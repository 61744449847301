import { Child, Module, School } from "@kanpla/types";
import { intersection } from "lodash";

export interface HasAccessToModuleArgs {
  child?: null | Pick<Child, "selectors" | "codes" | "groupName">;
  school?: null | Pick<School, "modules" | "id" | "hasFlexBulk" | "selectors">;
  module?: Pick<Module, "scope" | "config" | "id" | "paymentMethod">;
  /** Module doesn't need to have active bulk or individual for it to work. Could be useful e.g. for export and statistics */
  ignoreActive?: boolean;
}

const hasAccessThroughModuleSelectors = (
  child?: Pick<Child, "groupName"> | null,
  module?: Pick<Module, "scope">,
  school?: Pick<School, "id"> | null
): boolean => {
  if (!module || !school) return true;
  const moduleSelectors =
    module.scope?.schools?.[school.id]?.selectorStrings || [];
  if (!moduleSelectors.length) return true;
  return !!child && moduleSelectors.includes(child?.groupName);
};

export const getChildCodes = (
  child: Pick<Child, "selectors" | "codes"> | null,
  school?: Pick<School, "selectors"> | null
) => {
  const selectorEntries = child ? Object.entries(child.selectors || {}) : [];

  const selectorCodes = selectorEntries
    .map(([layerName, optionName]) => {
      const targetLayer = school?.selectors?.find(
        (layer) => layer.name === layerName
      );
      const targetOption = targetLayer?.options?.find(
        (o) => o.name === optionName
      );
      const optionCodes = targetOption?.codes || [];
      return optionCodes;
    })
    .flat();

  const allChildCodes = [...(child?.codes || []), ...selectorCodes];

  return allChildCodes;
};

export type HasAccessType = {
  individual: boolean;
  bulk: boolean;
  other: boolean;
};

export const hasAccessToModule = ({
  module,
  child,
  school,
  ignoreActive = false,
}: HasAccessToModuleArgs): HasAccessType => {
  const moduleConfig = module?.config || {};
  const schoolModuleConfig = school?.modules?.[module?.id || "—"] || {};

  const childCodes = getChildCodes(child ?? null, school);

  // Disallow user access to module if they don't fit selectors
  const selectorAccess = hasAccessThroughModuleSelectors(child, module, school);
  if (!selectorAccess) return { individual: false, bulk: false, other: false };

  // Evaluate codes
  const codes = [
    ...(moduleConfig.codes || []),
    ...(schoolModuleConfig.codes || []),
  ];

  const bulkCodes = [
    ...(moduleConfig.bulkCodes || []),
    ...(schoolModuleConfig.bulkCodes || []),
  ];

  const validCodes = (targetCodes: Array<string>) =>
    targetCodes.length === 0 ||
    intersection(targetCodes, childCodes).length > 0;

  // Evaluate enabled/disabled
  const individualDisabled =
    schoolModuleConfig.individualDisabled ?? moduleConfig.individualDisabled;

  const bulkEnabled =
    schoolModuleConfig.bulkEnabled ||
    moduleConfig.bulkEnabled ||
    school?.hasFlexBulk;
  // Both must be true
  const allowIndividual =
    (!individualDisabled || ignoreActive) && validCodes(codes);
  const allowBulk = (bulkEnabled || ignoreActive) && validCodes(bulkCodes);
  const allowOther = validCodes(codes);

  return {
    individual: allowIndividual,
    bulk: allowBulk,
    other: allowOther,
  };
};
