import { DocumentData, Query } from "@firebase/firestore-types";
import { FirestoreDocument } from "@kanpla/types";

interface Options {
  /** Won't filter out deleted documents */
  includeDeleted?: boolean;
}

/**
 * Function to make your querying easier
 * @typeParam T You can define a generic type here
 * @param query Typical firestore query without `.get()` at the end
 * @param includeRef Whether or not should the function add ref to the mix
 * @param options Extended options, see type `Options`
 * @returns Data for the query including ID and possibly REF
 */
export const fetchCollection = async <T extends FirestoreDocument>(
  query:
    | FirebaseFirestore.Query<FirebaseFirestore.DocumentData>
    | Query<DocumentData>,
  includeRef?: boolean,
  options?: Options
): Promise<Array<T>> =>
  query.get().then((res) =>
    res.docs
      .map(
        (d) =>
          ({
            ...d.data(),
            id: d.id,
            ...(includeRef && { ref: d.ref }),
          } as unknown as T)
      )
      .filter((o: T) => options?.includeDeleted || !o.deleted)
  );
