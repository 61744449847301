import axios, { AxiosRequestConfig } from "axios";
import { getAuth } from "firebase/auth";
import { APIError } from "../errors/api";

export interface APICallOptions {
  /** Hide error message, in case you want to handle the error in your own way */
  noErrorMessage?: boolean;
  /** Custom token that will override the default behavior of CallInternalAPI. Used for signing in `signInAnonymously(..)` */
  customToken?: string;
  /** If we should return raw response or data */
  returnRaw?: boolean;
  /** POST by default if some data is provided */
  method?: AxiosRequestConfig["method"];
  /** Optionally specify return type */
  responseType?: AxiosRequestConfig["responseType"];
}

/**
 * # Issue a request to internal API.
 *
 * The function takes care of:
 * - sending the request
 * - adding authorization data
 * - handling any exceptions
 *   - To set custom HTTP status code, throw exception satisfying:
 *     ```ts
 *     interface ExceptionSupportingHTTPStatusCode {
 *      response: {
 *        status: number,
 *      }
 *     }
 *     ```
 *   - To set custom HTTP message, throw exception satisfying:
 *     ```ts
 *     interface ExceptionSupportingHTTPMessage {
 *      data: {
 *        message: string,
 *      }
 *     }
 *     ```
 *
 * @param path - The prefix `/api/internal/` gets automatically added
 * @param data - Body of the request
 * @param options {@link APICallOptions}
 * @returns Response body from the server or `null` (or raw Axios response if the param `option` was specified)
 */
export const callInternalApi = async <RequestData, ResponseType>(
  path: string,
  data?: RequestData,
  options?: APICallOptions
): Promise<ResponseType> => {
  const auth = getAuth();
  const user = auth?.currentUser;
  const token = options?.customToken ?? (await user?.getIdToken());

  if (!window) {
    throw new Error("callInternalAPI is not available on the server side");
  }
  const hostname = window.location.hostname;
  const isLocalhost = hostname === "localhost";
  const isVercelTest = hostname?.includes("vercel.app");
  const environment = isLocalhost ? "DEV" : isVercelTest ? "TEST" : "PROD";

  try {
    const res = await axios({
      method: options?.method ?? (data ? "POST" : "GET"),
      url: `/api/internal/${path}`,
      headers: {
        Authorization: "Bearer " + token,
        "kanpla-auth-provider": "GAuth",
        "kanpla-debug": true,
        "kanpla-app-env": environment,
      },
      data: data ?? {},
      responseType: options?.responseType ?? "json",
    });

    if (options?.returnRaw) return res as ResponseType;

    return res.data as ResponseType;
  } catch (e) {
    // Handle axios exceptions
    if (axios.isAxiosError(e) && e.response) {
      const status = e.response.status;
      const text = e.response.statusText;
      console.error(`[callInternalAPI] failed with code: ${status} (${text})`);

      const customMessage = e.response.data.message;
      console.error(`[callInternalAPI] our custom message: ${customMessage}`);

      throw new APIError(status, customMessage, e.response.data.errors);
    }

    // Handle unknown errors
    console.error("[callInternalAPI] unknown error: ", e);
    throw new APIError(500, "unknown error", undefined);
  }
};
