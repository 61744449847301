import { faCheck, faSquare } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { useMergeRefs } from "rooks";
import { BaseInputProps } from "./BaseInput";

interface BaseCheckboxInputProps
  extends Omit<BaseInputProps, "value" | "onChange" | "design" | "label"> {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  labelLeft?: boolean;
  indeterminate?: boolean;
  children?: React.ReactNode;
}

type CheckboxInputProps = BaseCheckboxInputProps &
  Partial<
    Omit<
      InputHTMLAttributes<HTMLInputElement>,
      "type" | "value" | "onChange" | "children"
    >
  >;

export const Checkbox: React.FC<CheckboxInputProps> = React.forwardRef<
  HTMLInputElement,
  CheckboxInputProps
>((props, ref): JSX.Element => {
  const {
    checked = null,
    onChange = null,
    className = "",
    disabled = false,
    labelLeft = false,
    color = "primary",
    indeterminate = false,
    children,
    required,
    dataCy,
    error = false,
    ...rest
  } = props;

  const inputRef = useRef<HTMLInputElement>(null);

  const inputRefs = useMergeRefs(ref, inputRef);

  useEffect(() => {
    if (!inputRef?.current) return;

    inputRef.current.indeterminate = indeterminate;
  }, [inputRef, indeterminate]);

  // bg-input-primary, bg-input-secondary, text-primary-main, text-secondary-main, text-primary-contrast, text-secondary-contrast, outline-primary-focus, outline-secondary-focus, outline-danger-focus

  const classes = classNames(
    "w-7 h-7 md:w-5 md:h-5 rounded-sm cursor-pointer border flex items-center justify-center transition-all ease-in-out p-1",
    { "border-danger-focus border-danger-main outline-danger-focus": error },
    { [`border-${color}-focus`]: !disabled },
    {
      "bg-divider-main text-text-disabled border-divider-main cursor-not-allowed":
        disabled,
    },
    {
      [`bg-input-${color} border-divider-main outline-${color}-focus text-${color}-main`]:
        indeterminate && !checked && !disabled,
    },
    {
      [`bg-${color}-main border-${color}-main animate-pulse-click-primary text-${color}-contrast`]:
        checked && !disabled,
    },
    {
      [`bg-input-${color} border-divider-main outline-${color}-focus text-${color}-contrast`]:
        !checked && !indeterminate,
    },
    className
  );

  return (
    <label
      className={classNames("flex items-center cursor-pointer")}
      htmlFor={rest.id}
    >
      {children && (
        <span
          className={classNames("form-label text-base md:text-sm ml-2", {
            "order-last": !labelLeft,
          })}
        >
          {children}
        </span>
      )}
      {required ? (
        <span className="font-medium mr-1 text-danger-main">*</span>
      ) : null}
      <div className={classes} tabIndex={0}>
        {checked && (
          <FontAwesomeIcon
            icon={faCheck}
            className={`text-${color}-contrast`}
          />
        )}
        {indeterminate && !checked && (
          <FontAwesomeIcon icon={faSquare} size="sm" />
        )}
        <input
          id={rest.id}
          data-cy={dataCy}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          type="checkbox"
          hidden
          ref={inputRefs}
          {...rest}
        />
      </div>
    </label>
  );
});
