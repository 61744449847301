import { useEffect, useState } from "react";

const MOBILE_SIZE = 768;

type WindowSize = {
  width?: number;
  height?: number;
  isMobileSize?: boolean;
};

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    isMobileSize: false,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      if (!window) return;

      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobileSize: window.innerWidth < MOBILE_SIZE,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;
