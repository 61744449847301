import {
  faCheckCircle,
  faCreditCard,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  PaymentOnceProps,
  RecurringPaymentEndpointProps,
  SessionResponse,
} from "@kanpla/services";
import {
  T,
  callInternalApi,
  getErrorMessage,
  getReepayErrorText,
  priceFormatter,
  tx,
  useT,
} from "@kanpla/system";
import { AutomaticRefill } from "@kanpla/types";
import {
  Alert,
  Checkbox,
  DotDotDot,
  DrawerOrModal,
  Spinner,
  activeCurrencyAtom,
  message,
} from "@kanpla/ui";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import { basketContainerTotalPriceAtom } from "../../basket/useBasket";
import ChoosePaymentMethod from "../ChoosePaymentMethod";
import useChargeSession from "../UseChargeSession";
import { adyenPaymentCheckoutConfigAtom } from "../adyen/AdyenPaymentComponent";
import AdyenPaymentMethods from "../adyen/AdyenPaymentMethods";
import useWindowPayment from "../useWindowPayment";
import CreditCardInfo from "./CreditCardInfo";
import ErrorModal, { CardErrorProps } from "./ErrorModal";
import OngoingRefillWarning from "./OngoingRefillWarning";
import RefillAmount, { currencyNumbers } from "./RefillAmount";
import RefillSettings from "./RefillSettings";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
}

const Divider = () => {
  const t = useT();
  return (
    <div className="pt-8 pb-2">
      <div className="border-b w-full border-divider-main flex items-center justify-center h-0 select-none">
        <div className="px-2 relative bg-background-primary text-text-secondary text-opacity-70 italic text-xs">
          {t("or")}
        </div>
      </div>
    </div>
  );
};

type RecurringPaymentResponse = {
  success: boolean;
  error?: string;
  paymentId: string;
};

const PaymentModal = (props: Props) => {
  const { open, setOpen } = props;

  const t = useT();

  const {
    user,
    userId,
    balance,
    card,
    setBalance,
    schoolId,
    paymentGatewayProvider,
  } = useContainer(OrderingContext);

  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);
  const [refillAmount, setRefillAmount] = useState(basketContainerTotalPrice);
  const [loading, setLoading] = useState(false);
  const [refillOpen, setRefillOpen] = useState(false);
  const [rememberCard, setRememberCard] = useState(true);
  const [paymentMethod, setPaymentMethod] = useAtom(selectedPaymentMethodAtom);
  const setAdyenCheckoutConfig = useSetAtom(adyenPaymentCheckoutConfigAtom);
  const { callbackUrl } = useWindowPayment({
    mode: "credit",
    setReceiptOpen: () => true,
    setReceiptTime: () => true,
    setCheckoutItems: () => true,
  });
  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const activeNumbers = currencyNumbers[activeCurrency || "DKK"];
  const showRefill = (card && paymentMethod === "card") ?? false;

  const [cardError, setCardError] = useState<CardErrorProps>({
    text: t("Payment failed"),
  });

  const [cardErrorModal, setCardErrorModal] = useState(false);
  const [activeAutomaticRefill, setActiveAutomaticRefill] = useState<
    AutomaticRefill | undefined
  >(
    user?.refillData?.find(
      (refillData) =>
        refillData.currency === activeCurrency &&
        refillData.paymentGatewayId === card?.paymentGatewayId
    )
  );

  useEffect(
    () => setRefillAmount(basketContainerTotalPrice),
    [basketContainerTotalPrice]
  );

  // On successful one time payment
  const { loadChargeSession } = useChargeSession({
    onError: (err) => {
      setCardError(getReepayErrorText(err.error || getErrorMessage(err)));
      setCardErrorModal(true);
      setLoading(false);
    },
    setLoading,
  });

  const chargeCustomer = async () => {
    setLoading(true);

    const { cardId } = card || {};

    try {
      const data = await callInternalApi<
        RecurringPaymentEndpointProps,
        RecurringPaymentResponse
      >("payment/paymentRecurring", {
        cardId,
        unitPrice: refillAmount,
        schoolId,
        userId,
      });

      // If the payment wasn't right
      if (data.success) {
        setBalance(balance + refillAmount);
        message.success(t("Payment successful"));
      } else {
        console.error(data);
        data.error && setCardError(getReepayErrorText(data.error));
        setCardErrorModal(true);
      }
    } catch (err) {
      console.error(err);
      setCardErrorModal(true);
      message.error(t("Payment failed"));
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };

  const chargeOneTime = async () => {
    setLoading(true);

    try {
      const isCardPayment = paymentMethod === "card";

      const { sessionId, provider, sessionData } = await callInternalApi<
        PaymentOnceProps,
        SessionResponse
      >("payment/paymentOnce", {
        unitPrice: refillAmount,
        recurring: isCardPayment ? rememberCard : false,
        paymentMethod,
        isWindowSession: true,
        callbackUrl,
        mode: "credit",
        schoolId,
      });

      // Remember payment method
      setPaymentMethod(paymentMethod);

      const payload =
        provider === "adyen"
          ? { provider, sessionId, sessionData }
          : { provider, sessionId };

      const session = await loadChargeSession(payload);
      if (session.provider === "adyen") {
        setAdyenCheckoutConfig({
          options: session.config,
          onSuccess: () => setOpen(false),
          rememberCard,
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setCardErrorModal(true);
      message.error(t("Payment failed"));
      setLoading(false);
    }
  };

  const isAmountValid = (newAmount: number) => {
    if (newAmount === 0 || newAmount < activeNumbers[0]) {
      return false;
    }
    return true;
  };

  const showInvalidAmountMessage = () => {
    const formatted = priceFormatter(
      activeNumbers[0] * 100,
      activeCurrency,
      tx.getCurrentLocale()
    );
    message.info(t("Choose an amount over {formatted}.", { formatted }), {
      messageId: "min-amount-deposit",
    });
  };

  const disabledForNoPaymentMethod = !paymentMethod && !card;

  const actions = loading
    ? []
    : paymentGatewayProvider === "adyen" && paymentMethod !== "card"
    ? [
        isAmountValid(refillAmount) ? (
          <AdyenPaymentMethods
            selectedRefillAmount={refillAmount}
            closePaymentDrawer={() => setOpen(false)}
          />
        ) : (
          <Alert
            type="warning"
            message={<T _str="Please select a valid amount" />}
          />
        ),
      ]
    : [
        {
          type: "primary",
          disabled: disabledForNoPaymentMethod,
          onClick: async () => {
            if (!isAmountValid(refillAmount)) {
              showInvalidAmountMessage();
              return;
            }

            if (card && paymentMethod === "card") {
              await chargeCustomer();
            } else {
              await chargeOneTime();
            }
          },
          label: (
            <>
              <FontAwesomeIcon
                icon={faCreditCard}
                className="mr-2 fa-swap-opacity"
              />
              {t("Deposit")}
            </>
          ),
          dataCy: "deposit-credit-balance",
        } as const,
      ];

  return (
    <>
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={t("Deposit money")}
        subtitle={t("How much credit do you want to add?")}
        showCloseButton
        actions={actions}
        actionsClassName="!z-40 !overflow-visible"
        zMax
      >
        {loading ? (
          <div className="my-8 max-w-xs m-auto pb-6 flex flex-col items-center justify-center">
            <div className="py-3">
              <Spinner size={"medium"} />
            </div>
            <p className="text-center text-sm text-text-secondary pt-4">
              <span role="img" aria-label="tid">
                ⌛
              </span>{" "}
              {t("Payment could be slow ⌛")}
              <DotDotDot />
            </p>
          </div>
        ) : (
          <>
            <div className="mt-8 max-w-xs m-auto">
              <OngoingRefillWarning />
              {/* for UI purposes the price is in the main currency unit */}
              <RefillAmount
                amount={refillAmount / 100}
                setAmount={(newUIAmount) => setRefillAmount(newUIAmount * 100)}
              />
              <ChoosePaymentMethod className="mt-4 w-full" />
              <div className="flex justify-center pt-5 border-divider-main" />
              {!card && paymentMethod === "card" && (
                <div className="flex justify-center mt-3">
                  <Checkbox
                    onChange={(e) => setRememberCard(e.target.checked)}
                    checked={rememberCard}
                    className="text-text-primary"
                    data-cy="checkbox-save-card"
                  >
                    {t("Save the card for later payments")}
                  </Checkbox>
                </div>
              )}
            </div>
            {showRefill && <Divider />}
            <div className="pt-1 text-base">
              {!activeAutomaticRefill && showRefill && (
                <button
                  className="block text-primary-main mx-auto font-semibold py-2"
                  onClick={() => setRefillOpen(true)}
                >
                  {t("Setup automatic refill")}
                </button>
              )}
              {!!activeAutomaticRefill && showRefill && (
                <button
                  className="block text-primary-main mx-auto font-semibold py-2"
                  onClick={() => setRefillOpen(true)}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />{" "}
                  {t("Automatic refill")}
                  <p className="text-text-secondary text-sm font-normal">
                    {t(
                      "under {refillBelow} {activeCurrency} add {refillAmount} {activeCurrency}",
                      {
                        // amount is stored in the smallest currency unit
                        refillBelow: activeAutomaticRefill.refillBelow / 100,
                        refillAmount: activeAutomaticRefill.refillAmount / 100,
                        activeCurrency,
                      }
                    )}
                  </p>
                  <p className="text-text-secondary text-sm font-semibold underline mt-1">
                    {t("Edit").toLowerCase()}
                  </p>
                </button>
              )}
            </div>
          </>
        )}
        {card && paymentMethod === "card" && (
          <div className="pt-9 md:pt-0">
            <CreditCardInfo />
          </div>
        )}
      </DrawerOrModal>
      {/* ERROR MODAL */}
      <ErrorModal
        open={cardErrorModal}
        setOpen={setCardErrorModal}
        cardError={cardError}
        setCardError={setCardError}
      />
      {user && (
        <RefillSettings
          open={refillOpen}
          setOpen={setRefillOpen}
          paymentGatewayId={card?.paymentGatewayId}
          hasAutomaticRefill={!!activeAutomaticRefill}
          updateParentRefillData={(newRefillValue) =>
            setActiveAutomaticRefill(newRefillValue)
          }
        />
      )}
    </>
  );
};

export default PaymentModal;
