import { useLayoutEffect } from "react";
import { isNative } from "../native";

// Hook
export const useBodyLockScroll = (
  querySelector?: string,
  overflow?: CSSStyleDeclaration["overflow"]
) => {
  const definedOverflow = overflow || isNative ? "auto" : "initial";

  useLayoutEffect(() => {
    const component = document.querySelector<HTMLElement>(
      querySelector
        ? querySelector
        : // @ts-ignore
        isNative
        ? "body"
        : "html"
    );

    if (!component) return;

    // Prevent scrolling on mount
    component.style.overflow = "hidden";
    // Re-enable scrolling when component unmounts
    return () => {
      component.style.overflow = definedOverflow;
    };
  }, []); // Empty array ensures effect is only run on mount and unmount
};
