/* eslint-disable @typescript-eslint/ban-ts-comment */
import classnames from "classnames";
import React, { ReactNode } from "react";
import { ActionButton, ActionButtonProps } from "./ActionButton";

export type Action = ReactNode | ActionButtonProps;

interface Props {
  actions: Action[];
  withFlex?: boolean;
  noPadding?: boolean;
  className?: string;
}

export const Actions = ({
  actions,
  withFlex = true,
  noPadding = false,
  className = "",
}: Props) => {
  return (
    <div
      className={classnames(
        "w-full gap-2",
        { flex: withFlex },
        noPadding ? "p-0" : "p-3",
        className
      )}
    >
      {actions.map((action, index) => {
        // If element
        const isElement = React.isValidElement(action);
        if (isElement) {
          return (
            <div className="w-full" key={`action-element-${index}`}>
              {action as ReactNode}
            </div>
          );
        }

        // If button
        return (
          <ActionButton
            key={`action-button-${index}`}
            {...(action as ActionButtonProps)}
          />
        );
      })}
    </div>
  );
};
