import { useFetch } from "@kanpla/system";
import {
  LoadPartialPartnerProps,
  LoadPartialPartnerResult,
} from "../pages/api/internal/load/partialPartner";

export const usePartialPartner = (partnerId: string | null) => {
  // TODO: Migrate to Tanstack
  const { data: partialPartner } = useFetch<
    LoadPartialPartnerProps,
    LoadPartialPartnerResult
  >(
    "load/partialPartner",
    {
      partnerId,
    },
    {
      isPaused: () => !partnerId,
      revalidateOnFocus: false,
    }
  );

  return partialPartner;
};
