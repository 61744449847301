import { OrderLine } from "@kanpla/types";
import { sumBy } from "lodash";

export const calculateConfigTotal = (options: OrderLine["options"]) => {
  const optionValues = Object.values(options || {});

  const total = sumBy(optionValues, (option) => {
    const { amount = 0, unitExtraPrice = 0 } = option || {};
    const optionTotal = amount * unitExtraPrice;
    return optionTotal;
  });

  return total;
};
