import classNames from "classnames";
import { useState } from "react";
import { useIntersectionObserverRef } from "rooks";

const DayHeader = ({
  label,
  withMargin,
  mobile,
}: {
  label: string;
  withMargin: boolean;
  mobile: boolean;
}) => {
  const [isScrolling, setIsScrolling] = useState(false);

  const callback = (entries: any) =>
    setIsScrolling(entries?.[0]?.intersectionRatio < 1);

  const [divRef] = useIntersectionObserverRef(callback, {
    threshold: [1],
  });

  return (
    <div
      ref={mobile ? null : divRef}
      className={classNames(
        "w-full px-4 pt-2 pb-4 bg-background-primary transition-all ease-in-out text-text-primary",
        isScrolling && "shadow-sm z-10",
        !mobile && "sticky"
      )}
      style={{
        top: "-1px",
      }}
    >
      <h1 className={classNames("heading-uppercase", { "mt-2": !withMargin })}>
        {label}
      </h1>
    </div>
  );
};

export default DayHeader;
