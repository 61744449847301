import { callInternalApi, getErrorMessage } from "@kanpla/system";
import { Card, User } from "@kanpla/types";
import { message } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { paymentGatewayIdAtom } from "../components/contextProvider";
import {
  CardGetDefaultCallProps,
  CardGetDefaultCallReturn,
} from "../pages/api/internal/payment/cardGetDefaultCall";

interface CustomerCardArgs {
  userId: User["id"];
  card: Card;
  cards: Card[];
}

// is valid if paymentGatewayId is not set or if card.paymentGatewayId === paymentGatewayId
const isValidCard = (card: Card, paymentGatewayId?: string) => {
  if (!paymentGatewayId) return true;
  return card.paymentGatewayId === paymentGatewayId;
};

const filterCardsByPaymentGatewayId = (
  cards: Card[],
  paymentGatewayId?: string
) => {
  return cards.filter((c: Card) => isValidCard(c, paymentGatewayId));
};

export const useCustomerCard = ({
  userId,
  card: receivedCard,
  cards: receivedCards,
}: CustomerCardArgs) => {
  const [cards, setCards] = useState<Card[]>([]);
  const [card, setCard] = useState<Card>();

  const paymentGatewayId = useAtomValue(paymentGatewayIdAtom);

  const loadCards = async () => {
    if (!userId) return;
    console.warn("loading cards");
    try {
      const res = await callInternalApi<
        CardGetDefaultCallProps,
        CardGetDefaultCallReturn
      >("payment/cardGetDefaultCall", { userId, paymentGatewayId });
      const { defaultCardId, cards: allCards = [] } = res;

      const filteredCards = filterCardsByPaymentGatewayId(
        allCards,
        paymentGatewayId
      );

      setCards(filteredCards || []);

      const defaultCard = filteredCards.find(
        (c: Card) => c.id === defaultCardId && isValidCard(c, paymentGatewayId)
      );

      setCard(defaultCard);
    } catch (e) {
      message.error(getErrorMessage(e));
      console.error(e);
    }
  };

  useEffect(() => {
    if (receivedCard && isValidCard(receivedCard, paymentGatewayId)) {
      setCard(receivedCard);
    } else {
      const filteredCards = filterCardsByPaymentGatewayId(
        receivedCards,
        paymentGatewayId
      );

      const defaultCard = filteredCards.find(
        (c: Card) =>
          c.id === receivedCard?.id && isValidCard(c, paymentGatewayId)
      );
      setCard(defaultCard);
    }
  }, [receivedCard, paymentGatewayId]);

  useEffect(() => {
    const filtered = filterCardsByPaymentGatewayId(
      receivedCards,
      paymentGatewayId
    );

    setCards(filtered);
  }, [receivedCards, paymentGatewayId]);

  return { card, cards, setCard, setCards, loadCards };
};
