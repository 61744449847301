import { isNative, useT } from "@kanpla/system";
import { message } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { Dispatch, SetStateAction, useEffect } from "react";
import { confirmationModalOpenAtom } from "../../mealplan2/basket/elements/PurchaseAnimation";
import {
  checkoutItemsAtom,
  kanplaGoReceiptOpenAtom,
  receiptTimeAtom,
} from "../../mealplan2/kanplaGo/useKanplaGoListener";
import { basketResetAtom } from "../basket/useBasket";
import useChargeSession from "./UseChargeSession";
import useWindowPayment from "./useWindowPayment";

interface Props {
  /** Sets the loading of the payment action button */
  setLoading: Dispatch<SetStateAction<boolean>>;
  hasKanplaGo: boolean;
}

/**
 * Handles state and callbacks related to payment inside the basket
 */
const useBasketPayment = (props: Props) => {
  const { setLoading, hasKanplaGo } = props;
  const setReceiptOpen = useSetAtom(kanplaGoReceiptOpenAtom);
  const setReceiptTime = useSetAtom(receiptTimeAtom);
  const setCheckoutItems = useSetAtom(checkoutItemsAtom);
  const setConfirmationModalOpen = useSetAtom(confirmationModalOpenAtom);

  const kanplaGoCallback = () => {
    if (!hasKanplaGo) {
      setConfirmationModalOpen(true);
      setTimeout(() => setConfirmationModalOpen(false), 3000);
    }
  };

  const t = useT();
  const basketReset = useSetAtom(basketResetAtom);

  /** Payment method selected by the user to make the payment */
  /** Remembers the card of the user, if payment is card */

  /** If provider === reepay -> Create reepay window session
   * If provider === adyen -> Create adyen checkout
   */
  const { loadChargeSession } = useChargeSession({
    setLoading,
    onError: (error) => message.error(error.error),
  });

  /** Listen on payment success and provides callback url for reepay */
  const { callbackUrl } = useWindowPayment({
    mode: "order",
    setReceiptOpen,
    setReceiptTime,
    setCheckoutItems,
    callback: () => {
      basketReset();
      kanplaGoCallback();
    },
  });

  /* Catch `cancel` event from the native wrapper */
  const handleCancelFromWrapper = (e: MessageEvent) => {
    const { isApplePayCanceledByUser } = JSON.parse(e.data || {});

    if (isApplePayCanceledByUser) {
      setLoading(false);
      message.warning(t("Payment was canceled. Please try again"), {
        messageId: "payment-canceled",
      });
    }
    window.removeEventListener("message", handleCancelFromWrapper);
  };

  useEffect(() => {
    // Listen on messages from native wrapper (handles user cancel event)
    if (isNative) {
      window.addEventListener("message", handleCancelFromWrapper);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    /** If provider === "reepay" -> Opens a reepay session in window mode;
     * If provider === "adyen" -> Returns checkout */
    loadChargeSession,
    /** The `url` that will be opened after the window payment is made */
    callbackUrl,
  };
};

export default useBasketPayment;
