import { isSafari } from "react-device-detect";
import { isNative } from "..";

/**
 * Check if a device can support Apple Pay
 * @returns true if the app supports Apple Pay
 */
export const isApplePaySupported = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return isSafari || (isNative && /iphone|ipod|ipad/.test(userAgent));
};
