import { OrderingContext } from "@kanpla/ordering";
import { FC } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const OrderingContextWrapper: FC = ({ children }) => {
  const appContextData = useContainer(AppContext);

  return (
    <OrderingContext.Provider initialState={appContextData}>
      {children}
    </OrderingContext.Provider>
  );
};

export default OrderingContextWrapper;
