import { Plugins, _FrontendModule } from "@kanpla/types";

type Props = {
  module: _FrontendModule;
  /** Optional, to filter out modules out of this scope */
  schoolId?: string;
  /** Optional, to filter out modules out of this scope */
  groupName?: string;
};

type PluginName = keyof Plugins.Array;

type ActivePlugins = {
  [pluginName in PluginName]: boolean;
};
export const getActivePlugins = ({ module }: Props) => {
  // 1. Pure active plugins
  const activePlugins = Object.fromEntries(
    Object.entries(module?.plugins || {})
      .filter(([_, setup]) => setup.active)
      .map(([k]) => [k, true])
  ) as ActivePlugins;

  // 2. Extra values
  const hasKanplaGo = activePlugins.kanplaGo;
  const hasPayPerOrder = activePlugins.payPerOrder;

  const requiresCredit =
    module?.paymentMethod === "credit" &&
    !activePlugins.kanplaGo &&
    !activePlugins.payPerOrder;

  return {
    activePlugins,
    hasKanplaGo,
    hasPayPerOrder,
    requiresCredit,
  };
};
