import { Message } from "@kanpla/types";
import React from "react";
import { ToastContainer } from "react-toastify";

export const MessageContainer = (
  props: Message.MessageContainerProps = {}
): Message.MessageContainerReturnType => {
  return (
    <ToastContainer
      /** Avoiding message to be covered by the notch */
      style={{
        paddingTop: "calc(env(safe-area-inset-top) + 15px)",
        paddingLeft: "15px",
        paddingRight: "15px",
      }}
      {...props}
    />
  );
};
