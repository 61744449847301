import { OrderingContext } from "@kanpla/ordering";
import { SaveDefaultCardProps } from "@kanpla/services";
import { callInternalApi, useT } from "@kanpla/system";
import { Card } from "@kanpla/types";
import { DrawerOrModal, Spinner, message } from "@kanpla/ui";
import { useEffect, useState } from "react";
import { useContainer } from "unstated-next";
import CardAddAdyen from "./CardAddAdyen";
import CardAddReepay from "./CardAddReepay";
import CardItem from "./CardItem";

interface Props {
  open: boolean;
  setOpen: (nextState: boolean) => void;
  subtitle?: string;
}

const isCardSelected = (card: Card, selectedId: string | undefined) =>
  card?.cardId === selectedId;

const CardsManage = ({ open, setOpen }: Props) => {
  const t = useT();

  const {
    card,
    cards,
    setCard,
    loadCards,
    userId,
    paymentGatewayProvider,
    paymentGatewayId,
  } = useContainer(OrderingContext);

  const defaultCardId = card?.cardId || "";
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState<string>("");

  const isReepay = (paymentGatewayProvider ?? "reepay") === "reepay";

  useEffect(() => {
    if (selectedId) return;

    setSelectedId(defaultCardId);
  }, [defaultCardId, cards, open]);

  const send = async () => {
    if (!selectedId) {
      message.info(t("Select a card"));
      return;
    }

    try {
      await callInternalApi<SaveDefaultCardProps, void>(
        "payment/saveDefaultCard",
        {
          userId,
          paymentGatewayId: paymentGatewayId!,
          cardId: selectedId,
        }
      );

      await loadCards();
      setCard(card);
      message.success(t("Payment method updated"));
      setOpen(false);
    } catch (err) {
      console.error(err);
      message.error(t("Payment method could not be updated"));
    }
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      zMax
      title={t("My cards")}
      subtitle={t("Select your default card")}
      actions={[
        {
          label: t("Confirm"),
          onClick: send,
          className: "primary",
        },
      ]}
    >
      <div className="pt-4 pb-3">
        {loading && (
          <div className="pb-6 flex items-center justify-center">
            <Spinner size="medium" useCurrentColor />
          </div>
        )}
        {!loading &&
          (cards || []).map((card: Card, index: number) => (
            <CardItem
              key={index.toString()}
              card={card}
              isSelected={isCardSelected(card, selectedId)}
              setSelectedId={setSelectedId}
              setOpen={setOpen}
              setLoading={setLoading}
            />
          ))}
        <div className="flex w-full">
          {isReepay ? (
            <CardAddReepay />
          ) : (
            <CardAddAdyen setLoading={setLoading} />
          )}
        </div>
      </div>
    </DrawerOrModal>
  );
};

export default CardsManage;
