import { faTimesCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { SurveyForm } from "@kanpla/types";
import { Modal as UIModal } from "@kanpla/ui";
import { atom, useAtom } from "jotai";
import moment from "moment";
import { Dispatch, SetStateAction } from "react";
import SurveyModel from "./SurveyModel";

export const feedbackModalAtom = atom<{
  isOpen: boolean;
  surveyForm: SurveyForm | null;
}>({ isOpen: false, surveyForm: null });

export type SurveysClosed = { id: string; closedAtSeconds: number }[];

interface Props {
  setSurveysClosed: Dispatch<SetStateAction<SurveysClosed>>;
}

export const Modal = ({ setSurveysClosed }: Props) => {
  const t = useT();

  const [feedbackModal, setFeedbackModal] = useAtom(feedbackModalAtom);
  const { surveyForm, isOpen } = feedbackModal;

  if (!surveyForm) return null;

  return (
    <UIModal
      open={isOpen}
      setOpen={() => {
        setFeedbackModal({ isOpen: false, surveyForm: null });
      }}
      containerClassName="flex items-center"
      stopPropagation={false}
      noPadding
    >
      {/* Close button */}
      <button
        aria-label={t("Close the banner")}
        className="absolute z-max right-3 top-8 p-1 px-2 rounded-full bg-primary-main hover:bg-primary-main transition-colors flex items-center justify-center w-10 h-10 shadow-lg float-right"
        onClick={() => {
          setSurveysClosed((surveys: SurveysClosed) => {
            if (!surveyForm) return surveys;

            return [
              ...(surveys || []),
              {
                id: surveyForm.id,
                closedAtSeconds: moment().utc().unix(),
              },
            ];
          });
          setFeedbackModal({ isOpen: false, surveyForm: null });
        }}
      >
        <FontAwesomeIcon
          icon={faTimesCircle}
          className="text-background-primary text-lg"
        />
      </button>
      {/** The ID is used for the style */}
      <div id="SurveyElement" className="overflow-scroll h-full">
        <SurveyModel
          surveyForm={surveyForm}
          closeModal={() =>
            setFeedbackModal({ isOpen: false, surveyForm: null })
          }
        />
      </div>
    </UIModal>
  );
};
