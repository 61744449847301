import { ConfigProvider } from "antd";
import dynamic from "next/dynamic";
import React, { ReactElement, useState } from "react";
import { tx } from "..";

ConfigProvider.config({
  theme: {
    primaryColor: "#ff0000",
  },
});

export const LanguageConfigProvider = ({
  children,
}: {
  children: ReactElement | ReactElement[];
}) => {
  const currentLocale = tx.getCurrentLocale();
  const [antdLocale, setAntdLocale] = useState(undefined);

  return (
    <ConfigProvider locale={antdLocale}>
      {currentLocale === "da" && (
        <MomentDa setAntdLocale={setAntdLocale} currentLocale={currentLocale} />
      )}
      {currentLocale === "en" && (
        <MomentEn setAntdLocale={setAntdLocale} currentLocale={currentLocale} />
      )}
      {currentLocale === "no" && (
        <MomentNb setAntdLocale={setAntdLocale} currentLocale={currentLocale} />
      )}
      {currentLocale === "nl" && (
        <MomentNl setAntdLocale={setAntdLocale} currentLocale={currentLocale} />
      )}
      {currentLocale === "sv" && (
        <MomentSv setAntdLocale={setAntdLocale} currentLocale={currentLocale} />
      )}
      {children}
    </ConfigProvider>
  );
};

const MomentDa = dynamic(() => import("./imports/da"));
const MomentEn = dynamic(() => import("./imports/en"));
const MomentNb = dynamic(() => import("./imports/nb"));
const MomentNl = dynamic(() => import("./imports/nl"));
const MomentSv = dynamic(() => import("./imports/sv"));
