import { T } from "@kanpla/system";
import { Button } from "@kanpla/ui";
import { isEmpty } from "lodash";

interface Props {
  text: string;
  imageUrl?: string;
  action?: {
    label: string;
    url: string;
  };
  showingToday?: boolean;
}

const PopupPreview = (props: Props) => {
  const { text, imageUrl, action, showingToday = false } = props;

  return (
    <div className="w-full h-full flex flex-col">
      {imageUrl && (
        <img
          src={imageUrl}
          alt={text}
          className="object-cover w-20 h-12 rounded-sm mb-3"
        />
      )}
      <div className="w-full flex gap-4 items-center justify-between">
        <div
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {action?.label && action?.url && (
          <a
            href={action.url}
            target="_blank"
            rel="noreferrer"
            className="block"
          >
            <Button
              size="small"
              type={showingToday ? "primary" : "secondary"}
              dataCy="btn-interaction-button"
            >
              {action.label}
            </Button>
          </a>
        )}
      </div>
    </div>
  );
};

export default PopupPreview;
