/* eslint-disable prefer-const */
import { Timestamp } from "@kanpla/system";
import { Week } from "@kanpla/types";

export const getWeekArray = (
  seconds: number,
  TimestampClass: typeof Timestamp = Timestamp,
  includeWeekend?: boolean
) => {
  let week = [];
  for (let i = 0; i < (includeWeekend ? 7 : 5); i++) {
    week.push(new TimestampClass(seconds, 0));
    seconds = seconds + 86400;
  }

  return week as Week;
};
