import { Language } from "@kanpla/types";
import { useEffect, useState } from "react";
import { tx } from "../transifex.config";

type AvailableLanguage = {
  code: Language;
  /** Local name of the language, e.g. Dansk for Danish */
  localized_name: string;
  /** English name of the language */
  name: string;
  /** Right-to-left typing */
  rtl: boolean;
};

export const useAvailableLanguages = () => {
  const [availableLanguages, setAvailableLanguages] = useState<
    Array<AvailableLanguage>
  >([]);
  const loadAvailableLanguages = async () => {
    const availableLanguages = await tx.getLanguages();
    setAvailableLanguages(availableLanguages);
  };
  useEffect(() => {
    loadAvailableLanguages();
  }, []);

  return availableLanguages;
};
