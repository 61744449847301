// Hook (use-auth.js)
import { auth, fb } from "@kanpla/system";
import {
  applyActionCode,
  confirmPasswordReset as authConfirmPasswordReset,
  createUserWithEmailAndPassword as authCreateUserWithEmailAndPassword,
  sendPasswordResetEmail as authSendPasswordResetEmail,
  fetchSignInMethodsForEmail,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signOut as signOutFB,
  verifyPasswordResetCode,
} from "firebase/auth";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { uuid } from "short-uuid";
import { useLocalStorage } from "../hooks/useLocalStorage";

export interface UserAuth {
  user: firebase.default.User;
  loading: boolean;
  signIn: (email: string, password: string) => Promise<firebase.default.User>;
  signUp: (email: string, password: string) => Promise<firebase.default.User>;
  signOut: () => Promise<void>;
  sendPasswordResetEmail: (email: string) => Promise<boolean>;
  confirmPasswordReset: (code: string, password: string) => Promise<boolean>;
  changePassword: (code: string, password: string) => Promise<void>;
  isUserEmailValid: (email: string) => Promise<boolean>;
  verifyEmail: (code: string) => Promise<void>;
  signInWithToken: (token: string) => Promise<firebase.default.User>;
}

interface Props {
  updateIntercomUser?: (userObj: { userId: string; email: string }) => void;
}

// Provider hook that creates auth object and handles state
export const UseAuth = (props: Props = {}) => {
  const { updateIntercomUser = () => null } = props;

  const [user, loading] = useAuthState(auth);
  const [, setLoggedIn] = useLocalStorage("user_logged_in", false);

  useEffect(() => {
    if (user) fb?.analytics().setUserId(user.uid);

    if (!user?.uid || !user?.email) {
      const fakeUserId = uuid();

      updateIntercomUser({
        email: `unknown_${fakeUserId}@kanpla.dk`,
        userId: fakeUserId,
      });
      return;
    }

    updateIntercomUser?.({
      userId: user?.uid || "unknown",
      email: user?.email || "unknown@kanpla.dk",
    });

    // @ts-ignore (let's not provide user)
  }, [updateIntercomUser, user?.uid, user?.email]);

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = async (email: string, password: string) => {
    const response = await signInWithEmailAndPassword(auth, email, password);
    setLoggedIn(true);
    //@ts-ignore
    window.dataLayer.push({ loggedIn: true });
    return response.user;
  };

  const signInWithToken = async (token: string) => {
    const response = await signInWithCustomToken(auth, token);
    setLoggedIn(true);
    //@ts-ignore
    window.dataLayer.push({ loggedIn: true });
    return response.user;
  };

  const signUp = async (email: string, password: string) => {
    return authCreateUserWithEmailAndPassword(auth, email, password).then(
      (response) => {
        setLoggedIn(true);
        return response.user;
      }
    );
  };

  const signOut = async () => {
    await signOutFB(auth);
    //@ts-ignore
    window.dataLayer.push({ loggedIn: false });
    setLoggedIn(false);
  };

  const sendPasswordResetEmail = async (email: string) => {
    return authSendPasswordResetEmail(auth, email).then(() => {
      return true;
    });
  };

  const confirmPasswordReset = async (code: string, password: string) => {
    return authConfirmPasswordReset(auth, code, password).then(() => {
      return true;
    });
  };

  const changePassword = async (code: string, password: string) => {
    await verifyPasswordResetCode(auth, code);
    return await authConfirmPasswordReset(auth, code, password);
  };

  const isUserEmailValid = async (email: string) => {
    const res = await fetchSignInMethodsForEmail(auth, email);
    if (!res.length) throw new Error();
    return true;
  };

  const verifyEmail = async (code: string) => {
    return await applyActionCode(auth, code);
  };

  // Return the user object and auth methods
  return {
    user,
    loading,
    signIn,
    signUp,
    signOut,
    sendPasswordResetEmail,
    confirmPasswordReset,
    changePassword,
    isUserEmailValid,
    verifyEmail,
    signInWithToken,
  } as UserAuth;
};
