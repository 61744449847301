import { User } from "@kanpla/models";
import { OrderingContext } from "@kanpla/ordering";
import { useDocumentListener, useT } from "@kanpla/system";
import { Alert } from "@kanpla/ui";
import { useContainer } from "unstated-next";

const OngoingRefillWarning = () => {
  const { userId } = useContainer(OrderingContext);
  const t = useT();

  const [user] = useDocumentListener<User>({ collection: "users", id: userId });
  const refillInProgress = user?.automaticRefillInProgress;

  if (!refillInProgress) return null;

  return (
    <Alert message={t("Automatic refill is in progress!")} type="warning" />
  );
};

export default OngoingRefillWarning;
