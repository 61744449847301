import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { OrderingContext } from "@kanpla/ordering";
import {
  callInternalApi,
  getErrorMessage,
  getReepayErrorText,
  T,
  useGetPaymentBaseUrl,
  useT,
} from "@kanpla/system";
import { Currency, PaymentGateway, School, User } from "@kanpla/types";
import {
  activeCurrencyAtom,
  Button,
  message,
  usePriceFormatter,
} from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import useChargeSession from "../UseChargeSession";

// TODO: Reimport these
export interface AddReepayCardArgs {
  userId: User["id"];
  paymentGatewayId: PaymentGateway["id"] | undefined;
  currency: Currency;
  schoolId: School["id"];
  baseUrl: string;
}

const CardAddReepay = () => {
  const t = useT();
  const [loading, setLoading] = useState(false);
  const { schoolId, paymentGatewayId, userId } = useContainer(OrderingContext);
  const currency = useAtomValue(activeCurrencyAtom);

  const formatted1Unit = usePriceFormatter(1);

  const { loadChargeSession } = useChargeSession({
    setLoading,
    onError: (err) => {
      message.error(getReepayErrorText(err.error));
    },
  });

  const callbackUrl = useGetPaymentBaseUrl();

  const handleOpenCardAdd = async () => {
    try {
      setLoading(true);
      if (!callbackUrl)
        throw new Error("No callback url available. Try again.");

      const sessionId = await callInternalApi<AddReepayCardArgs, string>(
        "payment/card-add-reepay",
        {
          paymentGatewayId: paymentGatewayId ?? undefined,
          schoolId,
          currency,
          userId,
          baseUrl: `${callbackUrl}/app`,
        }
      );

      if (!sessionId)
        throw new Error(t("No payment session available. Try again."));

      await loadChargeSession({ sessionId, provider: "reepay" });
    } catch (e) {
      message.error(getErrorMessage(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full items-center">
      <Button
        size="large"
        icon={faPlus}
        loading={loading}
        dataCy="btn-add-new-card"
        shape="solid"
        className="w-full"
        onClick={handleOpenCardAdd}
      >
        {t("Add new card")}
      </Button>
    </div>
  );
};

export default CardAddReepay;
