import { getAnalytics, logEvent } from "firebase/analytics";

type AllowedParameterType = string | undefined | null | boolean | number;

export const trackingLogEvent = (
  name: string,
  params?: { [key: string]: AllowedParameterType }
) => {
  if (typeof window === `undefined`) return;
  const analytics = getAnalytics();
  logEvent(analytics, name, params);
};
