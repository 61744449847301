import classNames from "classnames";
import React, { CSSProperties, useMemo, useState } from "react";
import { Blurhash } from "react-blurhash";
import Imgix from "react-imgix";

interface Props {
  className?: string;
  src: string;
  alt: string;
  style?: CSSProperties;
  loadedAnyway?: boolean;
  dataCy?: string;
  size?: {
    w?: number;
    h?: number;
    /** Ratio */
    ar?: string;
    fit?: "crop";
  };
  /** Blur the provided image */
  blur?: boolean;
}

export const Image = (props: Props) => {
  const {
    className = "",
    src,
    alt,
    style = {},
    loadedAnyway = false,
    dataCy = "",
    size = {},
    blur = false,
  } = props;

  const [imageLoaded, setImageLoaded] = useState(false);

  const blurHash = useMemo(() => {
    if (!src) return;
    if (!src.includes("https") || src.includes("svg")) return;

    try {
      const url = new URL(src);
      const blurHashIndex = url.toString().indexOf("blurHash=");
      if (blurHashIndex < 0) return;

      const indexes = [blurHashIndex + 9, blurHashIndex + 45];
      const blurHash = url.toString().slice(indexes[0], indexes[1]);

      if (blurHash.length !== 36) {
        return;
      }

      return blurHash;
    } catch (err) {
      return null;
    }
  }, [src]);

  const finalLoaded = useMemo(
    () => loadedAnyway || imageLoaded,
    [loadedAnyway, imageLoaded]
  );

  if (!src) return null;

  if (src?.includes("imgix.net")) {
    return (
      <>
        {blurHash && (
          <div className="absolute inset-0 pointer-events-none">
            <Blurhash
              hash={blurHash}
              width={"100%"}
              height={"100%"}
              resolutionX={32}
              resolutionY={32}
              className={classNames(
                "transition-opacity duration-500",
                finalLoaded ? "opacity-0 !hidden" : "opacity-100"
              )}
            />
          </div>
        )}
        <Imgix
          imgixParams={{
            q: 70,
            ...(size || {}),
            auto: "compress",
            dpi: 1,
          }}
          width={size?.w}
          height={size?.h}
          src={src.replace(/(\?|&)blurHash=[^&]+/i, "")}
          className={classNames(
            className,
            {
              "transition-opacity ease-in-out duration-500": blurHash,
              "loader bg-background-secondary": !blurHash && !finalLoaded,
              "blur-sm": blur,
            },
            finalLoaded ? "opacity-100" : blurHash ? "opacity-0" : "opacity-100"
          )}
          htmlAttributes={{
            alt,
            "data-cy": dataCy,
            onLoad: () => setImageLoaded(true),
          }}
          sizes="70vw"
          attributeConfig={{
            src: "data-src",
            sizes: "data-sizes",
          }}
        />
      </>
    );
  }

  return (
    <img
      src={src}
      alt={alt}
      onLoad={() => setImageLoaded(true)}
      className={classNames(
        className,
        finalLoaded && src ? "opacity-100" : "loader bg-background-secondary",
        { "blur-sm": blur }
      )}
      style={{ transition: "opacity .2s ease-out", ...style }}
      data-cy={dataCy}
    />
  );
};
