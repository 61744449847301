import {
  calculateAmountOfOrderItems,
  calculateOrderWithDiscountTotal,
} from "@kanpla/system";
import {
  BasketContainer,
  Module,
  Order,
  OrderLines,
  OrderPersonal,
  _FrontendModule,
} from "@kanpla/types";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";

import { sumBy } from "lodash";
import {
  OrderingContextProps,
  childIdAtom,
  moduleIdAtom,
  schoolIdAtom,
} from "../../context";
import { dateInfoAtom } from "../../lib/hooks/useDateInfo";

type BasketOrder = OrderLines | ((oldOrder?: OrderLines) => OrderLines);

interface Props {
  userId: string;
  module: _FrontendModule;
  fromAdmin?: boolean;
  balance?: OrderingContextProps["balance"];
  /** Already paid orders */
  orders?: Order[];
}

export interface SetBasketProps {
  o: BasketOrder;
  orderInfo?: OrderPersonal["info"];
  moduleId: string;
  dateSeconds: number;
}

// Find the concrete order we are editing right now
export const isTargetOrder = (
  o: OrderPersonal,
  moduleId: string | null,
  dateSeconds: number,
  schoolId: string | null,
  childId: string | null
) =>
  o.moduleId === moduleId &&
  o.dateSeconds === dateSeconds &&
  o.childId === childId &&
  o.schoolId === schoolId;

/** Holds all of the baskets for different modules */
export const basketContainerAtom = atom<BasketContainer>({});

export const basketPaidAmountAtom = atom<number>(0);

/** Total price of all orders in the basket container */
export const basketContainerTotalPriceAtom = atom<number>(0);

export const basketResultedPriceAtom = atom<number>((get) => {
  const totalPrice = get(basketContainerTotalPriceAtom);
  const paidAmount = get(basketPaidAmountAtom);

  const resulted = totalPrice - paidAmount;

  return resulted < 0 ? 0 : resulted;
});

export const basketContainerTotalItemsAtom = atom<number>((get) => {
  const container = get(basketContainerAtom);
  const cost = Object.values(container).reduce(
    (acc, o) => acc + calculateAmountOfOrderItems(o.orderLines),
    0
  );
  return cost;
});

/** If basket modal is open (visible) in the UI */
export const openBasketAtom = atom<boolean>(false);

export const shouldNotifyUserAfterRefillAtom = atom<boolean>(false);

export const hasEnoughMoneyToPayBasketAtom = atom<boolean>(false);

/** Close basket and reset edit mode */
export const handleBasketOpeningAtom = atom(null, (get, set, open: boolean) => {
  set(openBasketAtom, open);
  if (get(basketEditModeAtom)) set(basketContainerAtom, {});
  set(basketEditModeAtom, false);
});

/** Is the UI set to editing basket items */
export const basketEditModeAtom = atom(false);

/** To understand if is editing of the basket or of the order */
export const isOrderEditingAtom = atom(false);

/** Check if basket has items */
export const isEmptyBasketAtom = atom((get) => get(basketAtom).length === 0);

const basketOrderInViewAtom = atom<Order | null>((get) => {
  const { dateSeconds } = get(dateInfoAtom);
  const childId = get(childIdAtom);
  const schoolId = get(schoolIdAtom);
  const moduleId = get(moduleIdAtom);

  const allOrders = Object.values(get(basketContainerAtom) || {});
  const targetOrder = allOrders.find((o) =>
    isTargetOrder(o, moduleId, dateSeconds, schoolId, childId)
  );
  return targetOrder || null;
});

/** Order lines of the basket */
/** @deprecated, don't use anymore!, calculate this instead
 * ```  const basket = Object.values(basketContainer ?? {}).flatMap(
    (o) => o.orderLines ?? []
  );
```
 */
export const basketAtom = atom<OrderLines>(
  (get) => get(basketOrderInViewAtom)?.orderLines || []
);

export const basketResetAtom = atom(null, (_get, set) => {
  // set(basketAtom, [] as OrderLines);
  set(basketContainerAtom, {});
  set(handleBasketOpeningAtom, false);
});

export const useBasket = ({
  module,
  fromAdmin,
  orders = [],
  balance = 0,
}: Props) => {
  const setOpenBasket = useSetAtom(openBasketAtom);
  const basketReset = useSetAtom(basketResetAtom);
  const basketContainer = useAtomValue(basketContainerAtom);
  const basketContainerTotalItems = useAtomValue(basketContainerTotalItemsAtom);
  const [editMode, setEditMode] = useAtom(basketEditModeAtom);

  const isEmptyBasket = !Object.values(basketContainer || {}).flatMap(
    (o) => o.orderLines
  )?.length;

  if (isEmptyBasket) {
    setOpenBasket(false);
    setEditMode(false);
  }

  const modulePaymentMethod = module?.paymentMethod ?? "credit";

  const totalPrice = calculateTotalSumPrice(
    Object.values(basketContainer),
    modulePaymentMethod
  );
  const paidAmount = calculateTotalSumPrice(orders, modulePaymentMethod);

  const setPaidAmount = useSetAtom(basketPaidAmountAtom);
  useEffect(() => {
    setPaidAmount(paidAmount);
  }, [paidAmount]);

  // Always close the basket when unmounting the hook
  useEffect(() => {
    return () => {
      setOpenBasket(false);
    };
  }, []);

  useEffect(() => {
    if (fromAdmin) return;
    if (basketContainerTotalItems === 0 && !editMode) {
      basketReset();
      setOpenBasket(false);
    }
  }, [basketContainerTotalItems, editMode]);

  /** Temp: updating basketContainerTotalPriceAtom via useEffect, until we move to Jotai fully */
  const setBasketContainerTotalPrice = useSetAtom(
    basketContainerTotalPriceAtom
  );

  useEffect(() => {
    setBasketContainerTotalPrice(totalPrice);
  }, [totalPrice]);

  /** Temp: updating basketContainerTotalPriceAtom via useEffect, until we move to Jotai fully */
  const setHasEnoughMoneyToPayBasket = useSetAtom(
    hasEnoughMoneyToPayBasketAtom
  );

  const resultedPrice =
    module?.plugins?.kanplaGo?.active || module?.plugins?.payPerOrder?.active
      ? totalPrice
      : totalPrice - paidAmount;

  const hasMoney = balance >= resultedPrice || false;

  useEffect(() => {
    setHasEnoughMoneyToPayBasket(hasMoney);
  }, [hasMoney]);
};

const calculateTotalSumPrice = (
  orders: Order[],
  paymentMethod: Module["paymentMethod"]
) => {
  const basketBreakdowns = orders.map((o) =>
    calculateOrderWithDiscountTotal(o)
  );
  const totalPrice = sumBy(basketBreakdowns, (breakdown) =>
    paymentMethod === "credit" ? breakdown.withVat : breakdown.withoutVat
  );

  return totalPrice;
};
