import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { T, useAvailablePaymentMethods, useT } from "@kanpla/system";
import classNames from "classnames";
import { useAtom } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import { selectedPaymentMethodAtom } from "../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import { SelectPayment } from "./SelectPayment";

const ButtonInside = () => {
  const t = useT();
  const { card, schoolId, partnerId } = useContainer(OrderingContext);
  const { paymentMethodOptions, isLoading } = useAvailablePaymentMethods(
    schoolId,
    partnerId
  );
  const [paymentMethod] = useAtom(selectedPaymentMethodAtom);

  const selectedOption = paymentMethodOptions.find(
    (option) => option.value === paymentMethod
  );

  if (isLoading)
    return (
      <p>
        <T _str="Loading" />
        ...
      </p>
    );

  if (selectedOption)
    return (
      <div className="flex gap-x-3 items-center">
        {typeof selectedOption?.icon === "string" ? (
          <img
            src={selectedOption.icon}
            alt={selectedOption.label}
            className="w-6"
          />
        ) : (
          <FontAwesomeIcon icon={selectedOption.icon} />
        )}
        {selectedOption?.value === "card" && card ? (
          <div className="flex flex-col items-start">
            <b className="capitalize text-sm">{card.metadata.brand}</b>
            <span className="text-xs text-text-secondary -mt-0.5">
              {t("ends with {value}", { value: card.metadata.last4 })}
            </span>
          </div>
        ) : (
          <span>{selectedOption?.label}</span>
        )}
      </div>
    );

  return (
    <span className="text-sm">
      <T _str="Choose payment option" />
    </span>
  );
};

const ChoosePaymentMethod = ({ className }: { className?: string }) => {
  const [open, setOpen] = useState(false);

  const [paymentMethod, setPaymentMethod] = useAtom(selectedPaymentMethodAtom);

  return (
    <>
      <button
        className={classNames(
          "w-48 px-3 py-2 border justify-between border-divider-main shadow-sm rounded-md flex items-center cursor-pointer hover:opacity-50 md:hover:opacity-100 md:hover:border-primary-main transition-all ease-in-out",
          className
        )}
        onClick={() => setOpen(true)}
      >
        <ButtonInside />
        <FontAwesomeIcon icon={faChevronDown} className="text-secondary-main" />
      </button>
      <SelectPayment
        open={open}
        setOpen={setOpen}
        selectedMethod={paymentMethod}
        showCardButton
        onSelect={(method) => setPaymentMethod(method)}
      />
    </>
  );
};

export default ChoosePaymentMethod;
