import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Modal } from "@kanpla/ui";
import classnames from "classnames";
import { atom, useAtom } from "jotai";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";

export const confirmationModalOpenAtom = atom<boolean>(false);

export const PurchaseAnimation = () => {
  const { mobile, hasKanplaGo, module } = useContainer(OrderingContext);
  const [confirmationModalOpen, setConfirmationModalOpen] = useAtom(
    confirmationModalOpenAtom
  );
  const t = useT();

  if (hasKanplaGo || module?.flow === "meeting") return null;

  return (
    <Modal
      className="h-96 w-96"
      zMax
      open={confirmationModalOpen}
      setOpen={setConfirmationModalOpen}
      disableLock
    >
      <div
        className={classnames(
          mobile ? "fixed" : "absolute",
          "inset-0 pointer-events-none transition text-primary-contrast duration-500 z-max transform"
        )}
      >
        <div className="flex flex-col items-center justify-center from-success-main to-success-dark bg-gradient-to-b transition-transform transform h-full origin-bottom shadow-2xl rounded-t-lg p-8">
          <div className="bg-background-primary w-32 h-32 flex items-center justify-center rounded-full ">
            <FontAwesomeIcon
              icon={faCheck}
              className="text-success-main text-4xl"
            />
          </div>
          <h3 className="text-2xl md:text-3xl font-medium text-background-primary mb-10 mt-10 text-center">
            {t("Your order is confirmed")}
          </h3>
        </div>
      </div>
    </Modal>
  );
};
