import { faClose } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNative } from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import moment from "moment";
import { useEffect, useState } from "react";
import { isMobileSafari } from "react-device-detect";
import { useLocalstorageState, useSessionstorageState } from "rooks";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const CustomAppBanner = () => {
  const { supplier: contextSupplier } = useContainer(AppContext);
  const [storageSupplier] =
    useSessionstorageState<Supplier>("storage-supplier");

  const supplier = contextSupplier || storageSupplier;

  const [closed, setClosed] = useState(true);

  const [customAppBannerLastShown, setCustomAppBannerLastShown] =
    useLocalstorageState("custom-app-banner-last-shown", null);

  const kanplaAppLogo =
    "https://is5-ssl.mzstatic.com/image/thumb/Purple112/v4/f3/cb/a0/f3cba095-bf74-9582-d2d7-1e956974db10/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/460x0w.png";

  const calculateDaysDifference = () => {
    const now = moment();
    const lastShown = moment(customAppBannerLastShown as string);

    const diff = now.diff(lastShown, "days");
    // Show the banner again after it's been closed for 7 days
    if (diff < 7) return;
    setClosed(false);
  };

  useEffect(() => {
    calculateDaysDifference();
  }, []);

  const openOnAppStore = () => {
    const script = document.createElement("script");

    // Redirect to app if user is viewing from iOS mobile
    if (isMobileSafari && !isNative) {
      script.innerHTML = `
        window.location.replace("${
          supplier?.appUrl || (supplier as Supplier)?.url || "kanpla"
        }://");
        setTimeout(() => {
          window.location.replace("https://itunes.apple.com/app/${
            (supplier as Supplier)?.appstoreId || "id1500582659"
          }");
        }, 25);  
      `;
      document.body.appendChild(script);
    }

    setClosed(true);
  };

  if (isNative || !isMobileSafari || closed) return null;

  return (
    <div className="sticky z-60 w-full h-auto p-4 text-gray-900 bg-gray-200 border-y border-gray-400 flex justify-between items-center shadow-xl">
      <div className="flex items-center">
        <FontAwesomeIcon
          icon={faClose}
          size="lg"
          onClick={() => {
            setCustomAppBannerLastShown(moment().toISOString());
            setClosed(true);
          }}
        />
        <div className="w-9 h-9 ml-4 rounded-lg border border-gray-400 overflow-clip">
          <img
            src={supplier?.appIconUrl || kanplaAppLogo}
            alt={`${supplier?.name || "Kanpla"} app logo`}
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
        <div className="flex flex-col ml-3">
          <h1 className="text-lg font-semibold">
            {supplier?.appName || supplier?.name || "Kanpla"}
          </h1>
          {supplier?.name && (
            <h2 className="text-gray-700 text-sm">powered by Kanpla</h2>
          )}
        </div>
      </div>
      <Button
        dataCy="button-custom-banner"
        type="primary"
        shape="solid"
        onClick={openOnAppStore}
        className="w-auto"
      >
        Open
      </Button>
    </div>
  );
};

export default CustomAppBanner;
