import {
  IconLookup,
  IconName,
  IconPrefix,
  faCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaymentProvider } from "@kanpla/types";

interface RowProps {
  name: string;
  icon: string | [IconPrefix, IconName] | IconLookup;
  value: PaymentProvider;
  selected: PaymentProvider;
  onChange: (value: PaymentProvider) => void;
  detail?: string;
}

const PaymentRow = (props: RowProps) => {
  const { name, icon, value, selected, onChange, detail = "" } = props;

  const isActive = selected === value;

  return (
    <div
      className={`w-full flex justify-between items-center border-b h-16 py-2 border-divider-main hover:opacity-80 ${
        !isActive ? "cursor-pointer" : ""
      }`}
      onClick={() => onChange(value)}
    >
      <div className="flex items-center">
        <div className="w-10">
          {typeof icon === "string" ? (
            <img src={icon} alt={name} className="w-8" />
          ) : (
            <FontAwesomeIcon
              icon={icon}
              className="text-primary-main"
              size="xl"
            />
          )}
        </div>
        <div className="flex flex-col ml-4">
          <h1 className="text-lg font-semibold text-text-primary">{name}</h1>
          {detail && (
            <span className="text-text-secondary text-xs">{detail}</span>
          )}
        </div>
      </div>
      {isActive && (
        <div className="w-8 h-8 bg-background-secondary rounded-full p-2 flex justify-center items-center pop">
          <FontAwesomeIcon icon={faCheck} className="text-primary-dark" />
        </div>
      )}
    </div>
  );
};

export default PaymentRow;
