import { faLoader } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React from "react";

type Size = "small" | "medium" | "large";

interface SpinnerProps {
  size?: Size;
  color?: string;
  className?: string;
  useCurrentColor?: boolean;
}

const getSize = (size: Size): string => {
  if (size === "small") return "h-5 w-5";
  if (size === "medium") return "h-8 w-8";

  return "h-12 w-12";
};

export const Spinner = ({
  size = "medium",
  color = "#48BB78",
  className,
  useCurrentColor = true,
}: SpinnerProps) => {
  return (
    <div className="animate-spin flex items-center justify-center">
      <FontAwesomeIcon
        icon={faLoader}
        className={classnames(getSize(size), className)}
        style={{ color: useCurrentColor ? undefined : color }}
      />
    </div>
  );
};

export default Spinner;
