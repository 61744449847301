import { Timezone } from "@kanpla/types";
import moment from "moment-timezone";
import { TIMEZONE_DEFAULT } from "..";
import { getMidnightSeconds } from "../time/getMidnightSeconds";
import { getWeekSeconds } from "../time/getWeekSeconds";

interface Props {
  deadline: number; // seconds relative to UTC midnight
  deadlineWeekRelative?: boolean; // day relative: 3 days ago, week relative: before friday
  deadlineExcludingWeekends?: boolean; // Friday is the day before monday
  includesWeekend: boolean;
  timezone: Timezone;
}

export const getDefaultDateSeconds = (props: Props) => {
  const {
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    includesWeekend,
    timezone,
  } = props;

  const offset = moment()
    .tz(timezone || TIMEZONE_DEFAULT)
    .utcOffset();
  const nowSeconds = moment().utc().add(offset, "minutes").unix();

  const getDayRelative = () => {
    const nowOrderTil = nowSeconds - deadline;

    const nowOrderTilTime = nowOrderTil % 86400;
    const resDateSeconds: number = nowOrderTil - nowOrderTilTime + 86400;
    return resDateSeconds;
  };

  const getWeekRelative = () => {
    const thisWeek = getWeekSeconds(nowSeconds);
    const nextWeek = thisWeek + 604800;
    const deadlineForNextWeek = nextWeek + deadline; // e.g. friday 00:00

    const isPastDeadline = nowSeconds > deadlineForNextWeek;
    const nextNextWeek = nextWeek + 604800;

    const resDateSeconds: number = isPastDeadline ? nextNextWeek : nextWeek;
    return resDateSeconds;
  };

  let defaultDateSeconds: number = deadlineWeekRelative
    ? getWeekRelative()
    : getDayRelative();

  // Account for excluding weekend
  if (deadlineExcludingWeekends && !deadlineWeekRelative) {
    const now = moment.unix(nowSeconds);

    // For now solve a weird weekend issue
    if ([6, 7, 0].includes(now.day())) {
      const week = getWeekSeconds(nowSeconds);
      const nextWeekMonday = moment.unix(week).add(1, "week");

      const daySeconds = 24 * 60 * 60;

      const days = Math.ceil(-deadline / daySeconds);

      return getMidnightSeconds(nextWeekMonday.unix()) + days * daySeconds;
    }

    const fridayBeforeDate = getWeekSeconds(defaultDateSeconds + 86400 * 2);
    const fridayBeforeToday = getWeekSeconds(nowSeconds + 86400 * 2);
    const differenceOfFridays = fridayBeforeDate - fridayBeforeToday;
    const weeksCrossed = differenceOfFridays / 604800;

    const weekendSeconds = 86400 * 2;
    const secondsToAdd = weeksCrossed * weekendSeconds;
    defaultDateSeconds = defaultDateSeconds + secondsToAdd;
  }

  // Adjust for weekend
  const mondaySeconds = getWeekSeconds(defaultDateSeconds);
  const isWeekend = defaultDateSeconds - mondaySeconds > 4 * 86400;
  if (isWeekend && !includesWeekend) {
    const nextMondaySeconds = mondaySeconds + 604800;
    defaultDateSeconds = nextMondaySeconds;
  }

  return defaultDateSeconds;
};
