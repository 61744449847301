import {
  Timestamp,
  getTodaySeconds,
  getWeekSeconds,
  isDatePastDeadline,
} from "@kanpla/system";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { useDeadlineFormatter } from "./useDeadlineFormatter";
import { deadlineInfoAtom } from "./useDeadlineInfo";

export const dateInfoAtom = atom({
  dateSeconds: getTodaySeconds(),
  weekSeconds: getWeekSeconds(getTodaySeconds()),
  isPastDeadline: false,
  deadlineFormatted: "",
  isPastTodayIndividualDeadline: (_individualDeadline?: number | false) =>
    false as boolean,
});

export const useDateInfo = ({ dateSeconds }: { dateSeconds: number }) => {
  const setDateInfo = useSetAtom(dateInfoAtom);
  const {
    isDateSecondsPastDeadline,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    includesWeekend,
    timezone,
  } = useAtomValue(deadlineInfoAtom);

  // 2. Derived values
  const isPastTodayIndividualDeadline = (individualDeadline?: number | false) =>
    isDatePastDeadline({
      date: new Timestamp(dateSeconds, 0),
      deadline: individualDeadline as number | false,
      deadlineWeekRelative,
      deadlineExcludingWeekends,
      includesWeekend,
      timezone,
    });

  const isPastDeadline = isDateSecondsPastDeadline(dateSeconds);

  const deadlineFormatted = useDeadlineFormatter({
    dateSeconds,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  });

  const weekSeconds = getWeekSeconds(dateSeconds);

  useEffect(() => {
    setDateInfo({
      dateSeconds,
      weekSeconds,
      isPastDeadline,
      deadlineFormatted,
      isPastTodayIndividualDeadline,
    });
  }, [
    dateSeconds,
    weekSeconds,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    isPastDeadline,
    deadlineFormatted,
  ]);
};
