import classnames from "classnames";
import React, { CSSProperties } from "react";

interface DividerProps {
  /** Divider's height (in pixels), instead of using height, border-top-width property is being used */
  height?: 1 | 2 | 3 | 4 | 5 | 6;
  /** Divider's y spacing. Defined by margin property */
  noSpacing?: boolean;
  /** Divider color. It's possible to set it through the border-color property. Either using the style object or the className string. */
  color?: string;
  className?: string;
  style?: CSSProperties;
  dashed?: boolean;
}

export const Divider: React.FC<DividerProps> = (props): JSX.Element => {
  const {
    height = 1,
    noSpacing = false,
    dashed = false,
    className = "",
    color = "",
    ...rest
  } = props;

  const classes = classnames(
    "w-full border-divider-main my-3",
    `border-t-[${height}px]`,
    color,
    className,
    noSpacing ? "!my-0" : "",
    dashed ? "border-dashed" : ""
  );

  return <hr className={classes} {...rest} />;
};
