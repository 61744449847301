import {
  Card,
  FirestoreDocument,
  Order,
  OrderPersonal,
  PaymentGateway,
  School,
} from "..";
import { Timestamp } from "../firebase";
import { Currency, PriceIncludingVat } from "../pricing/pricing";

export const paymentProviderOptions = [
  "applepay",
  "mobilepay",
  "card",
  "card-v3",
  "googlepay",
  "credit",
  "manual",
  "cash",
  "swish",
  "ideal",
  "vipps",
  "api",
  // used for the manual flow of mobilePay on the POS app, requires the approval of an clerk
  "mobilepay_own",
  "billing",
  "billing-selector", // POS invoice company on attended
] as const;

export type PaymentProvider = typeof paymentProviderOptions[number];

export type TemporaryOrder = Pick<
  OrderPersonal,
  "orderLines" | "childId" | "dateSeconds" | "moduleId" | "userId"
>;

/** Reepay payment types */
export type PaymentType =
  | "card"
  | "mobilepay"
  | "swish"
  | "vipps"
  | "swish"
  | "manual"
  | "applepay"
  | "googlepay"
  | "api";

export interface Payment extends FirestoreDocument {
  /** Total value of the payment */
  unitPrice: PriceIncludingVat;
  dateCreated: Timestamp;
  userId: string;
  // Payed or not
  valid: boolean;
  /** If the payment was created from superadmin (no actual ReePay payment) */
  isManual?: boolean;
  /** If the payment was already refunded (even partially), include reference */
  payoutReference?: string;
  /** How was the transaction made (on ReePay) */
  paymentProvider: PaymentProvider;
  /** Is the payment related to an order */
  hasTemporaryOrder?: boolean;
  /** Holds multiple orders related to one payment */
  orders?: {
    /** The full order, will be submitted after the payment is verified */
    order: TemporaryOrder;
    /** True if the order has ran successfully */
    orderValid?: boolean;
    // This is actually OrderPersonal | string, will have to be migrated later
    orderId?: string | OrderPersonal;
  }[];
  /** In which school the payment was initiated (used for redirection in kanplaGo) */
  schoolId?: string;
  /** In which module the payment was initiated (used for redirection in kanplaGo) */
  moduleId?: string;

  /** If something happened manually to the payment or it was created manually */
  type?: "giftcard" | "moved" | "test" | "import" | "equalize";
  /** Provide extra info in case it was created manually */
  info?: string;
  /** Used to logging fees */
  paymentType?: PaymentType;
  /** Partner ID is important to know to see who should pay fees */
  partnerId?: string;
  currency: Currency;
  /** Gateway used to create a payment */
  paymentGatewayId?: PaymentGateway["id"];
  /** POS: the order related */
  orderId?: string;
  /** POS: the change given to the customer (cash) - 1EUR should be 1 */
  change?: number;
  /** POS: the transaction ID from the payment terminal */
  transactionId?: string;
  /** POS: the service ID to identify the payment terminal transaction (for aborting) */
  serviceId?: string;
  /** POS: terminal ID used to make the payment. Null in case the payment came from POS but there was no terminal used */
  terminalId?: string | null;
  /** POS: Till through which the payment went. NOTE: migration of old payments did not run yet, data is available on Order */
  tillId?: string;
  /** POS: Till session during the which the payment was realized. NOTE: migration of old payments did not run yet, data is available on Order */
  sessionId?: string;
  transactionInfo?: {
    paymentMethod?: string;
    // e.g. visa, mastercard
    cardType?: string;
    cardSummary?: Card["metadata"]["last4"];
    pspReference?: string;
  };
}

/**
 * Model of what dashboard info service should return
 */
export interface DashboardInfo {
  timezone: string;
  name: string;
  currency: string;
  email: string;
  phone?: string;
  id: string;
  isTest?: boolean;
  logo?: string;
}

export type RecurringPaymentProps = {
  /** User id concerning the payment, required */
  userId: string;
  /** Price of the payment, required */
  unitPrice: PriceIncludingVat;
  /** Type of recurring payment, required, affects accessible props */
  recurringType: "background" | "standard";
  /** Used when getting the default Reepay card, optional */
  fallbackReepay?: boolean;
  /** Include orders only if they have not been created by your service */
  orders?: Order[];
} & (BackgroundRecurring | StandardRecurring);

// recurring payment triggered by a denormalization or other background process
type BackgroundRecurring = {
  recurringType: "background";
  /** Payment gateway id for the payment, optional */
  paymentGatewayId?: PaymentGateway["id"];
  /** Currency of the payment, required */
  currency: Currency;
  /** Payment id to use for the transaction, optional */
  paymentId?: string;
};
// recurring payment triggered by an endpoint or interface
type StandardRecurring = {
  recurringType: "standard";
  /** School id for the transaction, required */
  schoolId: School["id"];
  /** Card id to use for the charge, optional */
  cardId?: Card["id"];
};
