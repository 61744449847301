import { faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../../context";
import CardPreview from "./CardPreview";
import CardsManage from "./CardsManage";

const CreditCardInfo = () => {
  const t = useT();
  const { card } = useContainer(OrderingContext);
  const [manageOpen, setManageOpen] = useState(false);

  if (!card) return null;

  return (
    <div className="inset-x-0 rounded-b bg-background-secondary px-2 select-none flex items-center text-text-primary text-sm justify-between">
      {card.metadata && (
        <div className="py-2 px-2">
          <CardPreview card={card} />
        </div>
      )}
      <button
        className="p-2 text-text-secondary hover:text-text-secondary font-medium flex items-center"
        type="button"
        onClick={() => setManageOpen(true)}
      >
        {t("Payment methods")}{" "}
        <FontAwesomeIcon icon={faChevronRight} className="ml-1" />
      </button>
      <CardsManage open={manageOpen} setOpen={setManageOpen} />
    </div>
  );
};

export default CreditCardInfo;
