import { isNative } from "@kanpla/system";
import { useEffect, useState } from "react";

type StatusBarStyle = "light" | "dark";

export const useStatusBar = () => {
  const [statusBarStyle, setStatusBarStyle] = useState<StatusBarStyle>("dark");

  useEffect(() => {
    if (typeof window === "undefined" || !isNative) return;
    if (!statusBarStyle) return;

    window?.["ReactNativeWebView"]?.postMessage(
      JSON.stringify({ changeStatusBarStyle: statusBarStyle })
    );
  }, [statusBarStyle]);

  return {
    /** Color of the native status bar in the app - defaults to `dark` */
    statusBarStyle,
    /** Set the color of the native status bar in the app */
    setStatusBarStyle,
  };
};
