// Variable name example: "--palette-primary-main"

export const getCssVariable = (variableName: string) => {
  if (typeof document === "undefined") return "";

  const value = getComputedStyle(document.documentElement).getPropertyValue(
    variableName
  );

  return value;
};
