import { t } from "..";

const danishErrors: { [errorCode: string]: string } = {
  /**
   * Firebase errors
   */
  "auth/weak-password": t("The password has to have at least 6 characters"),
  "auth/claims-too-large": t(
    "The claims payload provided exceeds the maximum allowed size of 1000 bytes."
  ),
  "auth/email-already-exists": t(
    "This email is already used by another account."
  ),
  "auth/id-token-expired": t("The database token has expired."),
  "auth/id-token-revoked": t("The database token has been revoked."),
  "auth/insufficient-permission":
    "The credential used to initialize the Admin SDK has insufficient permission to access the requested Authentication resource. Refer to Set up a Firebase project for documentation on how to generate a credential with appropriate permissions and use it to authenticate the Admin SDKs.",
  "auth/internal-error":
    "The Authentication server encountered an unexpected error while trying to process the request. The error message should contain the response from the Authentication server containing additional information. If the error persists, please report the problem to our support channel.",
  "auth/invalid-argument":
    "An invalid argument was provided to an Authentication method. The error message should contain additional information.",
  "auth/invalid-claims": "The custom claims are invalid.",
  "auth/invalid-continue-uri": "The URL is invalid.",
  "auth/invalid-creation-time": "The creation time is invalid.",
  "auth/invalid-credential":
    "The credential used to authenticate the Admin SDKs cannot be used to perform the desired action. Certain Authentication methods such as createCustomToken() and verifyIdToken() require the SDK to be initialized with a certificate credential as opposed to a refresh token or Application Default credential. See Initialize the SDK for documentation on how to authenticate the Admin SDKs with a certificate credential.",
  "auth/invalid-disabled-field": t("The field is invalid."),
  "auth/invalid-display-name": t("The field is invalid."),
  "auth/invalid-dynamic-link-domain": t("The field is invalid."),
  "auth/invalid-email": t("The email address has an incorrect format."),
  "auth/invalid-email-verified": t("The field is invalid."),
  "auth/invalid-id-token": t("The database token is invalid."),
  "auth/invalid-last-sign-in-time": t(
    "The format of the last sign-in time is invalid."
  ),

  "auth/invalid-page-token":
    "The provided next page token in listUsers() is invalid. It must be a valid non-empty string.",
  "auth/invalid-password":
    "The provided value for the password user property is invalid. It must be a string with at least six characters.",
  "auth/invalid-password-hash":
    "The password hash must be a valid byte buffer.",
  "auth/invalid-password-salt": "The password salt must be a valid byte buffer",
  "auth/invalid-phone-number":
    "The provided value for the phoneNumber is invalid. It must be a non-empty E.164 standard compliant identifier string.",
  "auth/invalid-photo-url":
    "The provided value for the photoURL user property is invalid. It must be a string URL.",
  "auth/invalid-provider-data":
    "The providerData must be a valid array of UserInfo objects.",
  "auth/invalid-provider-id":
    "The providerId must be a valid supported provider identifier string.",
  "auth/invalid-oauth-responsetype":
    "Only exactly one OAuth responseType should be set to true.",
  "auth/invalid-session-cookie-duration":
    "The session cookie duration must be a valid number in milliseconds between 5 minutes and 2 weeks.",
  "auth/invalid-uid":
    "The provided uid must be a non-empty string with at most 128 characters.",
  "auth/invalid-user-import": "The user record to import is invalid.",
  "auth/maximum-user-count-exceeded":
    "The maximum allowed number of users to import has been exceeded.",
  "auth/missing-android-pkg-name":
    "An Android Package Name must be provided if the Android App is required to be installed.",
  "auth/missing-continue-uri":
    "A valid continue URL must be provided in the request.",
  "auth/missing-hash-algorithm":
    "Importing users with password hashes requires that the hashing algorithm and its parameters be provided.",
  "auth/missing-ios-bundle-id": "The request is missing an iOS Bundle ID.",
  "auth/missing-uid": "A uid identifier is required for the current operation.",
  "auth/missing-oauth-client-secret":
    "The OAuth configuration client secret is required to enable OIDC code flow.",
  "auth/operation-not-allowed":
    "The provided sign-in provider is disabled for your Firebase project. Enable it from the Sign-in Method section of the Firebase console.",
  "auth/phone-number-already-exists":
    "The provided phoneNumber is already in use by an existing user. Each user must have a unique phoneNumber.",
  "auth/project-not-found":
    "No Firebase project was found for the credential used to initialize the Admin SDKs. Refer to Set up a Firebase project for documentation on how to generate a credential for your project and use it to authenticate the Admin SDKs.",
  "auth/reserved-claims":
    "One or more custom user claims provided to setCustomUserClaims() are reserved. For example, OIDC specific claims such as (sub, iat, iss, exp, aud, auth_time, etc) should not be used as keys for custom claims.",
  "auth/session-cookie-expired": t("The session cookie has expired."),
  "auth/session-cookie-revoked": t("The session cookie is revoked."),
  "auth/uid-already-exists": t(
    "The provided uid is already in use by an existing user. Each user must have a unique uid."
  ),
  "auth/unauthorized-continue-uri": t(
    "The domain of the continue URL is not whitelisted. Whitelist the domain in the Firebase Console."
  ),
  "auth/user-not-found": t("The user does not exist."),
  "auth/user-mismatch": t(
    "The supplied credentials do not correspond to the previously signed in user."
  ),
  "auth/wrong-password": t("The password is invalid."),
  "auth/user-disabled": t(
    "The user has been disabled. Contact us to enable it again."
  ),
  "auth/too-many-requests": t("Too many attempts. Please try again later."),
  /***
   * Kanpla Errors
   */
  "kanpla/unauthorized": t("Unauthorized"),
  "kanpla/fill-email": t("Insert your email!"),
  "kanpla/fill-password": t("Insert your password!"),
  "kanpla/fill-canteen-id": t("Insert a canteen ID!"),
  "kanpla/wrong-canteen-id": t("Incorrect canteen ID"),

  /**
   * Reepay errors
   *
   */
  "reepay/declined_by_acquirer": t(
    "The transaction has unfortunately been declined. Try again later or contact your bank or our support channel."
  ),
  "reepay/credit_card_expired": t(
    "Your credit card is expired. Please contact your bank for more information or try again with a different card."
  ),
  "reepay/insufficient_funds": t(
    "There is not enough funds on your account. Please check your account and try again later."
  ),
  "reepay/other": t(
    "There has been an unknown payment issue. Please contact us, so that we can help you with the payment."
  ),

  else: "Unexpected error",
};

export default danishErrors;
