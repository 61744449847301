import { round } from "lodash";
import { OrderDiscount } from "../../../types/src/documents/base";

type OrderDiscountAmountCalculation = {
  totalDiscountInclVat: number;
  totalDiscountExclVat: number;
  totalDiscountVat: number;
};

export const calculateOrderDiscountAmount = (
  discounts: OrderDiscount[] | undefined,
  includeAlreadyDiscounted: boolean
): OrderDiscountAmountCalculation => {
  if (!discounts || !discounts.length) {
    return {
      totalDiscountInclVat: 0,
      totalDiscountExclVat: 0,
      totalDiscountVat: 0,
    };
  }

  const totalDiscountInclVat = discounts
    .filter((d) => includeAlreadyDiscounted || !d.alreadyDiscounted)
    .reduce((acc, discount) => acc + (discount.unitDiscountedAmount || 0), 0);

  const totalDiscountExclVat = discounts
    .filter((d) => includeAlreadyDiscounted || !d.alreadyDiscounted)
    .flatMap((d) => d.items)
    .reduce(
      (acc, item) => acc + (item.unitDiscountedPrice || 0) * item.amount,
      0
    );

  const totalDiscountVat = round(
    totalDiscountInclVat - totalDiscountExclVat,
    2
  );

  return { totalDiscountInclVat, totalDiscountExclVat, totalDiscountVat };
};
