import { Currency, PriceIncludingVat } from "..";

export type AdyenRecurringProcessingModel =
  | "CardOnFile"
  | "Subscription"
  | "UnscheduledCardOnFile";

export type AdyenShopperInteraction = "Ecommerce" | "ContAuth" | "POS" | "Moto";

export type RecurringAdyenSubmitData = {
  amount: {
    currency: Currency;
    value: PriceIncludingVat;
  };
  paymentMethod: {
    type: string;
    storedPaymentMethodId: string;
  };
  reference: string;
  merchantAccount: string;
  shopperReference: string;
  store?: string;
  recurringProcessingModel?: AdyenRecurringProcessingModel;
  shopperInteraction: AdyenShopperInteraction;
};

export type RecurringAdyenResponse = {
  additionalData: {
    cvcResult: string;
    authCode: string;
    avsResult: string;
    avsResultRaw: string;
    cvcResultRaw: string;
    refusalReasonRaw: string;
    acquirerCode: string;
    acquirerReference: string;
  };
  pspReference: string;
  resultCode: RecurringAdyenResultCodes;
  merchantReference: string;
};

export enum RecurringAdyenResultCodes {
  AuthenticationFinished = "AuthenticationFinished",
  AuthenticationNotRequired = "AuthenticationNotRequired",
  Authorised = "Authorised",
  Cancelled = "Cancelled",
  ChallengeShopper = "ChallengeShopper",
  Error = "Error",
  IdentifyShopper = "IdentifyShopper",
  PartiallyAuthorised = "PartiallyAuthorised",
  Pending = "Pending",
  PresentToShopper = "PresentToShopper",
  Received = "Received",
  RedirectShopper = "RedirectShopper",
  Refused = "Refused",
}
