import { PriceExcludingVat, PriceIncludingVat } from "@kanpla/types";
import React from "react";
import { usePriceFormatter } from "./usePriceFormatter";

/** Component version of the usePriceFormatter hook */

type Props = {
  price: PriceIncludingVat | PriceExcludingVat;
};

export const PriceFormatter = ({ price }: Props) => {
  const totalPriceItemsFormatted = usePriceFormatter(price);
  return <>{totalPriceItemsFormatted}</>;
};
