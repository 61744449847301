import { useWindowSize } from "@kanpla/system";
import React, { ReactNode, useEffect, useState } from "react";
import Modal, { ModalProps } from "../../modal/Modal";
import { BottomDrawer, BottomDrawerProps } from "./";
import { Action } from "./Actions";

export interface BottomDrawerOrModalCommonProps {
  open: boolean;
  setOpen: (nextOpen: boolean) => void;
  showCloseButton?: boolean;
  noPadding?: boolean;
  actions?: Array<Action>;
  title?: ReactNode;
  subtitle?: ReactNode;
  zIndex?: number;
  zMax?: boolean;
  closableOutside?: boolean;
  actionsClassName?: string;
  noPaddingOnActions?: boolean;
}

type PassingProps = BottomDrawerProps & ModalProps;

export interface DrawerOrModalProps extends PassingProps {
  setOpen: (nextOpen: boolean) => void;
  drawerProps?: Partial<BottomDrawerProps>;
  modalProps?: Partial<ModalProps>;
}

/**
 * @issues
 * Some components placed in the modal may not behave properly (e.g. `@kanpla/ui/Select`).
 * The solution might be to set `stopPropagation={false}`.
 */
export const DrawerOrModal = (props: DrawerOrModalProps) => {
  const { children, drawerProps = {}, modalProps = {}, open, setOpen } = props;

  const { width: screenWidth } = useWindowSize();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(open);
  }, [open]);

  const delayClose = () => {
    setMounted(false);
    setTimeout(() => {
      setOpen(false);
    }, 300);
  };

  // Rendering optimisations
  if (!open) return null;

  return screenWidth && screenWidth < 768 ? (
    <BottomDrawer
      {...props}
      {...drawerProps}
      open={mounted}
      setOpen={delayClose}
    >
      {children}
    </BottomDrawer>
  ) : (
    <Modal
      zIndex={props.zIndex || 60}
      {...props}
      {...modalProps}
      open={mounted}
      setOpen={delayClose}
    >
      {children}
    </Modal>
  );
};
