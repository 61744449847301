import { _FrontendModule } from "@kanpla/types";
import { useAtom } from "jotai";
import { moduleIdAtom } from "libs/ordering/src/context";
import { useEffect, useState } from "react";

const useModules = ({ modules = [] }: { modules: _FrontendModule[] }) => {
  // Current module in view
  const [moduleId, setModuleId] = useAtom(moduleIdAtom);
  const [previousModuleId, setPreviousModuleId] = useState<string | null>(null);
  const module = modules.find((m) => m.id === moduleId) || modules[0];

  // Default load moduleId
  useEffect(() => {
    if (module?.id && !moduleId) setModuleId(module?.id);
    return () => (module?.id ? setPreviousModuleId(module.id) : null);
  }, [module?.id, moduleId]);

  return {
    modules,
    module,
    moduleId: module?.id,
    setModuleId,
    previousModuleId,
  };
};

export default useModules;
