import { FirebaseError } from "firebase/app";
import { useMemo } from "react";
import { FirestoreDocument } from "@kanpla/types";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { db } from "../firebase.config";

type Props = {
  collection: string;
  id: string;
};

type Return<T> = [data: T, loading: boolean, error: FirebaseError];

export const useDocumentListener = <T extends FirestoreDocument>({
  collection,
  id,
}: Props): Return<T> => {
  // const postConverter: FirestoreDataConverter<T> = {
  //   toFirestore(document: WithFieldValue<T>): DocumentData {
  //     return { ...document };
  //   },
  //   fromFirestore(
  //     snapshot: QueryDocumentSnapshot,
  //     options: SnapshotOptions
  //   ): T {
  //     const data = snapshot.data(options) as T;
  //     const fullData = {
  //       ...data,
  //       id: snapshot?.id,
  //       ref: snapshot?.ref,
  //     };
  //     return fullData;
  //   },
  // };

  // const ref =
  //   typeof collection === `string` && typeof id === `string`
  //     ? doc(getFirestore(fb), collection, id).withConverter(postConverter)
  //     : null;

  const ref =
    typeof collection === `string` && typeof id === `string`
      ? db.collection(collection).doc(id)
      : null;

  //@ts-ignore
  const [data, loading, error] = useDocumentData<T>(ref, {
    idField: "id",
    refField: "ref",
  });

  // const data = [];
  // const loading = false;
  // const error = null;

  return useMemo<Return<T>>(
    () => [data, loading, error],
    [data, loading, error]
  );
};
