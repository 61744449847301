import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Message } from "@kanpla/types";
import { first, last, nth } from "lodash";
import React from "react";
import { toast } from "react-toastify";
import Spinner from "../../../loading/Spinner";

export abstract class MessageConfig {
  private static toastOptionsKeys: Array<keyof Message.ToastOptionsParam> = [
    "autoClose",
    "bodyClassName",
    "bodyStyle",
    "className",
    "closeButton",
    "closeOnClick",
    "containerId",
    "data",
    "delay",
    "draggable",
    "draggableDirection",
    "draggablePercent",
    "hideProgressBar",
    "icon",
    "isLoading",
    "onClick",
    "onClose",
    "onOpen",
    "pauseOnFocusLoss",
    "pauseOnHover",
    "position",
    "progress",
    "progressClassName",
    "progressStyle",
    "role",
    "rtl",
    "style",
    "theme",
    "toastId",
    "transition",
    "type",
    "updateId",
  ];

  private static messageCustomProps: Message.ToastMappedProps = {
    messageId: "toastId",
  };

  private static sharedClasses = {
    className: "!leading-none !p-3",
    bodyClassName: "!body-secondary",
  };

  /** Default methods configurations */
  private static methodConfig: Message.MethodConfig = {
    success: {
      className: `bg-success-backdrop ${this.sharedClasses.className}`,
      bodyClassName: `text-success-main ${this.sharedClasses.bodyClassName}`,
      icon: <FontAwesomeIcon icon={faCircleCheck} />,
    },
    error: {
      className: `bg-danger-backdrop ${this.sharedClasses.className}`,
      bodyClassName: `text-danger-main ${this.sharedClasses.bodyClassName}`,
      icon: <FontAwesomeIcon icon={faCircleXmark} />,
    },
    warning: {
      className: `bg-warning-backdrop ${this.sharedClasses.className}`,
      bodyClassName: `text-warning-main ${this.sharedClasses.bodyClassName}`,

      icon: <FontAwesomeIcon icon={faCircleExclamation} />,
    },
    info: {
      className: `bg-info-backdrop ${this.sharedClasses.className}`,
      bodyClassName: `text-info-main ${this.sharedClasses.bodyClassName}`,
      icon: <FontAwesomeIcon icon={faCircleInfo} />,
    },
    loading: {
      className: this.sharedClasses.className,
      bodyClassName: this.sharedClasses.bodyClassName,
      icon: <Spinner className="w-[14px] h-[14px]" />,
    },
    promise: {
      className: this.sharedClasses.className,
      bodyClassName: this.sharedClasses.bodyClassName,
    },
    "": {
      className: this.sharedClasses.className,
      bodyClassName: this.sharedClasses.bodyClassName,
      icon: false,
    },
  };

  private static refineOptions(
    options: Message.OptionsParam = {}
  ): Message.OptionsParam {
    const targetOptions: Message.OptionsParam = Object.entries(options).reduce(
      (acc, [key, value]) => {
        const isCustom = !this.toastOptionsKeys.includes(
          key as keyof Message.ToastOptionsParam
        );

        if (isCustom) return { ...acc, [this.messageCustomProps[key]]: value };

        return { ...acc, [key]: value };
      },
      {}
    );

    return targetOptions;
  }

  /** Get the default options (overwriteable) */
  static getDefaultOptions({ method }: Message.GetDefaultOptionsProps) {
    const config = this.methodConfig[method];

    const className = config.className || "";
    const bodyClassName = config.bodyClassName;
    const icon = config.icon;

    const iconProp = icon === undefined ? {} : { icon };

    const defaultOptions: Message.MessageProps = {
      position: toast.POSITION.TOP_CENTER,
      className,
      bodyClassName,
      ...iconProp,
    };

    return defaultOptions;
  }

  /** Get the params to pass to the single methods of react-toastify (toast[method](...params) or toast(...params)) */
  static getParams(
    params: Array<Message.MessageProps>,
    method: Message.MessageMethod
  ) {
    const content = first(params) as Message.ContentParam;

    const promiseParam =
      method === "promise"
        ? (nth(params, 1) as Message.PromiseParam)
        : undefined;

    const rawOptions = last(params) as Message.OptionsParam;
    const options = this.refineOptions(rawOptions);

    return { content, promiseParam, options };
  }
}
