import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGooglePay } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/pro-duotone-svg-icons";
import { School } from "@kanpla/models";
import { Partner, PaymentProvider } from "@kanpla/types";
import { useQuery } from "@tanstack/react-query";
import { t } from "@transifex/native";
import { callInternalApi, isApplePaySupported } from "..";

type PaymentMethodOption = {
  label: string;
  value: PaymentProvider;
  icon: IconProp | string;
};

export type AvailablePaymentProviders = Extract<
  PaymentProvider,
  "card" | "applepay" | "mobilepay" | "googlepay" | "swish" | "ideal" | "vipps"
>;

type PaymentMethodList = Record<AvailablePaymentProviders, PaymentMethodOption>;

export type AvailablePaymentMethodsArgs = {
  schoolId: School["id"];
  partnerId: Partner["id"];
};

export type AvailablePaymentMethodsResponse = {
  availablePaymentMethods: AvailablePaymentProviders[];
};

const allAvailablePaymentMethods: PaymentMethodList = {
  card: {
    label: t("Card"),
    value: "card",
    icon: faCreditCard,
  },
  applepay: {
    label: "Apple Pay",
    value: "applepay",
    icon: "/icons/payment-methods/applepay.svg",
  },
  mobilepay: {
    label: "Mobile Pay",
    value: "mobilepay",
    icon: "/icons/payment-methods/mobilepay.svg",
  },
  vipps: {
    label: "Vipps",
    value: "vipps",
    icon: "/icons/payment-methods/vipps.svg",
  },
  googlepay: {
    label: "Google Pay",
    value: "googlepay",
    icon: faGooglePay,
  },
  swish: {
    label: "Swish",
    value: "swish",
    icon: "/icons/payment-methods/swish.svg",
  },
  ideal: {
    label: "iDeal",
    value: "ideal",
    icon: "/icons/payment-methods/ideal.svg",
  },
};

export const useAvailablePaymentMethods = (
  schoolId: School["id"],
  partnerId: Partner["id"] | null
): { paymentMethodOptions: PaymentMethodOption[]; isLoading: boolean } => {
  const query = useQuery({
    queryKey: ["available-payment-methods", schoolId],
    queryFn: () => {
      if (!partnerId) return { availablePaymentMethods: [] };

      return callInternalApi<
        AvailablePaymentMethodsArgs,
        AvailablePaymentMethodsResponse
      >("payment/available-payment-methods", {
        schoolId,
        partnerId,
      });
    },
    enabled: !!schoolId && !!partnerId,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  if (!query.isSuccess)
    return { paymentMethodOptions: [], isLoading: query.isLoading };

  const paymentMethods = query.data?.availablePaymentMethods ?? [];
  if (!paymentMethods.length)
    return { paymentMethodOptions: [], isLoading: query.isLoading };

  const paymentMethodOptions: PaymentMethodOption[] = paymentMethods.flatMap(
    (method) => {
      if (method === "applepay" && !isApplePaySupported()) return [];
      return allAvailablePaymentMethods[method];
    }
  );

  return { paymentMethodOptions, isLoading: query.isLoading };
};
