import { useState } from "react";
import { APIError } from "../errors/api";
import { APICallOptions, callInternalApi } from "./callInternalApi";

export const useRequest = <RequestData, ResponseType>(
  /** path of the function inside the /api folder (e.g. "offers/loadFrontend") */
  path: string,
  options?: APICallOptions
) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const request = async (data: RequestData): Promise<ResponseType> => {
    setIsSubmitting(true);

    let res;
    try {
      res = await callInternalApi<RequestData, ResponseType>(
        path,
        data,
        options
      );
    } catch (err) {
      if (err instanceof APIError) throw err;
      throw new APIError(500, "unknown error");
    } finally {
      setIsSubmitting(false);
    }

    // if (!res) throw new APIError(500, "no response from the server");
    return res;
  };

  return { request, isSubmitting };
};
