import { getMondaySeconds, getWeekSeconds, tx, useT } from "@kanpla/system";
import moment from "moment";

interface Props {
  deadline: number;
  dateSeconds: number;
  deadlineWeekRelative: boolean;
  deadlineExcludingWeekends: boolean;
}

// Takes in number of seconds relative to UTC midnight of that day
export const useDeadlineFormatter = (props: Props) => {
  const t = useT();
  // Util to change the localization of moment.js

  const {
    deadline,
    dateSeconds: rawDateSeconds,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
  } = props;

  const dateSeconds = deadlineWeekRelative
    ? getMondaySeconds(rawDateSeconds)
    : rawDateSeconds;
  let deadlineSeconds = dateSeconds + deadline;

  // Account for excluding weekend
  if (deadlineExcludingWeekends && !deadlineWeekRelative) {
    const mondayBeforeDate = getWeekSeconds(dateSeconds);
    const mondayBeforeToday = getWeekSeconds(deadlineSeconds);
    const differenceOfMondays = mondayBeforeDate - mondayBeforeToday;
    const weeksCrossed = differenceOfMondays / 604800;

    const weekendSeconds = 86400 * 2;
    const secondsToSubtract = weeksCrossed * weekendSeconds;
    deadlineSeconds = deadlineSeconds - secondsToSubtract;
  }

  const deadlineDisplay = moment.utc(deadlineSeconds * 1000).calendar(null, {
    sameDay: `[${t("Today").toLowerCase()}][, ${
      tx.getCurrentLocale() !== "en" ? "kl." : ""
    }] LT`,
    nextDay: `[${t("tomorrow")}][, ${
      tx.getCurrentLocale() !== "en" ? "kl." : ""
    }] LT`,
    nextWeek: `dddd[, ${tx.getCurrentLocale() !== "en" ? "kl." : ""}] LT`,
    lastDay: `[${t("yesterday")}][, ${
      tx.getCurrentLocale() !== "en" ? "kl." : ""
    }] LT`,
    lastWeek: `[${t("last")}] dddd[, ${
      tx.getCurrentLocale() !== "en" ? "kl." : ""
    }] LT`,
    sameElse: `dddd, D/M[, ${tx.getCurrentLocale() !== "en" ? "kl." : ""}] LT`,
  });

  return deadlineDisplay;
};
