import { Order } from "@kanpla/models";
import { calculateOrderDiscountAmount } from "./calculateOrderDiscountAmount";
import {
  calculateOrderVatBreakdown,
  OrderInvoiceTotalResponse,
} from "./calculateOrderVatBreakdown";

interface Options {
  excludeConfigPrice?: boolean;
}
/**
 *
 * @param order Order to calculate price for
 * @param option Allows to modify the outcome
 * @returns Breakdown of the total price incl. discounts
 * @implements {OrderInvoiceTotalResponse}
 */
export const calculateOrderWithDiscountTotal = (
  order: Pick<Order, "orderLines" | "discounts">,
  option?: Options
): OrderInvoiceTotalResponse => {
  const orderLines = order.orderLines ?? [];
  const discounts = order.discounts ?? [];

  const orderVatBreakdown = calculateOrderVatBreakdown(
    orderLines,
    false,
    option?.excludeConfigPrice
  );
  const discountVatBreakdown = calculateOrderDiscountAmount(discounts, false);

  return {
    withoutVat:
      orderVatBreakdown.withoutVat - discountVatBreakdown.totalDiscountExclVat,
    vat: orderVatBreakdown.vat - discountVatBreakdown.totalDiscountVat,
    withVat:
      orderVatBreakdown.withVat - discountVatBreakdown.totalDiscountInclVat,
  };
};
