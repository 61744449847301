import { DayIndex } from "@kanpla/types";
import { getWeekSeconds } from "./getWeekSeconds";

export const dayIndexesArr = [0, 1, 2, 3, 4, 5, 6] as const;

export const getDayIndexFromSeconds = (seconds: number) => {
  const mondaySeconds = getWeekSeconds(seconds);
  const secondsDifference = seconds - mondaySeconds;
  const daysDifference = (secondsDifference / 86400) as DayIndex;
  return daysDifference;
};
