import { faCreditCardFront } from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import { Card, PaymentProvider } from "@kanpla/types";
import { Button, DrawerOrModal } from "@kanpla/ui";
import { Dispatch, SetStateAction, useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import MethodsWrapper from "./PaymentRowsWrapper";
import CardsManage from "./payment/CardsManage";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  selectedMethod: PaymentProvider | null;
  onSelect: (method: PaymentProvider) => void;
  /** Allow card editing */
  showCardButton?: boolean;
}

export const SelectPayment = (props: Props) => {
  const { open, setOpen, onSelect, selectedMethod } = props;
  const t = useT();
  const [openCardManager, setOpenCardManager] = useState(false);

  const { card } = useContainer(OrderingContext);

  const handleOnSelect = (provider: PaymentProvider) => {
    onSelect(provider);
    setOpen(false);
  };

  return (
    <DrawerOrModal
      open={open}
      setOpen={setOpen}
      title={t("Payment methods")}
      subtitle={t("How would you like to pay?")}
      drawerProps={{ showCloseButton: false }}
      zMax
    >
      <div className="w-full h-full flex flex-col pt-6 pb-8">
        <MethodsWrapper
          onClick={handleOnSelect}
          card={card as Card}
          method={selectedMethod || "card"}
        />
        {card && (
          <>
            <Button
              className="mt-6 mx-auto w-fit"
              icon={faCreditCardFront}
              onClick={() => setOpenCardManager(true)}
              dataCy="btn-payment-edit-cards"
            >
              {t("Edit multiple cards")}
            </Button>
            <CardsManage open={openCardManager} setOpen={setOpenCardManager} />
          </>
        )}
      </div>
    </DrawerOrModal>
  );
};
