import { PopConfirmButton, TooltipDisabledButton } from "@kanpla/types";
import { BaseButtonProps, Button } from "@kanpla/ui";
import { Popconfirm, Tooltip } from "antd";
import classnames from "classnames";
import React, { ReactNode, useMemo, useState } from "react";

export interface ActionButtonProps
  extends BaseButtonProps,
    TooltipDisabledButton,
    PopConfirmButton {
  className: string;
  label: string | ReactNode;
  onClick: () => Promise<void>;
  key?: string;
  dataCy?: string;
  autoWidth?: boolean;
}

export const ActionButton = (props: ActionButtonProps) => {
  const {
    onClick,
    label,
    className,
    type = "primary",
    dataCy = "",
    tooltip,
    autoWidth = false,
    popConfirm,
    ...otherProps
  } = props;

  const logicType = type === "default" ? "secondary" : type;

  const [loading, setLoading] = useState(false);

  const _onClick = async () => {
    if (typeof onClick === "undefined") return;

    setLoading(true);
    await onClick();
    setLoading(false);
  };

  if (popConfirm) {
    return (
      <Popconfirm {...popConfirm}>
        <Button
          size="large"
          type="primary"
          className="w-full"
          dataCy="modal-action-popconfirm"
        >
          {label}
        </Button>
      </Popconfirm>
    );
  }

  if (tooltip?.hasTooltip) {
    return (
      <Tooltip title={tooltip?.title}>
        <Button
          type={logicType}
          size="large"
          dataCy="modal-action-tooltip"
          style={tooltip?.style}
          {...props}
          className={`w-full flex-1 ${className || ""}`}
        >
          {label}
        </Button>
      </Tooltip>
    );
  }

  return (
    <Button
      type={logicType}
      shape="solid"
      size="large"
      onClick={_onClick}
      loading={loading}
      className={classnames(
        "flex-1",
        autoWidth ? "w-fit" : "w-full",
        className
      )}
      dataCy={dataCy}
      {...otherProps}
    >
      {label}
    </Button>
  );
};
