import { tx } from "@kanpla/system";
import { Currency } from "@kanpla/types";
import { atom, useAtomValue } from "jotai";

/** Use only in React. Hook that returns the display currency symbol for the active currency. */

export const activeCurrencyAtom = atom<Currency>("DKK");

export const useCurrencySymbol = () => {
  const activeCurrency = useAtomValue(activeCurrencyAtom);

  const currencySybmol = (0)
    .toLocaleString(tx.getCurrentLocale() || "da", {
      style: "currency",
      currency: activeCurrency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();

  return currencySybmol;
};
