import { trackingLogEvent, trackingSetUserId } from "@kanpla/ui";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

export const Tracking = () => {
  const { userId, childId, schoolId, moduleId } = useContainer(AppContext);
  const trackedProps = { childId, schoolId, moduleId };

  useEffect(() => {
    trackingSetUserId(userId);
  }, [userId]);

  useEffect(() => {
    trackingLogEvent(`Set childId`, trackedProps);
  }, [childId]);

  useEffect(() => {
    trackingLogEvent(`Set schoolId`, trackedProps);
  }, [schoolId]);

  useEffect(() => {
    trackingLogEvent(`Set moduleId`, trackedProps);
  }, [moduleId]);

  return null;
};

export default Tracking;
