const windowIsDefined = typeof window !== "undefined";

/** Check if the web app is being run on the native app */
export const isNative: boolean = windowIsDefined && window?.isRenderedInWebView;

/** Get the keyboard status, can be either "hidden" or "active" */
export const webViewKeyboardStatus: "hidden" | "active" | false =
  windowIsDefined && window?.webViewKeyboardStatus;

/** Check native app feature version */
export const webViewVersion: number | false =
  windowIsDefined && window?.version !== undefined && parseInt(window?.version);
