import { faXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SelectTypes } from "@kanpla/types";
import classNames from "classnames";
import React from "react";

const ClearIndicator: SelectTypes.ClearIndicator = ({
  innerProps,
}: SelectTypes.ClearIndicatorProps) => {
  const classes = classNames(
    "bg-secondary-main hover:bg-secondary-dark text-secondary-contrast hover:text-opacity-50 transition-all rounded-full w-[18px] h-[18px] flex items-center justify-center text-xs"
  );

  return (
    <div {...innerProps}>
      <div className={classes}>
        <FontAwesomeIcon icon={faXmark} />
      </div>
    </div>
  );
};

export default ClearIndicator;
