import {
  getActivePlugins,
  getDayIndexFromSeconds,
  getTodayTimestamp,
  Timestamp,
  useT,
} from "@kanpla/system";
import { LoadOfferReturn } from "@kanpla/types";
import { OrderingContextProps } from "../../context";

interface Props
  extends Pick<
    OrderingContextProps,
    | "dayIndex"
    | "week"
    | "offer"
    | "module"
    | "isBulk"
    | "dateSeconds"
    | "schoolId"
    | "moduleId"
  > {
  activePlugins: ReturnType<typeof getActivePlugins>["activePlugins"];
}

export const useHoliday = (props: Props) => {
  const { dateSeconds, offer, schoolId, moduleId, activePlugins } = props;

  const t = useT();

  const today = getTodayTimestamp({ Timestamp });

  const { holidayDates = [] as LoadOfferReturn["holidayDates"] } = offer || {};

  // Add option with holidays
  let activeHoliday = holidayDates?.[dateSeconds];

  const todayDayIndex = getDayIndexFromSeconds(today.seconds);
  const isWeekend = todayDayIndex > 4;
  if (
    isWeekend &&
    activePlugins["kanplaGo"] &&
    !activePlugins["sevenDayWeek"]
  ) {
    activeHoliday = {
      name: "Weekend",
      design: {
        title: t("The canteen doesn't sell on the weekend"),
        text: t("Come back on Monday"),
      },
      // Other props (not needed)
      partnerId: "",
      days: [],
      createdAt: 0,
      createdBy: "",
      schoolIds: [schoolId],
      moduleIds: [moduleId],
      id: "",
      type: "kitchen",
    };
  }

  return {
    activeHoliday,
    holidayDates,
  };
};
