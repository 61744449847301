import {
  faCircleXmark,
  faEnvelope,
  faEye,
  faEyeSlash,
  faMagnifyingGlass,
} from "@fortawesome/pro-solid-svg-icons";
import { useT } from "@kanpla/system";
import React, { useEffect, useState } from "react";
import { Button } from "../../button/Button";
import { BaseInput, InputProps } from "./BaseInput";
import { Color } from "./Color";
import { Number } from "./Number";
import { InputTag } from "./Tag";
import { TextArea } from "./TextArea";

interface InputPasswordProps
  extends Omit<InputProps, "type" | "icon" | "iconOnClick"> {
  hideToggle?: boolean;
}

const Password = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  (props, ref): JSX.Element => {
    const { hideToggle = false, ...rest } = props;
    const t = useT();

    const [pswVisible, setPswVisible] = useState<boolean>(false);
    const showIcon = hideToggle ? null : pswVisible ? faEye : faEyeSlash;

    return (
      <BaseInput
        label={props?.label || t("Password")}
        {...rest}
        type={pswVisible ? "text" : "password"}
        icon={showIcon}
        iconRight
        iconOnClick={() => setPswVisible((prev) => !prev)}
        ref={ref}
      />
    );
  }
);

const Email = React.forwardRef<
  HTMLInputElement,
  Omit<InputProps, "type" | "icon">
>((props, ref): JSX.Element => {
  return (
    <BaseInput
      {...props}
      type="email"
      icon={faEnvelope}
      label={props?.label || "E-mail"}
      iconRight
      ref={ref}
    />
  );
});

interface InputSearchProps {
  value: string;
  onChange: (newValue: string) => void;
  /** Displays a button instead of the input, to take less space. Defaults to `false` */
  withButton?: boolean;
}

type Props = InputSearchProps &
  Omit<InputProps, "type" | "icon" | "iconOnClick" | "value" | "onChange">;

const Search = React.forwardRef<HTMLInputElement, Props>(
  ({ value, onChange, withButton = false, ...rest }, ref): JSX.Element => {
    const [showInput, setShowInput] = useState<boolean>(!withButton);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const showIcon = searchQuery ? faCircleXmark : faMagnifyingGlass;

    useEffect(() => {
      onChange(searchQuery);
    }, [searchQuery]);

    return (
      <>
        {withButton && !showInput && (
          <Button
            icon={faMagnifyingGlass}
            className="px-4"
            onClick={() => {
              setShowInput(true);
            }}
          />
        )}
        {showInput && (
          <BaseInput
            {...rest}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            type="text"
            autoFocus={withButton}
            icon={showIcon}
            onBlur={() => {
              if (withButton) {
                setShowInput(false);
                setSearchQuery("");
              }
            }}
            iconOnClick={() => {
              if (!searchQuery) return;

              setSearchQuery("");
            }}
            iconRight
            ref={ref}
          />
        )}
      </>
    );
  }
);

type CompoundedInputType = typeof BaseInput & {
  Password: typeof Password;
  Email: typeof Email;
  Search: typeof Search;
  Number: typeof Number;
  TextArea: typeof TextArea;
  Tags: typeof InputTag;
  Color: typeof Color;
};

(BaseInput as CompoundedInputType).Password = Password;
(BaseInput as CompoundedInputType).Email = Email;
(BaseInput as CompoundedInputType).Search = Search;
(BaseInput as CompoundedInputType).Number = Number;
(BaseInput as CompoundedInputType).TextArea = TextArea;
(BaseInput as CompoundedInputType).Tags = InputTag;
(BaseInput as CompoundedInputType).Color = Color;

export const Input = BaseInput as CompoundedInputType;
