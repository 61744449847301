import { LanguageCode, SourceLanguageCode } from "deepl-node";

export const DeepLAvailableLanguages: LanguageCode[] = [
  "bg",
  "cs",
  "da",
  "de",
  "el",
  "es",
  "et",
  "fi",
  "fr",
  "hu",
  "id",
  "it",
  "ja",
  "ko",
  "lt",
  "lv",
  "nb",
  "nl",
  "pl",
  "ro",
  "ru",
  "sk",
  "sl",
  "sv",
  "tr",
  "uk",
  "zh",
  "en",
  "pt",
];

export const iso6393to1: Record<string, SourceLanguageCode> = {
  bul: "bg",
  ces: "cs",
  cmn: "zh",
  dan: "da",
  deu: "de",
  ell: "el",
  eng: "en",
  est: "et",
  fin: "fi",
  fra: "fr",
  hun: "hu",
  ind: "id",
  ita: "it",
  jpn: "ja",
  kor: "ko",
  lit: "lt",
  nld: "nl",
  nob: "nb",
  pol: "pl",
  por: "pt",
  ron: "ro",
  rus: "ru",
  slk: "sk",
  spa: "es",
  swe: "sv",
  tur: "tr",
  ukr: "uk",
};

export const getUserLanguage = (
  language: string | null,
  defaultLang = "en-GB"
): SourceLanguageCode => {
  const allowedWithCountryCode = DeepLAvailableLanguages.some((lang) =>
    lang.includes(language || defaultLang)
  );

  if (allowedWithCountryCode)
    return (language || defaultLang) as SourceLanguageCode;
  return (language || defaultLang).substring(0, 2) as SourceLanguageCode;
};
