import AdyenCheckout from "@adyen/adyen-web";
import GooglePay from "@adyen/adyen-web/dist/types/components/GooglePay";
import Ideal from "@adyen/adyen-web/dist/types/components/Ideal";
import { OrderingContext } from "@kanpla/ordering";
import { SaveCardAdyenServiceProps } from "@kanpla/services";

import ApplePayElement from "@adyen/adyen-web/dist/types/components/ApplePay";
import RedirectElement from "@adyen/adyen-web/dist/types/components/Redirect";
import { ResultCode } from "@adyen/adyen-web/dist/types/components/types";
import { PaymentAction } from "@adyen/adyen-web/dist/types/types";
import {
  callInternalApi,
  delayPromise,
  getErrorMessage,
  T,
  useT,
} from "@kanpla/system";
import {
  AdyenCheckoutApplePayConfiguration,
  AdyenCheckoutOrApplePayConfiguration,
  AllowedPaymentMethodsEnum,
  OrderLines,
  SubmitAdyenAuthenticationEndpointResponse,
} from "@kanpla/types";
import { message, Spinner } from "@kanpla/ui";
import { useMutation } from "@tanstack/react-query";
import { atom, useAtom, useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useContainer } from "unstated-next";
import { confirmationModalOpenAtom } from "../../../mealplan2/basket/elements/PurchaseAnimation";
import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import {
  checkoutItemsAtom,
  kanplaGoReceiptOpenAtom,
} from "../../../mealplan2/kanplaGo/useKanplaGoListener";
import { basketAtom, openBasketAtom } from "../../basket/useBasket";
import { useSubmitAdyenAuthenticationResult } from "./useSubmitAdyenAuthenticationResult";

export const showPendingOrdersAtom = atom(false);

interface AdyenCheckoutConfigArgs {
  options: AdyenCheckoutOrApplePayConfiguration;
  onSuccess: () => void;
  rememberCard?: boolean;
  merchantId?: string;
}

export const adyenPaymentCheckoutConfigAtom =
  atom<AdyenCheckoutConfigArgs | null>(null);

interface AdyenPaymentComponentProps {
  type: AllowedPaymentMethodsEnum;
  appleGoogleButtonColor?: AdyenCheckoutApplePayConfiguration["buttonColor"];
  className?: string;
  config?: AdyenCheckoutConfigArgs;
  isAddingCard?: boolean;
  closePaymentDrawer?: () => void;
}

function useReceiptModal() {
  const setReceiptOpen = useSetAtom(kanplaGoReceiptOpenAtom);
  const setCheckoutItems = useSetAtom(checkoutItemsAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);
  const setShowPendingOrders = useSetAtom(showPendingOrdersAtom);
  const basket = useAtomValue(basketAtom);
  const { module } = useContainer(OrderingContext);

  // Persist the basket to show pending orders,
  // as the basket clears when payment is successful
  const persistedBasket = React.useRef<OrderLines>([]);
  if (basket.length) persistedBasket.current = basket;

  const showReceiptModal = () => {
    setReceiptOpen(true);
    setShowPendingOrders(true);
    if (module.flow === "registering") setOpenBasket(false);
    setCheckoutItems(persistedBasket.current);
  };

  return showReceiptModal;
}

const AdyenPaymentComponent = ({
  type,
  appleGoogleButtonColor = "black",
  className,
  id,
  config,
  isAddingCard = false,
  closePaymentDrawer,
}: AdyenPaymentComponentProps & React.HTMLAttributes<HTMLDivElement>) => {
  const paymentContainerRef = useRef<HTMLDivElement>(null);
  const { mutate } = useSubmitAdyenAuthenticationResult({
    isAddingCard,
    closePaymentDrawer,
  });

  const t = useT();

  const { reloadOrders, hasKanplaGo } = useContainer(OrderingContext);

  const { mutateAsync: handleResultCode } = useHandleAdyenThreeDSResultCode({
    isAddingCard,
    closePaymentDrawer,
  });

  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);

  const [adyenCheckoutConfig, setAdyenCheckoutConfig] = useAtom(
    adyenPaymentCheckoutConfigAtom
  );

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isCreatingCheckout, setIsCreatingCheckout] = useState<boolean>(false);
  const setConfirmationModalOpen = useSetAtom(confirmationModalOpenAtom);

  const { options, onSuccess, merchantId } =
    adyenCheckoutConfig || config || {};

  const createCheckout = async (
    options: AdyenCheckoutOrApplePayConfiguration
  ) => {
    try {
      setIsCreatingCheckout(true);
      if (!paymentContainerRef.current) return;

      let paymentElement:
        | undefined
        | ApplePayElement
        | Ideal
        | GooglePay
        | RedirectElement = undefined;

      let configuration: AdyenCheckoutOrApplePayConfiguration = {
        ...options,
        showPayButton: true,
        onAdditionalDetails: (state) => {
          return mutate({
            threeDSResult: state.data.details.threeDSResult,
          });
        },
        onPaymentCompleted: async (result: {
          resultCode: ResultCode;
          action?: PaymentAction;
        }) => {
          if (!isEmpty(result.action)) {
            if (!paymentElement)
              throw new Error("Payment element is not defined");

            paymentElement.handleAction(result.action);
          }

          await handleResultCode(result, {
            onSuccess: (resultString) => {
              message.success(resultString, {
                toastId: "payment-result-code",
              });
            },
            onError: (err) => {
              message.error(getErrorMessage(err), {
                toastId: "payment-result-code",
              });
            },
          });

          if (!hasKanplaGo) {
            setConfirmationModalOpen(true);
            setTimeout(() => setConfirmationModalOpen(false), 3000);
          }

          onSuccess?.();
          reloadOrders();

          await delayPromise(2000);
          reloadOrders();
          await delayPromise(2000);
          reloadOrders();
          await delayPromise(2000);
          reloadOrders();
          await delayPromise(5000);
          reloadOrders();
        },
        onError() {
          message.error(
            isAddingCard
              ? t("An issue occurred while adding the card")
              : t("Payment failed"),
            { toastId: "payment-failed" }
          );
          setAdyenCheckoutConfig(null);
        },
      };

      if (["applepay", "googlepay", "mobilepay", "ideal"].includes(type)) {
        configuration = {
          ...configuration,
          buttonType: "check-out",
          buttonColor: appleGoogleButtonColor,
        };
      }

      const checkout = await AdyenCheckout(configuration);

      // ApplePay and GooglePay need to be validated before they can be mounted
      if (type === "applepay") {
        paymentElement = checkout.create("applepay");
        const isAvailable = await paymentElement.isAvailable();

        if (!isAvailable) return;
      } else if (type === "ideal") {
        paymentElement = checkout.create("ideal", {
          configuration: { type: "ideal" },
        }) as Ideal;
      } else if (type === "googlepay") {
        if (!merchantId) return;
        paymentElement = checkout.create("googlepay", {
          configuration: {
            gatewayMerchantId: merchantId,
            merchantName: "Kanpla",
            merchantId: process.env["NEXT_PUBLIC_GOOGLE_PAY_MERCHANT"],
          },
        });

        if ("isAvailable" in paymentElement) {
          const isAvailable = await paymentElement.isAvailable();
          if (!isAvailable) return;
        }

        // other payment methods do not need to be validated
      } else {
        paymentElement = checkout.create(type);
      }
      if (!paymentContainerRef.current) return;
      // console.log("🚀 ~ paymentElement:", paymentElement);
      paymentElement.mount(paymentContainerRef.current);
    } catch (err) {
      console.error(err);
      setErrorMessage(getErrorMessage(err));
    } finally {
      setIsCreatingCheckout(false);
    }
  };

  useEffect(() => {
    setErrorMessage(null);
    if (!options || !paymentContainerRef.current) return;

    createCheckout(options);
  }, [selectedPaymentMethod, options?.session.id]);

  return (
    <div>
      <div id={id} ref={paymentContainerRef} className={className} />

      {isCreatingCheckout && (
        <div className="flex flex-row items-center justify-center my-6 md:my12 gap-4">
          <Spinner size="small" /> <T _str="Loading payment gateway" />
        </div>
      )}

      {errorMessage && (
        <div className="p-6 rounded border-danger-light bg-danger-backdrop">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

export default AdyenPaymentComponent;

export const useHandleAdyenThreeDSResultCode = (cardOptions?: {
  isAddingCard?: boolean;
  closePaymentDrawer?: () => void;
}) => {
  const { paymentGatewayId, user } = useContainer(OrderingContext);
  const t = useT();
  const showReceiptModal = useReceiptModal();
  const [adyenCheckoutConfig, setAdyenCheckoutConfig] = useAtom(
    adyenPaymentCheckoutConfigAtom
  );

  const isAddingCard = cardOptions?.isAddingCard ?? false;
  const closePaymentDrawer = cardOptions?.closePaymentDrawer;

  return useMutation({
    mutationFn: async (data: SubmitAdyenAuthenticationEndpointResponse) => {
      setAdyenCheckoutConfig(null);

      const resultCode = data.resultCode;

      if (resultCode === "Authorised") {
        if (!isAddingCard) return t("Payment successful") as string;

        // Here we need to open the receipts modal and load with the temp basket and display a message
        showReceiptModal();
        !!closePaymentDrawer && closePaymentDrawer();

        if (adyenCheckoutConfig?.rememberCard && paymentGatewayId && user) {
          // Save the card id for later payments in the user wallet
          await callInternalApi<SaveCardAdyenServiceProps, void>(
            "payment/saveCardAdyen",
            {
              user,
              paymentGatewayId,
            }
          );

          return t("Card added successfully") as string;
        }
        // close the payment drawer when the payment is successful
      }

      // Handle refusals
      if (resultCode === "Refused") {
        const resultString = [
          t(
            "The payment has been refused. Try again or with a different card."
          ),
        ];
        if (data.refusalReason) resultString.push(data.refusalReason);

        throw new Error(resultString.join(" "));
      }

      if (resultCode === "Error") {
        const resultString = [
          t("There was an error when the payment was being processed"),
        ];
        if (data.refusalReason) resultString.push(data.refusalReason);

        throw new Error(resultString.join(": "));
      }

      if (resultCode === "Cancelled") {
        throw new Error(t("The payment has been cancelled"));
      }

      return resultCode;
    },
  });
};
