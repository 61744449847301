import classNames from "classnames";
import React, { forwardRef } from "react";
import { HexColorPicker } from "react-colorful";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../elements/Popover";
import { Input } from "./Input";

interface ColorProps {
  value: string;
  onChange: (value: string) => void;
  zMax?: boolean;
}

export const Color = forwardRef<HTMLInputElement, ColorProps>(
  ({ value, onChange, zMax = false }, ref) => {
    return (
      <Popover>
        <div className="w-full h-fit relative">
          <Input
            className="w-full pr-8"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            ref={ref}
          />
          <PopoverTrigger asChild>
            <div
              className="absolute top-[11px] right-4 border border-secondary-main rounded-full w-6 h-6 cursor-pointer hover:opacity-80 transition-opacity ease-in-out"
              style={{ backgroundColor: value }}
            />
          </PopoverTrigger>
        </div>
        <PopoverContent className={classNames("!p-0 !w-fit", zMax && "!z-max")}>
          <HexColorPicker color={value} onChange={onChange} className="!p-0" />
        </PopoverContent>
      </Popover>
    );
  }
);
