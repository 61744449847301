import { OrderingContext } from "@kanpla/ordering";
import { SessionAdyenResponse, SessionAdyneCardArgs } from "@kanpla/services";
import { callInternalApi } from "@kanpla/system";
import { activeCurrencyAtom } from "@kanpla/ui";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import useChargeSession from "../../UseChargeSession";

interface UseSessionCardAdyenArgs {
  open: boolean;
}

export const useSessionCardAdyen = ({ open }: UseSessionCardAdyenArgs) => {
  const { schoolId, partnerId, userId } = useContainer(OrderingContext);
  const currency = useAtomValue(activeCurrencyAtom);

  const { loadChargeSession } = useChargeSession({
    setLoading: () => null,
    onError: (err) => {
      console.error(err);
    },
  });

  const callbackUrl = typeof window !== "undefined" ? window.location.href : "";

  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ["getAdyenSessionForCard"],
    queryFn: async () => {
      const cardSessionData = await callInternalApi<
        SessionAdyneCardArgs,
        SessionAdyenResponse
      >("payment/getAdyenSessionForCard", {
        schoolId,
        partnerId: partnerId || "",
        baseUrlForWindow: callbackUrl,
        currency,
        userId,
      });

      if (!cardSessionData) return;

      const session = await loadChargeSession({
        ...cardSessionData,
        provider: "adyen",
      });

      return session;
    },
    enabled: !!partnerId && !open,
  });

  useEffect(() => {
    if (!open) return;

    refetch();
  }, [open]);

  return {
    sessionData: data,
    isLoading,
    isError,
  };
};
