import { useT } from "@kanpla/system";
import { Popup, PopupConstructor } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useState } from "react";
import { useLocalstorageState } from "rooks";
import Interaction from "../../popups/Interaction";

interface Props {
  text: string;
  votedFor?: string;
  imageUrl?: string;
  popup: Popup;
  closedAt?: number;
  type: string;
}

const InteractionPreview = (props: Props) => {
  const {
    text,
    type,
    popup,
    votedFor = null,
    imageUrl = null,
    closedAt = null,
  } = props;

  const t = useT();

  // Opens interaction modal
  const [open, setOpen] = useState(false);
  const [canVoteAgain, setCanVoteAgain] = useState(closedAt || !votedFor);
  const [, setSavedPopup] = useLocalstorageState<Popup | null>("saved-popup");

  return (
    <>
      <div className="w-full h-full">
        {canVoteAgain ? (
          <div className="w-full flex items-center justify-between">
            {text}
            <Button
              type="primary"
              size="small"
              shape="solid"
              onClick={() => {
                setOpen(true);
              }}
              dataCy="btn-interaction-vote-now"
            >
              {t("Vote now")}
            </Button>
          </div>
        ) : (
          <div className="flex flex-row items-center mt-1">
            {type === "image" && imageUrl && (
              <img
                className="rounded-full w-8 h-8 object-cover mr-2"
                src={imageUrl}
                alt={votedFor}
              />
            )}
            <span className="text-text-primary leading-relaxed">
              {votedFor}
            </span>
          </div>
        )}
      </div>
      <Interaction
        popup={popup as PopupConstructor.Interaction}
        closePopup={() => setSavedPopup(null)}
        open={open}
        setOpen={setOpen}
        onVoteCallback={() => setCanVoteAgain(false)}
      />
    </>
  );
};

export default InteractionPreview;
