import { db, fetchDocument, T } from "@kanpla/system";
import { Notification, Product, ScheduledNotification } from "@kanpla/types";
import { Button } from "@kanpla/ui";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../../contextProvider";
import { useFeedback } from "../../feedback/useFeedback";
import { notificationCenterOpenAtom } from "../NotificationCenter";

interface Props {
  body: string;
  message: Notification;
  showingToday: boolean;
}

const PushNotificationPreview = (props: Props) => {
  const { body, message, showingToday = false } = props;

  const { modules, school, setSchoolId, setModuleId, setDate } =
    useContainer(AppContext);

  const [product, setProduct] = useState<Product>({} as Product);

  const setNotificationCenterOpen = useSetAtom(notificationCenterOpenAtom);

  const { surveysResponded, openSurveyModal } = useFeedback();

  /** If the survey has already been answered, the button is disabled */
  const isReplyBtnDisabled = (surveyId: string) => {
    // find the latest response for this survey, the responsed MUST be sorted by dateSeconds
    const response = surveysResponded.find((r) => r.surveyId === surveyId);

    const messageSentSecond =
      message?.lastSentAtSeconds ||
      (message as ScheduledNotification)?.dateTimeSeconds ||
      message?.createdAtSeconds;

    // true, if the user has replied since the last message was sent
    if (response && response.dateSeconds > messageSentSecond) {
      return true;
    }
    return false;
  };

  const { moduleId, extraQueries } = message.payload || {};

  const module = modules?.find((module) => module?.id === moduleId);

  const { productId, surveyId } = extraQueries || {};

  const buttonLabel = useMemo(() => {
    if (productId && product?.name) {
      return product?.name;
    }

    return module?.displayName;
  }, [module?.displayName, product?.name, productId]);

  const fetchProduct = useCallback(async () => {
    const product = await fetchDocument<Product>(
      db.collection("products").doc(productId),
      false
    );
    if (product) setProduct(product);
  }, [productId]);

  useEffect(() => {
    if (!productId || !moduleId) return;

    fetchProduct();
  }, [fetchProduct, moduleId, product, productId]);

  const onClickNotification = () => {
    if (moduleId) {
      if (school) setSchoolId(school.id);
      const messageDateSeconds = message.payload?.extraQueries?.dateSeconds;
      if (messageDateSeconds) setDate({ dateSeconds: messageDateSeconds });
      setModuleId(moduleId);
      setNotificationCenterOpen(false);
    }
  };

  return (
    <div className="w-full flex flex-col justify-between">
      {body}
      {moduleId && buttonLabel && (
        <Button
          onClick={onClickNotification}
          size="small"
          type={showingToday ? "primary" : "secondary"}
          dataCy="navigate-notification"
          className="mt-2"
        >
          {buttonLabel}
        </Button>
      )}
      {surveyId && (
        <Button
          disabled={isReplyBtnDisabled(surveyId)}
          onClick={() => {
            openSurveyModal(surveyId);
            setNotificationCenterOpen(false);
          }}
          size="small"
          type={showingToday ? "primary" : "secondary"}
          dataCy="navigate-notification"
        >
          <T _str={isReplyBtnDisabled(surveyId) ? "Replied" : "Reply"} />
        </Button>
      )}
    </div>
  );
};

export default PushNotificationPreview;
