import { useCallback, useEffect } from "react";

/** This function triggers a callback when you press on the ESC key */
export const useEscapeKeyPress = (callback: () => void) => {
  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return null;
};
