import { useT } from "@kanpla/system";
import {
  Button,
  Input,
  activeCurrencyAtom,
  useCurrencySymbol,
} from "@kanpla/ui";
import { useAtomValue } from "jotai";

interface Props {
  amount: number;
  setAmount: (newAmount: number) => void;
}

export const currencyNumbers = {
  DKK: [50, 100, 200, 500, 1000],
  NOK: [50, 100, 200, 500, 1000],
  SEK: [50, 100, 200, 500, 1000],
  GBP: [5, 10, 20, 50, 100],
  EUR: [5, 10, 20, 50, 100],
  CHF: [5, 10, 20, 50, 100],
};

const RefillAmount = ({ amount, setAmount }: Props) => {
  const t = useT();
  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const currencySymbol = useCurrencySymbol();

  const activeNumbers = currencyNumbers[activeCurrency || "DKK"];

  return (
    <>
      <Input.Number
        className="mb-2"
        id="payment-amount"
        placeholder={String(activeNumbers[2])}
        value={String(amount) === "0" ? undefined : amount}
        // convert UI amount to smaller currency unit
        onChange={(amount) => setAmount(amount)}
        label={t("({currencySymbol}) Amount", { currencySymbol })}
        required
        onBlur={() => amount < activeNumbers[0] && setAmount(50)}
      />
      <div className="flex -mx-2 justify-center">
        {activeNumbers.map((amount) => (
          <Button
            key={`payment-insert-${amount}-${activeCurrency}`}
            size="small"
            className="mr-1"
            onClick={() => setAmount(amount)}
            id={`cy-payment-insert-${amount}`}
            dataCy={`cy-payment-insert-${amount}`}
            shape="solid"
          >
            {amount}
          </Button>
        ))}
      </div>
    </>
  );
};

export default RefillAmount;
