import { useT } from "@kanpla/system";
import { ScheduledNotification } from "@kanpla/types";
import { Tabs, TabsContent } from "@kanpla/ui";
import {
  GroupType,
  Notifications,
} from "apps/frontend/pages/api/internal/notifications/fetchNotifications";
import { NormalizedPopup } from "apps/frontend/pages/api/internal/popups/fetchPopupHistory";
import { useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { NotificationCenterContext, showNotificationBadgeAtom } from ".";
import { AppContext } from "../contextProvider";
import DayHeader from "./DayHeader";
import EmptyView from "./EmptyView";
import MessageLine from "./MessageLine";
import TabContentWrapper from "./TabContentWrapper";

export type AllMessages = {
  [key in GroupType]: (
    | NormalizedPopup
    | (Notification & ScheduledNotification)
  )[];
};

interface Props {
  allMessages: AllMessages;
  notifications: Notifications;
  popups: NormalizedPopup[];
  isViewing?: boolean;
}

const Content = (props: Props) => {
  const { mobile } = useContainer(AppContext);
  const {
    newMessagesAmount,
    setNewMessagesAmount,
    groupedNotifications,
    groupedPopups,
  } = useContainer(NotificationCenterContext);
  const setShowNotificationBadge = useSetAtom(showNotificationBadgeAtom);

  const { allMessages, notifications, popups, isViewing = null } = props;

  const filterNotifications = notifications.filter(
    (notification) => !notification?.sendPopup
  );

  const t = useT();

  useEffect(() => {
    if (mobile && !isViewing) return;

    setShowNotificationBadge(false);

    return () => setNewMessagesAmount(0);
  }, [isViewing]);

  const hasRecentNotifications =
    Boolean(allMessages?.["today"]) && !isEmpty(allMessages?.["today"]);
  const hasPreviousNotifications =
    Boolean(allMessages?.["previous"]) && !isEmpty(allMessages?.["previous"]);

  const noNotifications = isEmpty(filterNotifications);
  const noPopups = isEmpty(popups);

  const noMessages = noNotifications && noPopups;

  return (
    <Tabs
      defaultActiveKey="all"
      className="px-3 md:mt-2"
      tabs={[
        {
          label: t("All"),
          key: "all",
        },
        {
          label: t("Messages"),
          key: "messages",
        },
        {
          label: t("Popups"),
          key: "popups",
        },
      ]}
    >
      <TabsContent value="all">
        <TabContentWrapper mobile={mobile} noMessages={noMessages}>
          {noMessages && <EmptyView type="message" />}
          {!noMessages &&
            Object.entries(allMessages).map(([day, notifications]) => {
              const label = day === "today" ? t("Today") : t("Earlier");
              const isToday = day === "today";

              return (
                <div key={day}>
                  <DayHeader
                    label={label}
                    withMargin={isToday}
                    mobile={mobile}
                  />
                  {(!hasRecentNotifications && isToday) ||
                    (!isToday && !hasPreviousNotifications && (
                      <EmptyView type="message" />
                    ))}
                  {notifications
                    .filter((n) => !n?.sendPopup)
                    .map((n, i) => (
                      <MessageLine
                        key={i}
                        message={n}
                        isForToday={isToday}
                        isRecent={isToday && i < newMessagesAmount}
                      />
                    ))}
                </div>
              );
            })}
        </TabContentWrapper>
      </TabsContent>

      <TabsContent value="messages">
        <TabContentWrapper mobile={mobile} noMessages={noMessages}>
          {noNotifications && <EmptyView type="message" />}
          {!noNotifications &&
            Object.entries(groupedNotifications).map(([day, notifications]) => {
              const isToday = day === "today";
              const label = isToday? t("Today") : t("Earlier");


              return (
                <div key={day}>
                  <DayHeader
                    label={label}
                    withMargin={isToday}
                    mobile={mobile}
                  />
                  {(!hasRecentNotifications && isToday) ||
                    (!isToday && !hasPreviousNotifications && (
                      <EmptyView type="message" />
                    ))}
                  {(notifications as any[])
                    .filter((n) => !n?.sendPopup)
                    .map((n, i) => (
                      <MessageLine
                        key={i}
                        message={n}
                        isForToday={isToday}
                        isRecent={isToday && i < newMessagesAmount}
                      />
                    ))}
                </div>
              );
            })}
        </TabContentWrapper>
      </TabsContent>

      <TabsContent value="popups">
        <TabContentWrapper mobile={mobile} noMessages={noMessages}>
          {noPopups && <EmptyView type="popup" />}
          {!noPopups &&
            Object.entries(groupedPopups).map(([day, popups]) => {
              const isToday = day === "today";
              const label = isToday ? t("Today") : t("Earlier");


              return (
                <div key={day}>
                  <DayHeader
                    label={label}
                    withMargin={isToday}
                    mobile={mobile}
                  />
                  {(!hasRecentNotifications && isToday) ||
                    (!isToday && !hasPreviousNotifications && (
                      <EmptyView type="message" />
                    ))}
                  {(popups as any[]).map((n, i) => (
                    <MessageLine
                      key={i}
                      message={n}
                      isForToday={isToday}
                      isRecent={isToday && i < newMessagesAmount}
                    />
                  ))}
                </div>
              );
            })}
        </TabContentWrapper>
      </TabsContent>
    </Tabs>
  );
};

export default Content;
