import { Language } from "@kanpla/types";
import { last } from "lodash";
import { useEffect, useState } from "react";

const DOMAINS_MAPPING: { [tlDomain: string]: Language } = {
  dk: "da",
  no: "no",
  nl: "nl",
  se: "sv",
  uk: "en",
  com: "en",
  io: "en",
};

/**
 * @param domain the host address (domain), use the actual one,
 * e.g. app.blueapplecatering.co.uk instad of blueapple.kanpla.dk
 * @returns Language or null based on the predefined match
 */
export const useDomainLanguage = () => {
  const [domainLanguage, setDomainLanguage] = useState<Language | null>(null);

  useEffect(() => {
    if (typeof window === "undefined") return;

    const hostname = window.location.hostname;
    if (!hostname) return;

    const tlDomain = last(hostname.split("."));

    if (!tlDomain) return;

    const mappedLanguage = DOMAINS_MAPPING[tlDomain];

    setDomainLanguage(mappedLanguage);
  }, [typeof window]);

  return domainLanguage;
};
