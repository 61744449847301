/**
 *
 * @param userBalance User's balance in smaller currency unit
 * @param basketPrice The cumulative price of the basket in smaller currency unit
 * @param selectedBalanceRefillAmount The user-selected refill amount in smaller currency unit
 * @returns The amount to charge in smaller currency unit
 */
export const calculatePaymentOnceAmount = (
  userBalance: number,
  basketPrice?: number,
  selectedBalanceRefillAmount?: number
) => {
  // No charge amount provided
  if (!basketPrice && !selectedBalanceRefillAmount) {
    throw new Error("No charge amount provided.");
  }
  // Either of the charge amounts is negative
  if (
    (basketPrice && basketPrice < 0) ||
    (selectedBalanceRefillAmount && selectedBalanceRefillAmount < 0)
  ) {
    throw new Error("Invalid charge amount provided.");
  }
  // No basket price, just refill amount is provided
  if (!basketPrice && selectedBalanceRefillAmount) {
    return selectedBalanceRefillAmount;
  }
  // Basket price is provided and valid
  if (basketPrice) {
    const newBalance = userBalance - basketPrice;
    const hasMissingMoney = newBalance < 0;
    const necessaryAmount = hasMissingMoney
      ? Math.abs(newBalance)
      : basketPrice;
    const greaterAmountToCharge = Math.max(
      necessaryAmount,
      selectedBalanceRefillAmount ?? 0
    );
    return greaterAmountToCharge;
  }
};
