import { faCreditCard } from "@fortawesome/pro-duotone-svg-icons";
import { useAvailablePaymentMethods, useT } from "@kanpla/system";
import { Card, PaymentProvider } from "@kanpla/types";
import { Spinner } from "@kanpla/ui";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { OrderingContext } from "../../context";
import PaymentRow from "./PaymentRow";

interface Props {
  onClick: (val: PaymentProvider) => void;
  method: PaymentProvider | null;
  card: Card;
}

const PaymentRowsWrapper = (props: Props) => {
  const { onClick, card, method } = props;
  const t = useT();
  const { schoolId, partnerId } = useContainer(OrderingContext);
  const { paymentMethodOptions, isLoading } = useAvailablePaymentMethods(
    schoolId,
    partnerId
  );

  const [selected, setSelected] = useState<PaymentProvider>(method || "card");

  const getCardBrandName = (type: string) => {
    if (!card) return t("Card");

    switch (type) {
      case "visa":
        return "Visa";
      case "mc":
        return "Mastercard";
      case "amex":
        return "American Express";
      case "dankort":
        return "Dankort";
      default:
        return t("Default card");
    }
  };

  return (
    <div className="w-full h-full">
      {isLoading && <Spinner />}
      {paymentMethodOptions
        .filter((o) => {
          if (o.value !== "card") return true;
          return !card;
        })
        .map((option) => (
          <PaymentRow
            name={option.label}
            value={option.value}
            icon={option.icon}
            selected={selected}
            onChange={(val: PaymentProvider) => {
              setSelected(val);
              onClick(val);
            }}
          />
        ))}
      {card && (
        <PaymentRow
          name={getCardBrandName(card.metadata?.brand)}
          icon={faCreditCard}
          value="card"
          detail={t("ends with {value}", { value: card.metadata.last4 })}
          selected={selected}
          onChange={(val) => {
            setSelected(val);
            onClick(val);
          }}
        />
      )}
    </div>
  );
};

export default PaymentRowsWrapper;
