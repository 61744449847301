import type { Currency } from "@kanpla/types";

export const priceFormatter = (
  value: number | string = 0,
  currency: Currency,
  locale?: string | null,
  options?: {
    showDecimals?: boolean;
    hideCurrency?: boolean;
  }
) => {
  /** Sometimes happens that the value is directly a string */
  if (typeof value === "string") return value;

  // @ts-ignore
  if (!currency || currency === "undefined") return "Missing currency";

  if (Object.is(value, -0)) value = 0;

  /** Convert from unit value to display value (e.g. "cents" to "euro") */
  const priceCurrency = value / 100;

  /** Format the price */
  const price = new Intl.NumberFormat(locale || "da-DK", {
    style: options?.hideCurrency ? "decimal" : "currency",
    currency: currency || "DKK",
    minimumFractionDigits: options?.showDecimals
      ? 2
      : priceCurrency % 1 === 0
      ? 0
      : 2,
    maximumFractionDigits: options?.showDecimals
      ? 2
      : priceCurrency % 1 === 0
      ? 0
      : 2,
  }).format(priceCurrency);

  return price;
};
