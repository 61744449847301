import { User } from "@kanpla/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { fb } from "../firebase.config";
import useWebPushNotifications from "./useWebPushNotifications";

interface Props {
  user: User;
  setNotificationBadge?: Dispatch<SetStateAction<boolean>>;
}

export const useFCMNotifications = (props: Props) => {
  const { setNotificationBadge = () => null, user } = props;

  const [notification, setNotification] = useState(null);

  const { doesBrowserSupportsPush } = useWebPushNotifications(user);

  useEffect(() => {
    if (typeof window !== "undefined" && window?.["isRenderedInWebView"])
      return;

    const isPushSupported = doesBrowserSupportsPush();
    if (!isPushSupported) return;

    // Handle notifications if the app is foregrounded
    fb?.messaging()?.onMessage((payload) => {
      const { notification } = payload;

      setNotification(notification);
      setNotificationBadge(true);
    });
  }, [doesBrowserSupportsPush, setNotificationBadge]);

  return {
    notification,
  };
};
