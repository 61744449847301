import { faBell, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNotifications } from "@kanpla/system";
import { message } from "@kanpla/ui";
import "firebase/messaging";
import { useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import { generate } from "short-uuid";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";
import { showNotificationBadgeAtom } from "./notifications";

const FCM = () => {
  const { user, auth } = useContainer(AppContext);

  const [open, setOpen] = useState(false);
  const [notification, setNotification] = useState(null);
  const setShowNotificationBadge = useSetAtom(showNotificationBadgeAtom);

  const { notification: _notification } = useNotifications({
    user,
    auth,
    setNotificationBadge: setShowNotificationBadge,
  });

  useEffect(() => {
    (async () => {
      try {
        if (typeof window === "undefined") return;

        setNotification(_notification);
        setOpen(true);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [_notification]);

  useEffect(() => {
    if (!open) return;
    if (!notification) return;
    const { title, body } = notification;

    const messageId = generate();

    message.success(
      ({ closeToast }) => (
        <div className="w-full flex justify-between">
          <div className="w-full flex flex-col pr-2">
            <h3 className="font-semibold">{title}</h3>
            <p className="mt-1 text-sm">{body}</p>
          </div>
          <FontAwesomeIcon
            icon={faTimes}
            className="hover:opacity-70 cursor-pointer"
            onClick={() => {
              closeToast();
              setNotification(null);
            }}
          />
        </div>
      ),
      {
        messageId,
        autoClose: false,
        icon: <FontAwesomeIcon icon={faBell} size="1x" />,
      }
    );
  }, [open, notification]);

  return null;
};

export default FCM;
