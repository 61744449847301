import { faBell } from "@fortawesome/pro-regular-svg-icons";
import { useT } from "@kanpla/system";
import { Badge, Button, ChildView, Popper } from "@kanpla/ui";
import classnames from "classnames";
import { atom, useAtom, useAtomValue } from "jotai";
import { useState } from "react";
import { useContainer } from "unstated-next";
import { NotificationCenterContext, showNotificationBadgeAtom } from ".";
import { AppContext } from "../contextProvider";
import Content, { AllMessages } from "./Content";

export const notificationCenterOpenAtom = atom<boolean>(false);

const NotificationCenter = () => {
  const { mobile } = useContainer(AppContext);
  const { notifications, allMessages, popups } = useContainer(
    NotificationCenterContext
  );
  const showNotificationBadge = useAtomValue(showNotificationBadgeAtom);

  const t = useT();

  const [open, setOpen] = useAtom(notificationCenterOpenAtom);
  const [openPopper, setOpenPopper] = useState<boolean>(false);

  const NotificationButton = (
    <Badge
      count={showNotificationBadge ? 1 : 0}
      hidden={!showNotificationBadge}
      dot
      offset={[-15, 11]}
      useRedColor
    >
      <Button
        icon={faBell}
        size="small"
        shape="plain"
        className={classnames("!text-xl h-10 !text-text-secondary", {
          "animate-ring": showNotificationBadge,
        })}
        onClick={() => mobile && setOpen(true)}
      />
    </Badge>
  );

  if (mobile)
    return (
      <>
        {NotificationButton}
        <ChildView
          open={open}
          setOpen={setOpen}
          title={t("Notifications")}
          noPadding
        >
          <Content
            allMessages={allMessages as AllMessages}
            notifications={notifications}
            popups={popups}
            isViewing={open}
          />
        </ChildView>
      </>
    );

  return (
    <Popper
      open={openPopper}
      setOpen={setOpenPopper}
      actionElement={NotificationButton}
      withPadding={false}
      minWidth={410}
      maxWidth={410}
      className="pb-8"
      lockOnOpen
      zIndex={30}
      margin={2}
    >
      <div className="flex flex-col">
        <h2 className="text-sm uppercase font-semibold text-text-secondary pt-3 px-3">
          {t("Notifications")}
        </h2>
        <Content
          allMessages={allMessages as AllMessages}
          notifications={notifications}
          popups={popups}
        />
      </div>
    </Popper>
  );
};

export default NotificationCenter;
