import { priceFormatter, tx } from "@kanpla/system";
import { PriceExcludingVat, PriceIncludingVat } from "@kanpla/types";
import { atom, useAtomValue } from "jotai";
import React from "react";
import { TooltipHiddenPrices } from "../elements/TooltipHiddenPrices";
import { activeCurrencyAtom } from "./useCurrencySymbol";

export const hidePricesAtom = atom<boolean>(false);

export const usePriceFormatterFunction = () => {
  const hidePrices = useAtomValue(hidePricesAtom);
  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const currentLocale = tx.getCurrentLocale();

  return (price?: PriceIncludingVat | PriceExcludingVat) => {
    /**
     * Hidden price but add a wrapper and a component instead of it,
     * like a dash plus an icon that display a tooltip to give a feedback to the user
     */
    if (hidePrices)
      return (
        <div>
          - <TooltipHiddenPrices />
        </div>
      );

    if (typeof price === "string") return price;

    return priceFormatter(price, activeCurrency, currentLocale);
  };
};
