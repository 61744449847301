import { useRequest } from "@kanpla/system";
import { TrackNotificationRequest } from "apps/frontend/pages/api/internal/submit/trackNotification";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";

const NotificationTracking = () => {
  const router = useRouter();
  const trackingId = router?.query?.trackingId as string;
  const { schoolId, childId, isSuperadmin } = useContainer(AppContext);

  const { request: registerNotification } = useRequest<
    TrackNotificationRequest,
    TrackNotificationRequest
  >("submit/trackNotification");

  useEffect(() => {
    // Don't track superadmins
    if (isSuperadmin) return;
    // Return if no tracking ID in the router query
    if (!trackingId) return;

    // Don't track until the user is loaded and logged in
    if (!childId || !schoolId) return;

    (async () =>
      await registerNotification({
        trackingId,
        schoolId,
        childId,
      }))();
  }, [trackingId, isSuperadmin, childId, schoolId]);

  return null;
};

export default NotificationTracking;
