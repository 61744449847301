import { Lock, isModalOpen } from "@kanpla/ui";
import {
  ClickAwayListener,
  Fade,
  Popper as PopperMui,
  PopperPlacementType,
} from "@mui/material";
import classNames from "classnames";
import { useAtomValue } from "jotai";
import React, {
  CSSProperties,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import { CSSTransition } from "react-transition-group";
import { useDebouncedValue } from "rooks";
interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  /** The element that triggers the display of the Popper */
  actionElement: ReactNode;
  /** Any element that will be wrapper by the Popper */
  children: ReactElement;
  /** Additional class names on the wrapper of the Popper */
  className?: string;
  /** Adds padding to the `Popper` */
  withPadding?: boolean;
  /** Sets a min width on the `Popper` */
  minWidth?: number;
  /** Sets a max width on the `Popper` */
  maxWidth?: number;
  /** Sets a fixed with on the `Popper` */
  width?: number;
  /** The dropdown will stretch to the `actionElement` width */
  containerAutoWidth?: boolean;
  /** Set the zIndex of the `Popper` */
  zIndex?: 10 | 20 | 30 | 40 | 50 | 60;
  /** Locks the body scroll if the `Popper` is open */
  lockOnOpen?: boolean;
  /** Applies `w-fit` to the action element automatically */
  fitActionElement?: boolean;
  /** Custom shadown strength, defaults to `xl` */
  shadow?: "sm" | "md" | "lg" | "xl" | "2xl";
  /** Adds a space between the `actionElement` and the `dropdown` */
  margin?: 0 | 1 | 2 | 4;
  /** Apply custom background to the dropdown element */
  background?: string;
  /** Custom rounded corners size, defaults to `lg` */
  rounded?: "sm" | "md" | "lg" | "xl";
  /** Placement of the Popper, relative to the `actionElement` */
  placement?: PopperPlacementType;
  /** Makes the action element extends to the width of the parent container */
  flex?: boolean;
  /** Adds a backdrop behind the popper */
  withBackdrop?: boolean;
  /** If any modal is open, the Popper will not close (useful if you're opening a modal inside a Popper). **Don't use if you're opening a Popper inside a modal!** Defaults to `true`. */
  closableIfModalOpen?: boolean;
  /** The children stay within their parent DOM hierarchy) */
  disablePortal?: boolean;
}

/**
 * Wraps a `Popper` provided by `@mui/material`
 * Creates a "dropdown" triggered by any element
 * @param props
 * @returns A `Popper` element
 */
const BasePopper = (props: Props) => {
  const {
    open = false,
    setOpen,
    actionElement,
    children,
    className,
    withPadding = true,
    minWidth = 0,
    maxWidth = null,
    width = null,
    zIndex = null,
    lockOnOpen = false,
    fitActionElement = true,
    shadow = "xl",
    margin = 0,
    containerAutoWidth = false,
    background = "bg-background-primary",
    rounded = "lg",
    placement = "bottom-end",
    flex = false,
    withBackdrop = false,
    closableIfModalOpen = true,
    disablePortal = false,
  } = props;

  const modalOpen = useAtomValue(isModalOpen);
  const [debouncedModalOpen] = useDebouncedValue(modalOpen, 800);

  const mouseEvent =
    debouncedModalOpen && !closableIfModalOpen ? false : "onClick";

  const actionElementRef = useRef<HTMLDivElement>(null);
  const backdropRef = useRef(null);

  const handleOnClick = (event: React.MouseEvent<any>) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleOnClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) return;
  }, [open]);

  return (
    <>
      {withBackdrop && (
        <CSSTransition
          ref={backdropRef}
          in={open}
          classNames="animate-fade"
          timeout={150}
          unmountOnExit
        >
          <div
            className="w-screen h-screen fixed top-0 left-0 bg-text-primary opacity-50 z-max"
            onClick={() => setOpen(false)}
          />
        </CSSTransition>
      )}
      <ClickAwayListener onClickAway={handleOnClose} mouseEvent={mouseEvent}>
        <div
          className={classNames(
            "relative",
            { "flex-1": flex },
            { "z-max": withBackdrop }
          )}
        >
          <div
            className={classNames(
              { "w-fit": fitActionElement },
              { "flex-1 w-full": flex && !fitActionElement },
              "h-auto cursor-pointer"
            )}
            onClick={handleOnClick}
            ref={actionElementRef}
          >
            {actionElement}
          </div>
          {open && (
            <PopperMui
              anchorEl={actionElementRef.current?.firstElementChild}
              open={open}
              placement={placement}
              className={classNames({
                "z-max": !zIndex,
                [`z-${zIndex}`]: zIndex,
              })}
              transition
              style={{
                width:
                  (width || containerAutoWidth) &&
                  (containerAutoWidth
                    ? actionElementRef.current?.offsetWidth || 0
                    : width),
              }}
              disablePortal={disablePortal}
            >
              {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                  <div
                    className={classNames(
                      `mt-${margin} rounded-${rounded} shadow-${shadow} border border-divider-main ${background}`,
                      className,
                      { "p-4": withPadding }
                    )}
                    style={{
                      minWidth: minWidth,
                      maxWidth: maxWidth ?? "auto",
                    }}
                  >
                    {children}
                  </div>
                </Fade>
              )}
            </PopperMui>
          )}
          {lockOnOpen && open && <Lock overflow="auto" />}
        </div>
      </ClickAwayListener>
    </>
  );
};

interface BasePopperMenuProps {
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
  onClick?: () => void;
}

type PopperMenuProps = BasePopperMenuProps;

const BasePopperMenu = (props: PopperMenuProps): JSX.Element => {
  const { children, className = "", ...rest } = props;

  const classes = classNames(className);

  return (
    <div className={classes} {...rest}>
      <ul className="list-none py-1">{children}</ul>
    </div>
  );
};

interface ItemProps extends BasePopperMenuProps {
  isDisabled?: boolean;
  danger?: boolean;
}

const Item = (props: ItemProps): JSX.Element => {
  const {
    children,
    danger = false,
    isDisabled = false,
    className = "",
    ...rest
  } = props;

  const classes = classNames(
    "py-[0.4rem] px-3 truncate transition-all ease-in-out",
    isDisabled
      ? "cursor-not-allowed text-text-disabled pointer-events-none"
      : "text-text-primary cursor-pointer active:bg-secondary-dark",
    { "hover:bg-secondary-main": !isDisabled },
    { "hover:bg-danger-extra-light text-danger-dark": danger && !isDisabled },
    className
  );

  return (
    <li
      className={classes}
      onClick={() => {
        if (isDisabled) return;
        onClick();
      }}
      {...rest}
    >
      {children}
    </li>
  );
};

type CompoundedPopperMenuType = typeof BasePopperMenu & {
  Item: typeof Item;
};

(BasePopperMenu as CompoundedPopperMenuType).Item = Item;

export const Popper = BasePopper;
export const PopperMenu = BasePopperMenu as CompoundedPopperMenuType;
