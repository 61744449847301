import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons";
import { T, useT } from "@kanpla/system";
import { _FrontendModule } from "@kanpla/types";
import { Button, DrawerOrModal, PriceFormatter } from "@kanpla/ui";
import { useAtomValue, useSetAtom } from "jotai";
import { isEmpty } from "lodash";
import {
  render as reactRender,
  unmount as reactUnmount,
} from "rc-util/lib/React/render";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
  basketAtom,
  basketContainerTotalItemsAtom,
  basketContainerTotalPriceAtom,
  basketResetAtom,
  openBasketAtom,
} from "../basket/useBasket";

interface Props {
  disabled?: boolean;
}

export const useBasketPreventClick = ({ disabled = false }: Props) => {
  const t = useT();
  const basketReset = useSetAtom(basketResetAtom);
  const totalPriceItems = useAtomValue(basketContainerTotalPriceAtom);

  const [open, setOpen] = useState(true);
  useEffect(() => {
    if (!open) setOpen(true);
  }, [open]);

  const basket = useAtomValue(basketAtom);
  const setOpenBasket = useSetAtom(openBasketAtom);
  const totalItemsAmount = useAtomValue(basketContainerTotalItemsAtom);

  if (typeof document === "undefined") return null;

  const container = document.createDocumentFragment();

  const close = () => {
    setOpen(false);
    reactUnmount(container);
  };

  const confirm = async (
    currModule?: _FrontendModule
  ): Promise<string | boolean> => {
    const promise = new Promise<string | boolean>((resolve, reject) => {
      // Skip if basket is empty, or if explicitly disabled
      const shouldSkip = isEmpty(basket) || disabled;
      if (shouldSkip) {
        resolve(true);
        return;
      }

      // Configuration for labels
      const labels = {
        title: `${t("Complete the order")}?`,
        subtitle: <T _str="Do you wish to continue?" />,
        okButtonLabel: t("Continue ordering"),
        cancelButtonLabel: `${t("Cancel the order")}`,
        productLabel:
          totalItemsAmount === 1
            ? t("{totalItemsAmount} product", { totalItemsAmount })
            : t("{totalItemsAmount} products", {
                totalItemsAmount,
              }),
      };

      BasketCTA({
        open,
        setOpen: close,
        labels,
        onConfirm: () => {
          reject("Cancelled");
          setOpenBasket(true);
          close();
        },
        onCancel: () => {
          // Allow user to keep going
          resolve(true);
          basketReset();
          setTimeout(() => basketReset(), 200);
          close();
        },
        container,
        totalPriceItems,
      });
    });

    return await promise;
  };

  return confirm;
};

interface CTAProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void;
  onCancel: () => void;
  container: DocumentFragment;
  labels: {
    title: string;
    subtitle: JSX.Element;
    okButtonLabel: string;
    cancelButtonLabel: string;
    productLabel: string;
  };
  totalPriceItems: number;
}

export const BasketCTA = (props: CTAProps) => {
  const {
    onCancel,
    onConfirm,
    open,
    setOpen,
    container,
    labels,
    totalPriceItems,
  } = props;
  const { title, subtitle, okButtonLabel, cancelButtonLabel, productLabel } =
    labels;

  setTimeout(() => {
    reactRender(
      <DrawerOrModal
        open={open}
        setOpen={setOpen}
        title={title}
        subtitle={<div className="mt-2">{subtitle}</div>}
        actions={[
          <div className="flex flex-col w-full">
            <Button
              type="primary"
              shape="solid"
              className="w-full"
              icon={faArrowRight}
              onClick={onConfirm}
              size="large"
              dataCy="basket-prevent-click-confirm"
            >
              {okButtonLabel}
            </Button>
            <Button
              type="secondary"
              shape="soft"
              onClick={onCancel}
              dataCy="basket-prevent-click-cancel"
              size="large"
              className="mt-2"
            >
              {cancelButtonLabel}
            </Button>
          </div>,
        ]}
      >
        <div className="flex justify-between items-center w-full p-4 mt-12 rounded-md bg-secondary-main text-secondary-contrast">
          {productLabel}
          <b>
            <PriceFormatter price={totalPriceItems} />
          </b>
        </div>
      </DrawerOrModal>,
      container
    );
  });
};
