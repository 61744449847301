import { Week } from "@kanpla/types";
import { getWeekArray } from "./getWeekArray";

interface Props {
  /** Timestamp provider from fb */
  Timestamp: any;
  includeWeekend?: boolean;
}

export const getCurrentWeek = (props: Props) => {
  const { Timestamp, includeWeekend } = props;
  // GET TIME INFO
  const kanplaModulus = 345600; // 4 days because 1.1.1970 is a Thursday
  let local = Date.now() / 1000; // get time right now
  const localModulus = local % 604800; // seconds to Thursday, 00:00

  // NECESSARY EDITS
  if (localModulus < kanplaModulus) local = local - 604800; // if before Sunday midnight, subtract a week
  const correctStart = local - localModulus + kanplaModulus; // go to the beggining of the week in CET

  // CONSTRUCT A WEEK
  const week = getWeekArray(correctStart, Timestamp, includeWeekend);

  return week as Week;
};
