import { useT } from "@kanpla/system";
import { Button, DrawerOrModal, Image } from "@kanpla/ui";
import { Dispatch, SetStateAction } from "react";

export interface CardErrorProps {
  text: string | JSX.Element;
  extra?: string;
}

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  cardError: CardErrorProps;
  setCardError: Dispatch<SetStateAction<CardErrorProps>>;
}

const ErrorModal = ({ open, setOpen, cardError, setCardError }: Props) => {
  const t = useT();
  return (
    <DrawerOrModal
      open={open}
      setOpen={(state) => {
        setCardError({ text: t("Payment failed") });
        setOpen(state);
      }}
      actions={[
        <a
          rel="noreferrer"
          href="https://intercom-help.eu/kanpla/da/articles/22430-hvordan-optanker-jeg-min-konto"
          target="_blank"
          className="text-background-primary focus:text-background-primary"
        >
          <Button size="large" dataCy="btn-payment-error-read-more">
            {t("Read more")}
          </Button>
        </a>,
        <Button
          type="danger"
          size="large"
          className="w-full"
          onClick={() => {
            setCardError({ text: t("Payment failed") });
            setOpen(false);
          }}
          dataCy="btn-payment-error-accept"
        >
          Okay
        </Button>,
      ]}
    >
      <h2 className="title-main text-danger-main">{t("Payment failed")}</h2>
      <Image
        src="/images/card_error.svg"
        alt={t("Payment failed")}
        className="my-6 mx-auto w-3/4 md:w-full"
      />
      <p>{cardError.text}</p>
      {cardError.extra && (
        <p className="p-2 bg-background-secondary rounded body-secondary font-semibold mt-4 border border-divider-main">
          {cardError.extra}
        </p>
      )}
    </DrawerOrModal>
  );
};

export default ErrorModal;
