import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { useT } from "@kanpla/system";
import {
  AdyenCheckoutCardConfiguration,
  AllowedPaymentMethodsEnum,
} from "@kanpla/types";
import { Button, DrawerOrModal, Spinner } from "@kanpla/ui";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import AdyenPaymentComponent from "../adyen/AdyenPaymentComponent";
import { useSessionCardAdyen } from "./hooks/useSessionCardAdyen";

interface CardAddAdyenProps {
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const CardAddAdyen = ({ setLoading }: CardAddAdyenProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const t = useT();

  const { sessionData, isLoading } = useSessionCardAdyen({ open });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  return (
    <>
      <Button
        size="large"
        icon={faPlus}
        loading={isLoading}
        loadingText={t("Please wait...")}
        dataCy="btn-add-new-card"
        shape="solid"
        className="w-full"
        onClick={() => setOpen(true)}
      >
        {t("Add new card")}
      </Button>
      <DrawerOrModal open={open} setOpen={setOpen} zMax>
        {isLoading && <Spinner size="medium" />}
        {sessionData && (
          <AdyenPaymentComponent
            type={AllowedPaymentMethodsEnum.card}
            id="adyen-card-checkout"
            isAddingCard
            config={{
              onSuccess: () => setOpen(false),
              options: {
                ...(sessionData.config as AdyenCheckoutCardConfiguration),
              },
            }}
          />
        )}
      </DrawerOrModal>
    </>
  );
};

export default CardAddAdyen;
