import { faQuestionCircle } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import { Tooltip, TooltipProps } from "antd";
import React from "react";

interface Props {
  /** Custom color of the icon */
  color?: string;
  /** Control the placement of the tooltip */
  placement?: TooltipProps["placement"];
}

export const TooltipHiddenPrices = ({
  color = "",
  placement = "top",
}: Props) => {
  const t = useT();

  return (
    <Tooltip
      title={t("The price has been hidden by your employer")}
      trigger={["hover", "click"]}
      placement={placement}
      arrowPointAtCenter
    >
      <FontAwesomeIcon
        icon={faQuestionCircle}
        onClick={(event) => {
          event.stopPropagation();
        }}
        className={color}
      />
    </Tooltip>
  );
};
