import { IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useWindowSize } from "@kanpla/system";
import classNames from "classnames";
import React, {
  InputHTMLAttributes,
  isValidElement,
  MouseEventHandler,
  ReactNode,
  useRef,
} from "react";
import { isMobile } from "react-device-detect";
import { useMergeRefs } from "rooks";

export interface BaseInputProps {
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  dataCy?: string;
  name?: string;
  label?: string;
  className?: string;
  disabled?: boolean;
  error?: boolean;
}

export interface InputTextProps extends BaseInputProps {
  type?: "text" | "password" | "email";
  readOnly?: boolean;
  icon?: IconDefinition | ReactNode;
  iconRight?: boolean;
  iconOnClick?: MouseEventHandler<SVGSVGElement> | null;
}

export type InputProps = InputTextProps &
  Partial<
    Omit<InputHTMLAttributes<HTMLInputElement>, "type" | "value" | "onChange">
  >;

export const BaseInput = React.forwardRef<HTMLInputElement, InputProps>(
  (props, ref): JSX.Element => {
    const {
      className = "",
      disabled = false,
      readOnly = false,
      label = null,
      dataCy = "",
      type = "text",
      icon,
      iconRight,
      iconOnClick = null,
      style,
      error = false,
      required,
      placeholder,
      ...rest
    } = props;

    const baseInputRef = useRef<HTMLInputElement>(null);

    const { width: screenWidth } = useWindowSize();
    const mobile = isMobile || screenWidth < 768;

    const inputRefs = useMergeRefs(ref, baseInputRef);

    const classes = classNames(
      // bg-input-primary, bg-input-secondary, outline-danger-focus, outline-primary-focus, outline-secondary-focus, border-primary-focus, border-danger-focus, border-secondary-focus
      `bg-input-primary text-text-primary mt-3 md:mt-0 select-none rounded-md h-14 md:h-auto md:py-2 px-3 flex items-center outline-${
        error ? "danger" : "primary"
      }-focus border-${error ? "danger" : "primary"}-focus`,
      { "border-danger-main": error },
      {
        "border border-divider-main shadow-inner": true,
      },
      {
        "pl-3": icon && !iconRight,
        "pr-3": icon && iconRight,
      },
      { "opacity-50": disabled },
      className
    );

    if (mobile)
      return (
        <div
          className={classNames("w-full py-1", classes)}
          style={style}
          onClick={() => baseInputRef.current.focus()}
        >
          <div
            className={classNames("flex items-center w-full", {
              "justify-between": iconRight,
            })}
          >
            {icon &&
              (isValidElement(icon) ? (
                <div
                  className={classNames(
                    "text-text-secondary",
                    { "mr-3": !iconRight },
                    { "order-last ml-2": iconRight }
                  )}
                >
                  {icon}
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={icon as IconDefinition}
                  className={classNames(
                    "text-text-secondary",
                    { "mr-3": !iconRight },
                    { "order-last ml-2": iconRight }
                  )}
                  onClick={iconOnClick}
                />
              ))}
            <div className={classNames("flex flex-col w-full relative")}>
              <input
                placeholder={placeholder}
                data-cy={dataCy}
                ref={inputRefs}
                type={type}
                disabled={disabled}
                readOnly={readOnly}
                className={classNames(
                  `bg-input-primary`,
                  "peer w-full text-base",
                  label
                    ? "pt-4 placeholder-transparent focus:placeholder-text-disabled"
                    : "placeholder-text-disabled"
                )}
                {...rest}
              />
              {label && (
                <label
                  className={classNames(
                    rest.value?.length
                      ? "text-xs top-0"
                      : "text-base font-normal top-[8px] left-1",
                    "text-text-secondary absolute transition-all peer-focus:left-0 peer-focus:text-xs peer-focus:top-0 "
                  )}
                >
                  {label}
                  {required ? (
                    <span className="font-medium mr-0.5 text-danger-main">
                      *
                    </span>
                  ) : null}
                </label>
              )}
            </div>
          </div>
        </div>
      );

    return (
      <div
        className={classNames("w-full py-1 mt-1 flex flex-col")}
        style={style}
      >
        {label && (
          <label className="form-label mb-2" htmlFor={rest.name}>
            {required ? (
              <span className="font-medium mr-0.5 text-danger-main">*</span>
            ) : null}
            {label}
          </label>
        )}
        <div className={classes} onClick={() => baseInputRef.current.focus()}>
          {icon &&
            (isValidElement(icon) ? (
              <div
                className={classNames(
                  "text-text-secondary",
                  { "mr-3": !iconRight },
                  { "order-last ml-2": iconRight },
                  { "cursor-pointer": iconOnClick }
                )}
              >
                {icon}
              </div>
            ) : (
              <FontAwesomeIcon
                icon={icon as IconDefinition}
                className={classNames(
                  "text-text-secondary",
                  { "mr-3": !iconRight },
                  { "order-last ml-2": iconRight },
                  { "cursor-pointer": iconOnClick }
                )}
                onClick={iconOnClick}
              />
            ))}
          <input
            placeholder={placeholder}
            data-cy={dataCy}
            ref={inputRefs}
            type={type}
            disabled={disabled}
            readOnly={readOnly}
            className={`bg-input-primary w-full`}
            {...rest}
          />
        </div>
      </div>
    );
  }
);
