import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import React, { FC, MouseEventHandler } from "react";

interface Props {
  onClick: MouseEventHandler<HTMLDivElement>;
  className?: string;
  /** Default icon is `faTimes`. */
  icon?: IconProp;
}

export const CloseButton: FC<Props> = ({ onClick, icon, className = "" }) => {
  return (
    <div
      className={classnames(
        "rounded-full hover:opacity-60 transition-opacity ease-in-out text-text-primary flex",
        " justify-center items-center p-4 w-8 h-8 cursor-pointer absolute top-4 right-4 z-50",
        className
      )}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={icon ?? faTimes} />
    </div>
  );
};
