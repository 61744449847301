import { SelectTypes } from "@kanpla/types";
import classNames from "classnames";
import React, { KeyboardEventHandler, useCallback, useState } from "react";
import { InputProps } from "react-select";
import CreatableSelect from "react-select/creatable";
import { BaseInputProps } from "./BaseInput";
import ClearIndicator from "./Select/ClearIndicator";
import Input from "./Select/Input";

interface InputTagProps extends Omit<BaseInputProps, "value" | "onChange"> {
  value?: string[];
  onChange?: (newValue: string[]) => void;
  className?: string;
  placeholder?: string;
  design?: "lighter" | "bordered";
  color?: "primary";
}

interface Option {
  readonly label: string;
  readonly value: string;
}

export const InputTag = React.forwardRef<any, InputTagProps>((props, ref) => {
  const {
    error,
    disabled,
    value = [],
    onChange = () => null,
    className = "",
    placeholder = "Add a tag",
    design = "bordered",
    color = "primary",
  } = props;

  const [inputValue, setInputValue] = useState("");

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        onChange([...value, inputValue]);
        setInputValue("");

        event.preventDefault();
    }
  };

  const colorWithError = error ? "danger" : color;

  const classes = classNames(
    // bg-input-primary outline-danger-focus, outline-primary-focus, outline-secondary-focus, border-primary-focus, border-danger-focus, border-secondary-focus
    `bg-input-primary select-none !cursor-text !rounded-md py-1 md:py-2 px-3 h-14 md:h-auto items-center md:justify-between outline-${colorWithError}-focus border-${colorWithError}-focus border-${colorWithError}-hover transition-all ease-in-out mt-0`,
    { "border border-danger-main": error },
    {
      [`shadow-sm border border-transparent`]: design === "lighter",

      [`border border-divider-main shadow-inner`]: design === "bordered",
    },
    { "opacity-50 cursor-not-allowed": disabled },
    className
  );

  const customClassNames: SelectTypes.ClassNames = {
    control: () => classes,
    placeholder: () => "text-gray-500",
    multiValue: () => "cursor-default",
    multiValueRemove: () =>
      "cursor-pointer hover:opacity-50 transition-opacity ease-in-out ml-2",
  };

  // `useCallback` is necessary here as it will prevent the component from re-rendering (causing issues with value entering)
  const memoizedCustomInput = useCallback(
    (props: InputProps) => <Input {...props} placeholder={placeholder} />,
    [placeholder]
  );

  const mappedValues = value.map((option) => ({
    label: option,
    value: option,
  }));

  return (
    <CreatableSelect
      ref={ref}
      components={{
        DropdownIndicator: null,
        ClearIndicator,
        Input: memoizedCustomInput,
        Placeholder: () => null,
      }}
      // @ts-ignore
      onBlur={() => handleKeyDown({ key: "Enter", preventDefault: () => null })}
      classNamePrefix="kanpla_select"
      menuIsOpen={false}
      onKeyDown={handleKeyDown}
      value={mappedValues}
      onChange={(newValue) => {
        const newValueTyped = newValue as Option[];
        onChange(newValueTyped.map((v) => v.value));
      }}
      inputValue={inputValue}
      onInputChange={(newValue) => setInputValue(newValue)}
      isDisabled={disabled}
      classNames={customClassNames}
      isClearable
      isMulti
      unstyled
    />
  );
});
