import { Currency, PaymentGateway, Wallet } from "@kanpla/types";
import { isEmpty, round } from "lodash";

interface GetUserWalletArgs {
  wallets: Wallet[];
  activeCurrency: Currency;
  paymentGatewayId?: PaymentGateway["id"];
}

export const getUserWalletBalance = ({
  wallets,
  activeCurrency,
  paymentGatewayId,
}: GetUserWalletArgs) => {
  if (!wallets || isEmpty(wallets)) return null;

  const targetWallet = wallets.find((wallet) => {
    // Ignore mismatching currencies
    if (wallet.currency !== activeCurrency) return false;

    // Custom gateway
    if (paymentGatewayId) {
      // Ignore Kanpla gateway wallet
      if (!wallet.paymentGatewayId) return false;

      // Choose if the gateway ID matches the wallet
      if (wallet.paymentGatewayId === paymentGatewayId) return true;

      // Ignore mismatching IDs
      return false;
    }

    // Kanpla payment gateway
    if (!paymentGatewayId) {
      // Ignore any wallet that's on a custom gateway
      if (wallet.paymentGatewayId) return false;

      // If currency (from above) matches and there's no gateway ID on the wallet, we're golden
      return true;
    }

    // Ignore if we get this down
    return false;
  });

  if (!targetWallet) return null;

  return round(targetWallet.amount, 0);
};
