import {
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
} from "react-hook-form";

export const getNestedError = (
  errors: FieldErrors<FieldValues>,
  name: string
) => {
  const nameParts = name.split(".");

  let nestedError: Record<string, any> = errors;

  for (const part of nameParts) {
    nestedError = nestedError?.[part];
    if (
      nestedError &&
      typeof nestedError === "object" &&
      "message" in nestedError
    ) {
      break;
    }
  }
  return nestedError as FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
};
