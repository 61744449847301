import type {
  OrderLine,
  PriceExcludingVat,
  PriceIncludingVat,
} from "@kanpla/types";
import Big from "big.js";
import { calculateConfigTotal } from "../orders/calculateConfigTotal";

/**
 * calculateOrderVatBreakdown calculates all VAT properties for an order.
 * Use it in places where you need to display a full price breakdown
 */

export interface OrderInvoiceTotalResponse {
  withoutVat: PriceExcludingVat;
  vat: number;
  withVat: PriceIncludingVat;
}

type OrderLineForVat = Pick<
  OrderLine,
  "options" | "vatRate" | "amount" | "unitPrice" | "productId"
>;

/**
 * Prefer using `calculateOrderWithDiscountTotal` if you need to calculate the total price with discounts!
 * This will be deprecated in the future in favor of `calculateOrderWithDiscountTotal`
 */
export const calculateOrderVatBreakdown = (
  orderLines: OrderLineForVat[] = [],
  /** If `decimalPrice` is selected, we want to return the value in decimals, not as unit price */
  decimalPrice?: boolean,
  /** If 'excludeConfigPrice' is selected, we want to return the value without the config price */
  excludeConfigPrice?: boolean
): OrderInvoiceTotalResponse => {
  const mapped = orderLines.map((orderLine) => {
    const bigZero = new Big(0);

    if (!orderLine)
      return {
        withoutVat: bigZero,
        withVat: bigZero,
        vat: bigZero,
      };

    if (!orderLine.amount)
      return {
        withoutVat: bigZero,
        withVat: bigZero,
        vat: bigZero,
      };

    const {
      options = {},
      vatRate: lineVatRate,
      amount = 0,
      unitPrice = 0,
    } = orderLine;

    const totalConfigPrice = excludeConfigPrice
      ? 0
      : calculateConfigTotal(options) ?? 0;

    const bigUnitPrice = new Big(unitPrice);
    const singlePriceExclVat = bigUnitPrice.plus(totalConfigPrice ?? 0.2);
    const vatRate = new Big(lineVatRate ?? 0.25);

    const withoutVat = bigUnitPrice.times(amount);
    const withVat = singlePriceExclVat.times(vatRate.add(1)).times(amount);
    const vat = singlePriceExclVat.times(vatRate).times(amount);

    return {
      withoutVat,
      withVat,
      vat,
    };
  });

  const totalWithVat = mapped.reduce(
    (acc, curr) => acc.add(curr.withVat),
    new Big(0)
  );

  const totalWithoutVat = mapped.reduce(
    (acc, curr) => acc.add(curr.withoutVat),
    new Big(0)
  );

  const totalVat = mapped.reduce((acc, curr) => acc.add(curr.vat), new Big(0));

  if (decimalPrice)
    return {
      withoutVat: totalWithoutVat.div(100).round(2).toNumber(),
      withVat: totalWithVat.div(100).round(2).toNumber(),
      vat: totalVat.div(100).round(2).toNumber(),
    };

  return {
    withoutVat: totalWithoutVat.round(0).toNumber(),
    withVat: totalWithVat.round(0).toNumber(),
    vat: totalVat.round(0).toNumber(),
  };
};
