const THURSDAY_SECONDS = 345600; // 4 days because 1.1.1970 is a Thursday
const WEEK_SECONDS = 604800; // seconds to Thursday, 00:00

/** Returns the seconds of the start of the week containing given `seconds` of timestamp. */
export const getWeekSeconds = (seconds: number) => {
  const localModulus = seconds % WEEK_SECONDS;
  // go to the beginning of the week in CET
  let correctStart = seconds - localModulus + THURSDAY_SECONDS;

  // Subtract a week, if after thursday
  const shouldJumpWeekBack = localModulus < THURSDAY_SECONDS;
  if (shouldJumpWeekBack) correctStart = correctStart - WEEK_SECONDS;

  return correctStart;
};
