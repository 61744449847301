import { ReactNode } from "react";
import { SWRConfig } from "swr";

interface Props {
  children: ReactNode;
}

const CustomSWRConfig = ({ children }: Props) => {
  return (
    <SWRConfig value={{ provider: () => new Map() }}>{children}</SWRConfig>
  );
};

// const CustomSWRConfig = ({ children }: Props) => <>{children}</>;

export default CustomSWRConfig;
