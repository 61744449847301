import { useAtomValue, useSetAtom } from "jotai";
import { useEffect, useState } from "react";
import tinycolor from "tinycolor2";
import { useContainer } from "unstated-next";

import { OrderingContext } from "@kanpla/ordering";
import { PaymentOnceResponse } from "@kanpla/services";
import {
  T,
  calculateAmountOfOrderItems,
  calculatePaymentOnceAmount,
  callInternalApi,
  getCssVariable,
  useT,
} from "@kanpla/system";
import {
  AdyenCheckoutApplePayConfiguration,
  AllowedPaymentMethodsEnum,
} from "@kanpla/types";

import { Button } from "@kanpla/ui";
import { selectedPaymentMethodAtom } from "../../../mealplan2/basket/elements/selectedPaymentMethodAtom";
import {
  basketContainerAtom,
  basketContainerTotalPriceAtom,
  basketResetAtom,
  openBasketAtom,
} from "../../basket/useBasket";
import useBasketPayment from "../useBasketPayment";
import AdyenPaymentComponent, {
  adyenPaymentCheckoutConfigAtom,
} from "./AdyenPaymentComponent";

type AdyenPaymentMethodProps = {
  // refill amount in the smaller currency unit (e.g. Øre, Cent)
  selectedRefillAmount?: number;
  closePaymentDrawer?: () => void;
};

const AdyenPaymentMethods = (props: AdyenPaymentMethodProps) => {
  const {
    hasKanplaGo,
    balance,
    schoolId,
    paymentGatewayId,
    dateSeconds,
    module,
  } = useContainer(OrderingContext);
  const t = useT();

  const basketOpen =
    useAtomValue(openBasketAtom) || module?.flow === "registering";
  const basketContainerTotalPrice = useAtomValue(basketContainerTotalPriceAtom);
  const selectedPaymentMethod = useAtomValue(selectedPaymentMethodAtom);
  const basketContainer = useAtomValue(basketContainerAtom);
  const setAdyenCheckoutConfig = useSetAtom(adyenPaymentCheckoutConfigAtom);
  const basketReset = useSetAtom(basketResetAtom);

  const [sessionLoading, setSessionLoading] = useState<boolean>(false);
  // Handle Apple Pay button color based on primary color contrast
  const appBackground = getCssVariable("--palette-background-primary");
  const isBlackReadableOnBg = tinycolor.isReadable("#000000", appBackground);

  const buttonColor = isBlackReadableOnBg ? "black" : "white";

  const { loadChargeSession, callbackUrl } = useBasketPayment({
    setLoading: setSessionLoading,
    hasKanplaGo,
  });

  const initializeSession = async () => {
    try {
      setSessionLoading(true);
      const amountToCharge = calculatePaymentOnceAmount(
        balance,
        basketContainerTotalPrice,
        props.selectedRefillAmount
      );

      // Create order personal
      const validBasketContainer = Object.values(basketContainer).filter(
        (o) => {
          const hasDate = typeof o.dateSeconds === `number`;
          const isValid = calculateAmountOfOrderItems(o.orderLines) > 0 || o.id;
          return hasDate && isValid;
        }
      );

      const paymentOnceResponse: PaymentOnceResponse | null =
        await callInternalApi("payment/paymentOnce", {
          unitPrice: amountToCharge,
          recurring: false,
          paymentMethod: selectedPaymentMethod,
          isWindowSession: true,
          mode: "order",
          callbackUrl,
          orders: validBasketContainer,
          schoolId,
          paymentGatewayId,
          orderDateSeconds: dateSeconds,
        });

      if (!paymentOnceResponse)
        throw new Error(t("No payment session available. Try again."));

      const { sessionId, provider, sessionData, merchantId } =
        paymentOnceResponse;

      if (!merchantId)
        throw new Error("Merchant ID missing for Adyen payment provider");

      const payload =
        provider === "adyen"
          ? { provider, sessionId, sessionData: sessionData || "" }
          : { provider, sessionId };

      const session = await loadChargeSession(payload);

      // Initialize Adyen checkout
      setAdyenCheckoutConfig({
        options: {
          ...(session.config as AdyenCheckoutApplePayConfiguration),
        },
        onSuccess: basketReset,
        merchantId,
      });
    } catch (e) {
      console.error(e);
    } finally {
      setSessionLoading(false);
    }
  };

  useEffect(() => {
    if (
      !Object.values(AllowedPaymentMethodsEnum).includes(
        selectedPaymentMethod as AllowedPaymentMethodsEnum
      ) ||
      (!props.selectedRefillAmount && !basketContainerTotalPrice)
    )
      return;

    setAdyenCheckoutConfig(null);

    initializeSession();
  }, [
    basketContainerTotalPrice,
    selectedPaymentMethod,
    basketOpen,
    callbackUrl,
    props.selectedRefillAmount,
  ]);

  if (sessionLoading)
    return (
      <Button className="flex-1 !p-2 w-full" loading type={buttonColor}>
        <T _str="Loading" />
      </Button>
    );

  return Object.values(AllowedPaymentMethodsEnum).includes(
    selectedPaymentMethod as AllowedPaymentMethodsEnum
  ) ? (
    <AdyenPaymentComponent
      id="adyen-payment-checkout"
      type={selectedPaymentMethod as AllowedPaymentMethodsEnum}
      appleGoogleButtonColor={buttonColor}
      closePaymentDrawer={props.closePaymentDrawer}
    />
  ) : (
    <T _str="Unsupported payment method for the Adyen gateway" />
  );
};

export default AdyenPaymentMethods;
