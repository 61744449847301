import {
  db,
  fetchCollection,
  getPartnerUrl,
  isNative,
  useLocationHostname,
} from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";

export const useGetPaymentBaseUrl = () => {
  const [baseUrl, setBaseUrl] = useState<string | null>(null);

  const hostname = useLocationHostname({});

  const domain = useMemo(() => {
    const newUrl = hostname ? getPartnerUrl({ hostname }) : undefined;
    const isKanpla = !newUrl || newUrl === "app";
    const domain = isKanpla ? "kanpla" : newUrl;
    return domain;
  }, [hostname]);

  const getUrl = async () => {
    // Get potential supplier based on the app url
    try {
      const suppliers = await fetchCollection<Supplier>(
        db.collection("suppliers").where("url", "==", domain)
      );

      if (isEmpty(suppliers)) return domain;

      const targetSupplier = suppliers?.find((s) => s?.isMain) || suppliers[0];
      const targetUrl = targetSupplier?.appUrl || targetSupplier?.url || domain;

      return targetUrl;
    } catch (err) {
      console.error(err);
      return domain;
    }
  };

  useEffect(() => {
    if (typeof window === "undefined" || domain === undefined) return;

    (async () => {
      const partnerUrl = await getUrl();

      if (isMobile && isNative) {
        setBaseUrl(`${partnerUrl !== "kanpla" ? partnerUrl : "kanpla"}://`);
      } else {
        setBaseUrl(`${window.location.origin}/`);
      }
    })();
  }, [hostname, domain]);

  return baseUrl;
};
