import { useT } from "@kanpla/system";
import React, { PropsWithChildren, ReactElement, useState } from "react";
import Modal, { ActionType, ModalProps } from "../modal/Modal";

export interface ModalConfirmProps {
  /** Confirm callback */
  onConfirm: () => any | Promise<any>;
  /** Cancel callback */
  onCancel?: () => void;
  /** The content of the modal */
  content: string;
  /** The confirmation text, defaults to `Confirm` */
  confirmText?: string;
  /** The cancellation text, defaults to `Cancel` */
  cancelText?: string;
  /** Renders the confirm button in danger style */
  danger?: boolean;
}

const ModalConfirm: React.FC<
  PropsWithChildren<ModalConfirmProps> &
    Omit<ModalProps, "open" | "setOpen" | "actions" | "children">
> = (props) => {
  const {
    onConfirm,
    onCancel = null,
    content,
    confirmText,
    cancelText,
    danger = false,
    children,
    ...rest
  } = props;

  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const t = useT();

  const actions: ActionType[] = [
    {
      label: cancelText || t("Cancel"),
      onClick: async () => {
        onCancel?.();
        setOpen(false);
      },
      type: "secondary",
    },
    {
      label: confirmText || t("Confirm"),
      loading,
      type: danger ? "danger" : "primary",
      onClick: async () => {
        try {
          setLoading(true);
          await onConfirm();
        } catch (err) {
          console.error(err);
        } finally {
          setLoading(false);
          setOpen(false);
        }
      },
    },
  ];

  return (
    <>
      <Modal open={open} setOpen={setOpen} actions={actions} {...rest}>
        {content}
      </Modal>
      {/**
       * The `children` is a ReactNode which does not necessarily have to
       * be a ReactElement to have handlers, events, and so on.
       * The solution is to wrap the children into a div element to be able to add click handlers.
       **/}
      {React.cloneElement(<div>{children}</div>, {
        onClick: (e: React.MouseEvent) => {
          e?.preventDefault();
          e?.stopPropagation();

          /* Workaround to fix the disabled attribute. */
          const isDisabled = (children as ReactElement)?.props?.disabled;
          if (isDisabled) return;
          setOpen(true);
        },
      })}
    </>
  );
};

export { ModalConfirm };
