import { useBodyLockScroll } from "@kanpla/system";

interface LockProps {
  overflow?: "auto" | "initial";
}

export const Lock = ({ overflow }: LockProps) => {
  useBodyLockScroll(undefined, overflow);
  return null;
};
