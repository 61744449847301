import { getCssVariable, isNative } from "@kanpla/system";
import { Supplier } from "@kanpla/types";
import Head from "next/head";
import { useEffect } from "react";
import tinycolor from "tinycolor2";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

interface CompanySupplierProps {
  supplier?: Supplier;
}

const CompanySuppliers = ({
  supplier: supplierFromProps,
}: CompanySupplierProps) => {
  const { supplier: supplierFromContext, setStatusBarStyle } =
    useContainer(AppContext);

  const supplier = supplierFromContext || supplierFromProps;

  useEffect(() => {
    if (typeof window === "undefined" || !isNative) return;

    if (!supplier) {
      setStatusBarStyle("dark");
      return;
    }

    const appBackground = getCssVariable("--palette-background-primary");
    const isIOsTextReadableOnBg = tinycolor.isReadable(
      "#000000",
      appBackground
    );

    if (!isIOsTextReadableOnBg) {
      setStatusBarStyle("light");
      return;
    }

    setStatusBarStyle("dark");
  }, [supplier, setStatusBarStyle]);

  return (
    <Head>
      <title>
        {supplier?.name ? `${supplier.name} | powered by Kanpla` : "Kanpla"}
      </title>
      <link
        rel="shortcut icon"
        href={
          (supplier as Supplier)?.favicon ||
          supplierFromProps?.favicon ||
          "/icons/favicon.ico"
        }
      />
      <meta
        name="theme-color"
        content={(supplier as Supplier)?.custom?.palette?.background?.primary}
      />
    </Head>
  );
};

export default CompanySuppliers;
