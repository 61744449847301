import { Message } from "@kanpla/types";
import { update } from "lodash";
import { toast } from "react-toastify";
import { MessageConfig } from "./Message.config";

/**
 * This class covers the main methods of react-toastify (success, error, warn, info and dismiss + our custom default method).
 * {@link https://fkhadra.github.io/react-toastify/introduction}
 */
export abstract class message {
  private static _caller(
    params: Array<Message.MessageProps>,
    method: Message.MessageMethod
  ): Message.Id {
    const {
      content,
      promiseParam,
      options: paramsOptions,
    } = MessageConfig.getParams(params, method);

    const defaultOptions = MessageConfig.getDefaultOptions({ method });

    const options: Message.MessageProps = {
      ...defaultOptions,
      ...paramsOptions,
    };

    update(options, "className", (value) => `!rounded-lg ${value}`);

    const targetParams: Array<Message.MessageProps> =
      promiseParam === undefined
        ? [content, options]
        : [content, promiseParam, options];

    const id =
      method === ""
        ? toast.apply(null, [
            ...(targetParams as Message.ToastFunctionParameters),
          ])
        : (toast as unknown as Message.ToastFunction)[method].apply(null, [
            ...(targetParams as Message.ToastFunctionParameters),
          ]);
    return id;
  }

  static success(
    content: Message.ContentParam,
    options?: Message.OptionsParam
  ) {
    const result = this._caller([content, options], "success");
    return result;
  }

  static error(content: Message.ContentParam, options?: Message.OptionsParam) {
    const result = this._caller([content, options], "error");
    return result;
  }

  static warning(
    content: Message.ContentParam,
    options?: Message.OptionsParam
  ) {
    const result = this._caller([content, options], "warning");
    return result;
  }

  static info(content: Message.ContentParam, options?: Message.OptionsParam) {
    const result = this._caller([content, options], "info");
    return result;
  }

  static loading(
    content: Message.ContentParam,
    options?: Message.OptionsParam
  ) {
    const result = this._caller([content, options], "loading");
    return result;
  }

  static promise(
    content: Message.PromiseContentParam,
    promiseParam: Message.PromiseParam,
    options?: Message.OptionsParam
  ) {
    const result = this._caller([content, promiseParam, options], "promise");
    return result;
  }

  static default(
    content: Message.ContentParam,
    options?: Message.OptionsParam
  ) {
    const result = this._caller([content, options], "");
    return result;
  }

  static dismiss(id?: Message.Id) {
    const result = toast.dismiss(id);
    return result;
  }
}
