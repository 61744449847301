import {
  getActivePlugins,
  TIMEZONE_DEFAULT,
  useLoadFrontend,
  UseTimeNavigation,
  useWindowSize,
} from "@kanpla/system";
import {
  _FrontendModule,
  _GeneratedSchool,
  Card,
  Child,
  CombinedOfferItem,
  FlexOption,
  LoadOfferReturn,
  Order,
  Partner,
} from "@kanpla/types";
import { activeCurrencyAtom, hidePricesAtom } from "@kanpla/ui";
import { atom, useSetAtom } from "jotai";
import { Dispatch, SetStateAction, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { createContainer } from "unstated-next";

import { isEmpty } from "lodash";
import { useDateInfo } from "./lib/hooks/useDateInfo";
import { useDeadlineInfo } from "./lib/hooks/useDeadlineInfo";
import { useHoliday } from "./lib/hooks/useHoliday";
import { useOffer } from "./lib/hooks/useOffer";
import { useLoadOrders } from "./lib/load/useLoadOrders";
import { useBasket } from "./shared/basket/useBasket";

export interface OrderingContextProps
  extends ReturnType<typeof useLoadFrontend>,
    ReturnType<typeof UseTimeNavigation> {
  childId: string;
  schoolId: string;
  module: _FrontendModule;
  moduleId: string;
  groupName: string;
  offer: LoadOfferReturn;
  // IsBulk
  isBulk: boolean;
  // User Data
  userId: string;
  isOverridingUser: boolean;
  /** Gives us different / multiple options if we're using admin UI */
  fromAdmin?: boolean;
  /** Fill only on backend if `fromAdmin === true` */
  adminId?: string;
  // Money Data
  balance: number;
  setBalance: (newBalance: number) => void;
  setCard: Dispatch<SetStateAction<Card | null>>;
  setCards: Dispatch<SetStateAction<Card[]>>;
  loadCards: () => Promise<void>;
  setChild?: (child: Child) => null;
  previousModuleId?: string;
  isOrderInvoiced?: boolean;
  overviewEditingOrder?: Order;
  partner: Partial<Partner>;
  disableRefund?: boolean;
  selectedLanguage?: string;
}

/** True for admin (backend) ordering, modifies the UI slightly and allows ordering past deadline */
export const isOrderingFromBackendAtom = atom(false);

export const userIdAtom = atom<string | null>(null);
export const childIdAtom = atom<string | null>(null);
export const schoolIdAtom = atom<string | null>(null);
export const groupNameAtom = atom<string | null>(null);
export const moduleIdAtom = atom<string | null>(null);
export const mealOptionsAtom = atom<FlexOption[]>([]);
export const showDiscountPriceAtom = atom<boolean>(false);

export const offerAtom = atom<LoadOfferReturn | null>(null);
export const offerItemsAtom = atom<CombinedOfferItem[]>([]);
export const moduleAtom = atom<_FrontendModule | null>(null);
export const schoolAtom = atom<_GeneratedSchool["school"] | null>(null);
export const ordersAtom = atom<Order[]>([]);

// TODO: Technical debt: Research how to best update data of atoms without using useEffect: FF-2919
const ContextState = (
  props: OrderingContextProps = {} as OrderingContextProps
) => {
  const {
    modules,
    module,
    school,
    week,
    schoolId,
    childId,
    dateSeconds,
    userId,
    moduleId,
    balance,
    isOrderInvoiced,
    overviewEditingOrder,
    partner,
    paymentGatewayId,
    groupName,
    fromAdmin,
    setChild = () => null,
    paymentGatewayProvider,
  } = props;

  const { width: screenWidth } = useWindowSize();
  const mobile = isMobile && (screenWidth || 0) <= 768;

  // Currency
  const setActiveCurrency = useSetAtom(activeCurrencyAtom);
  useEffect(() => {
    if (school?.currency) {
      setActiveCurrency(school.currency);
    }
  }, [school?.currency]);

  // Hide prices
  const updateHidePrices = useSetAtom(hidePricesAtom);
  useEffect(() => {
    if (fromAdmin) {
      updateHidePrices(false);
    }
    updateHidePrices(module?.config?.hidePrices || false);
  }, [fromAdmin, module?.config?.hidePrices]);

  const updateModuleId = useSetAtom(moduleIdAtom);
  useEffect(() => {
    updateModuleId(moduleId);
  }, [moduleId]);

  const updateSchoolId = useSetAtom(schoolIdAtom);
  useEffect(() => {
    if (schoolId) {
      updateSchoolId(schoolId);
    }
  }, [schoolId]);

  const updateGroupName = useSetAtom(groupNameAtom);
  useEffect(() => {
    updateGroupName(groupName);
  }, [groupName]);

  const updateChildId = useSetAtom(childIdAtom);
  useEffect(() => {
    updateChildId(childId);
  }, [childId]);

  const updateUserId = useSetAtom(userIdAtom);
  useEffect(() => {
    updateUserId(userId);
  }, [userId]);

  const updateSchool = useSetAtom(schoolAtom);
  useEffect(() => {
    updateSchool(school);
  }, [school]);

  const updateModule = useSetAtom(moduleAtom);
  useEffect(() => {
    updateModule(module);
  }, [module]);

  // Offer
  const { offer, noMealplan, mealOptions, items } = useOffer(props);

  const updateOffer = useSetAtom(offerAtom);
  useEffect(() => {
    updateOffer(offer);
  }, [offer]);

  const updateOfferItems = useSetAtom(offerItemsAtom);
  useEffect(() => {
    updateOfferItems(items);
  }, [items]);

  // Deadline
  useDeadlineInfo({
    module,
    offer,
    timezone: partner?.timezone || TIMEZONE_DEFAULT,
  });

  useDateInfo({ dateSeconds });

  // Order
  const allDateSeconds =
    module?.type === "flex" ? week.map((t) => t.seconds) : [dateSeconds];
  const {
    orders,
    orderDocument,
    childOrders,
    orderInfo,
    orderLines,
    hasOrdered,
    numberOfItems,
    reloadOrders,
    setLoadedOrders,
  } = useLoadOrders({
    childId,
    allDateSeconds,
    moduleId,
    userId,
  });

  const updateOrders = useSetAtom(ordersAtom);
  useEffect(() => {
    updateOrders(orders);
  }, [orders]);

  // Plugins
  const { activePlugins, requiresCredit, hasPayPerOrder, hasKanplaGo } =
    getActivePlugins({
      module,
      schoolId,
      groupName: props?.child?.groupName,
    });

  // Holidays
  const { activeHoliday, holidayDates } = useHoliday({
    ...props,
    offer,
    activePlugins,
  });

  const defaultReference = props?.child?.defaultReference;

  // Basket
  useBasket({
    userId,
    module,
    fromAdmin: props?.fromAdmin,
    balance,
    orders,
  });

  const setMealOptions = useSetAtom(mealOptionsAtom);
  useEffect(() => {
    setMealOptions(mealOptions);
  }, [mealOptions.length]);

  const setShowDiscountPrice = useSetAtom(showDiscountPriceAtom);
  useEffect(() => {
    if (!module || isEmpty(offer?.discounts)) return;

    const hasDiscountType = offer.discounts.some((d) =>
      ["discount-hybridBilling", "discount"].includes(d.type)
    );

    setShowDiscountPrice(hasDiscountType);
  }, [offer.discounts?.length, module]);

  return {
    ...props,
    childId,
    userId,
    moduleId,
    modules,
    schoolId,
    mobile,
    activePlugins,
    defaultReference,
    offer,

    // Order values
    /** @deprecated, use offerItemsAtom instead */
    items,
    orderInfo,
    orderDocument,
    orders,
    reloadOrders,
    setLoadedOrders,
    childOrders,
    orderLines,
    hasOrdered,
    noMealplan,
    isOrderInvoiced,
    overviewEditingOrder,

    // Module values
    module,
    requiresCredit,
    hasKanplaGo,
    hasPayPerOrder,

    activeHoliday,
    numberOfItems,
    mealOptions,
    holidayDates,

    paymentGatewayProvider,
    paymentGatewayId,
    setChild,
  };
};

export const OrderingContext = createContainer(ContextState);
