import { getTodaySeconds } from "./getTodaySeconds";

interface Props {
  /** Timestamp provider from fb */
  Timestamp: any;
}

export const getTodayTimestamp = (props: Props) => {
  const { Timestamp } = props;

  const dayStart = getTodaySeconds();

  const firebaseFormat = Timestamp.fromMillis(dayStart * 1000);

  return firebaseFormat;
};
