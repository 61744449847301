import React from "react";
import { InputProps } from "react-select";

const Input = ({ placeholder, innerRef, ...props }: InputProps) => (
  <input
    {...props}
    ref={innerRef}
    placeholder={placeholder}
    className="min-w-[100px]"
  />
);

export default Input;
