import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faSpinnerThird,
  IconDefinition,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useT } from "@kanpla/system";
import classNames from "classnames";
import { omit } from "lodash";
import React, { ButtonHTMLAttributes } from "react";

export interface BaseButtonProps {
  shape?: "solid" | "soft" | "plain";
  size?: "small" | "medium" | "large";
  type?:
    | "primary"
    | "secondary"
    | "danger"
    | "warning"
    | "info"
    | "success"
    | "white"
    | "black";
  icon?: IconDefinition;
  iconRight?: boolean;
  loading?: boolean;
  loadingText?: string;
  dataCy?: string;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  textClassName?: string;
}

export type NativeButtonProps = {
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
} & Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, "type" | "onClick">;

export type AnchorButtonProps = {
  href: string;
  target?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
} & Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement | HTMLButtonElement>,
  "type" | "onClick"
>;

export type ButtonProps = BaseButtonProps &
  Partial<NativeButtonProps | AnchorButtonProps>;

export const Button = React.forwardRef<unknown, ButtonProps>((props, ref) => {
  const {
    shape = "solid",
    size = "medium",
    type = "secondary",
    icon = null,
    iconRight = false,
    loading = false,
    loadingText: providedLoadingText,
    disabled = false,
    dataCy = "",
    htmlType,
    onClick,
    className,
    children,
    textClassName = "",
    ...rest
  } = props;
  const t = useT();
  const defaultLoadingText = t("Loading");
  const loadingText = providedLoadingText || defaultLoadingText;

  const linkButtonRestProps = omit(
    rest as AnchorButtonProps & { navigate: any },
    ["navigate"]
  );

  // Need these comments to generate the proper tailwind colors :(
  // bg-primary-main text-primary-contrast bg-primary-backdrop  hover:bg-primary-dark text-primary-main hover:text-primary-dark text-primary-dark
  // bg-secondary-main text-secondary-contrast bg-secondary-backdrop  hover:bg-secondary-dark text-secondary-dark hover:text-secondary-dark text-secondary-dark
  // bg-danger-main text-danger-contrast bg-danger-backdrop hover:bg-danger-dark text-danger-main hover:text-danger-dark text-danger-dark
  // bg-warning-main text-warning-contrast bg-warning-backdrop hover:bg-warning-dark text-warning-main hover:text-warning-dark text-warning-dark
  // bg-info-main text-info-contrast bg-info-backdrop hover:bg-info-dark text-info-main hover:text-info-dark text-info-dark
  // bg-success-main text-success-contrast bg-success-backdrop hover:bg-success-dark text-success-main hover:text-success-dark text-success-dark
  // bg-white-main text-white-contrast bg-white-backdrop hover:bg-white-dark text-white-main hover:text-white-dark text-white-dark
  // bg-black-main text-black-contrast bg-black-backdrop hover:bg-black text-black hover:text-black text-black

  const genericButtonStyles = {
    [`bg-${type}-main text-${type}-contrast`]:
      shape === "solid" && type !== "secondary",
    [`hover:bg-${type}-dark`]:
      shape === "solid" && !disabled && type !== "secondary",
    [`bg-${type}-backdrop text-${type}-dark`]:
      shape === "soft" && type !== "secondary",
    [`text-${type}-main hover:text-${type}-dark`]:
      shape === "plain" && type !== "secondary",
  };

  const secondaryButtonStyles = {
    "!text-text-primary": shape === "plain" && type === "secondary",
    "!text-text-primary bg-secondary-backdrop bg-opacity-60 hover:bg-opacity-40":
      shape === "soft" && type === "secondary",
    "!text-text-primary bg-background-primary hover:bg-background-secondary border-divider-main border":
      shape === "solid" && type === "secondary",
  };

  const whiteButtonStyles = {
    "!text-white": shape === "plain" && type === "white",
    "!text-white bg-white bg-opacity-20 hover:bg-opacity-30":
      shape === "soft" && type === "white",
    "!text-gray-900 bg-white hover:bg-opacity-90":
      shape === "solid" && type === "white",
  };

  const blackButtonStyles = {
    "!text-black": shape === "plain" && type === "black",
    "!text-black bg-black bg-opacity-20 hover:bg-opacity-30":
      shape === "soft" && type === "black",
    "!text-white bg-black hover:bg-opacity-90":
      shape === "solid" && type === "black",
  };

  const classes = classNames(
    "transition-all ease-in-out rounded-md hover:opacity-80",
    {
      ...genericButtonStyles,
      ...secondaryButtonStyles,
      ...whiteButtonStyles,
      ...blackButtonStyles,
      "cursor-not-allowed opacity-40 pointer-events-none": disabled,
      "cursor-wait pointer-events-none": loading,
      "px-3 py-1 text-sm": size === "small",
      "px-5 py-3": size === "medium",
      "px-5 py-4": size === "large",
    },
    className
  );

  if (linkButtonRestProps.href !== undefined) {
    return (
      <a
        {...linkButtonRestProps}
        data-cy={dataCy}
        aria-disabled={disabled}
        className={classNames(
          "button-link",
          "focus:no-underline focus-visible:no-underline", // overwrite libs/ui/styles/buttons.scss a styles
          classes
        )}
        onClick={onClick as React.MouseEventHandler<HTMLAnchorElement>}
        ref={ref as React.Ref<HTMLAnchorElement>}
      >
        <span
          className={classNames("text-center ", {
            "animate-blink": loading,
          })}
        >
          {(loading || icon) && (
            <FontAwesomeIcon
              icon={(loading ? faSpinnerThird : icon) as IconProp}
              className={classNames(
                { "animate-spin": loading },
                { "mr-2": !iconRight },
                { "order-last ml-2": iconRight }
              )}
              size={size === "large" ? "lg" : "1x"}
            />
          )}
          {loading ? loadingText : children}
        </span>
      </a>
    );
  }

  const hasContent = Boolean(children);

  return (
    <button
      {...(rest as NativeButtonProps)}
      data-cy={dataCy}
      type={htmlType || "button"}
      onClick={onClick as React.MouseEventHandler<HTMLButtonElement>}
      className={classes}
      ref={ref as React.Ref<HTMLButtonElement>}
    >
      <span
        className={classNames(
          {
            "flex flex-nowrap whitespace-nowrap justify-center items-center text-center w-full":
              Boolean(children),
          },
          {
            "animate-blink": loading,
          },
          textClassName
        )}
      >
        {(loading || icon) && (
          <FontAwesomeIcon
            icon={(loading ? faSpinnerThird : icon) as IconProp}
            className={classNames(
              { "animate-spin": loading },
              { "mr-2": !iconRight && hasContent },
              { "order-last ml-2": iconRight && hasContent }
            )}
            size={size === "large" ? "lg" : "1x"}
          />
        )}
        {loading ? loadingText : children}
      </span>
    </button>
  );
});
