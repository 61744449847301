import { SessionResponse } from "@kanpla/services";
import { getErrorMessage, useLocalStorage } from "@kanpla/system";
import { AdyenCheckoutConfigurationBase } from "@kanpla/types";

interface Props {
  setLoading: (nextState: boolean) => void;
  onError: (err: { error: string }) => void;
}

export type LoadChargeSessionType =
  | {
      provider: "adyen";
      config: AdyenCheckoutConfigurationBase; // CoreOptions;
    }
  | {
      provider: "reepay";
      config?: never;
    };

const useChargeSession = (props: Props) => {
  const { setLoading, onError } = props;

  const [, setReepaySessionId] = useLocalStorage<string | null>(
    "reepay-session-id",
    null
  );

  const loadChargeSession = async ({
    sessionId,
    provider,
    sessionData,
  }: SessionResponse): Promise<LoadChargeSessionType> => {
    setLoading(true);
    setReepaySessionId(sessionId);

    try {
      switch (provider) {
        case "adyen": {
          if (process.env["NEXT_PUBLIC_ADYEN_CLIENT_KEY"] === undefined) {
            throw new Error("Missing NEXT_PUBLIC_ADYEN_CLIENT_KEY");
          }

          return {
            provider,
            config: {
              environment:
                process.env.NODE_ENV === "production" ? "live" : "test", // Change to 'live' for the live environment.
              clientKey: process.env["NEXT_PUBLIC_ADYEN_CLIENT_KEY"],
              session: {
                id: sessionId,
                sessionData: sessionData,
              },
            },
          };
        }
        case "reepay":
        default:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.rp = new Reepay.WindowCheckout(sessionId);
          return { provider };
      }
    } catch (err) {
      const errorMessage = getErrorMessage(err);
      console.error(errorMessage);
      setLoading(false);
      onError({ error: errorMessage });
      throw new Error(errorMessage);
    }
  };

  return { loadChargeSession };
};

export default useChargeSession;
