import { Language } from "@kanpla/types";
import { useAvailableLanguages } from "./useAvailableLanguages";

/**
 * @returns Language of the navigator (browser) if available and is amongst the available languages
 */
export const useNavigatorLanguage = (allowNotSupportedLanguages = false) => {
  const availableLanguages = useAvailableLanguages();

  const loadNavigatorLanguage = () => {
    if (typeof navigator === `undefined`) return;

    const navigatorLanguageCode = navigator?.language?.substring(
      0,
      2
    ) as Language;

    if (allowNotSupportedLanguages) {
      return navigatorLanguageCode;
    }

    // Skip if browser language not available in Kanpla
    const isAvailable = availableLanguages.some(
      (l) => l.code === navigatorLanguageCode
    );
    if (!isAvailable) {
      return null;
    }

    return navigatorLanguageCode;
  };

  const navLanguage = loadNavigatorLanguage();

  return navLanguage;
};
