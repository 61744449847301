/* eslint-disable @typescript-eslint/ban-ts-comment */
import { tx } from "@kanpla/system";
import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";
import { useContainer } from "unstated-next";
import { AppContext } from "./contextProvider";

const useIntercomHook = () => {
  const { school, child } = useContainer(AppContext);
  const { update } = useIntercom();
  const userLanguage = tx.getCurrentLocale();

  // Send user information to intercom
  useEffect(() => {
    update({
      customAttributes: {
        childId: child?.id || null,
        childName: child?.name || null,
        schoolName: school?.name || null,
        schoolContactPhone: school?.contact?.tel || null,
        schoolContactEmail: school?.contact?.email || null,
        schoolContactName: school?.contact?.name || null,
        language_override: userLanguage,
      },
    });
  }, [child, school, userLanguage]);

  return null;
};

export default useIntercomHook;
