import { LoadOrdersProps, LoadOrdersReturn } from "@kanpla/services";
import { calculateAmountOfOrderItems, useFetch } from "@kanpla/system";
import {
  Child,
  Module,
  OrderInfo,
  OrderLines,
  OrderPersonal,
  User,
} from "@kanpla/types";
import { orderBy } from "lodash";

type Props = {
  childId: Child["id"];
  allDateSeconds: number[];
  moduleId: Module["id"];
  userId: User["id"];
};

export const useLoadOrders = ({
  childId,
  allDateSeconds,
  moduleId,
  userId,
}: Props) => {
  const {
    data,
    reload: reloadOrders,
    setData: setLoadedOrders,
  } = useFetch<LoadOrdersProps, LoadOrdersReturn>(
    userId ? "load/orders" : null,
    {
      moduleId,
      childId,
      allDateSeconds,
    },
    {
      isPaused: () => !moduleId || !childId,
      refreshInterval: 1000 * 50,
    }
  );

  const childOrders = data?.orders || [];

  // Don't filter them, flex UI will stop working properly!
  const sorted = orderBy(childOrders, "createdAtSeconds");

  // Extra props
  const orderDocument =
    childOrders.find((o) => calculateAmountOfOrderItems(o.orderLines) > 0) ||
    ({} as OrderPersonal);

  const orderLines: OrderLines = orderDocument.orderLines || [];
  const orderInfo = orderDocument.info || ({} as OrderInfo);

  const numberOfItems = calculateAmountOfOrderItems(orderLines);
  const hasOrdered = numberOfItems > 0;

  return {
    hasOrdered,
    orderInfo,
    orders: sorted,
    numberOfItems,
    orderLines,
    orderDocument,
    childOrders,
    reloadOrders,
    setLoadedOrders,
  };
};
