import { getNestedError } from "@kanpla/system";
import classNames from "classnames";
import { omit } from "lodash";
import React, { FormHTMLAttributes, ReactElement } from "react";
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  FormProvider,
  RegisterOptions,
  UseFormGetValues,
  UseFormReturn,
  UseFormWatch,
  useController,
  useForm,
  useFormContext,
} from "react-hook-form";
import { uuid } from "short-uuid";

interface FormProps<T extends FieldValues> {
  /** The fields that will be wrapped by the Form, usually `inputs` */
  children: React.ReactNode;
  /** The function to handle the form submission */
  onSubmit?: (data: T) => void;
  /** Callback that gets called on form reset */
  onReset?: () => void;
  /** The default values for the form fields */
  defaultValues?: DefaultValues<T>;
  /** Optionally pass `methods` from `useForm` if you want to have full control over the form */
  methods?: UseFormReturn<T, any>;
  layout?: "vertical" | "horizontal";
  /** The `id` of the form */
  id?: string;
  className?: string;
}

type BaseFormProps<T extends FieldValues> = FormProps<T> &
  Partial<
    Omit<
      FormHTMLAttributes<HTMLFormElement>,
      "onSubmit" | "onReset" | "onChange" | "value"
    >
  >;

const BaseForm = <T extends FieldValues>(
  props: BaseFormProps<T>
): JSX.Element => {
  const {
    children,
    onSubmit,
    defaultValues,
    onReset = () => null,
    methods: methodsFromProps = null,
    layout = "vertical",
    id = uuid(),
    className = "",
    ...rest
  } = props;

  // Call useForm to create a form instance and pass in defaultValues if provided
  const internalMethods = useForm<T>({ defaultValues });
  const methods = methodsFromProps ? methodsFromProps : internalMethods;

  // Define a callback function to handle form submission
  const onSubmitHandler = (data: T) => {
    onSubmit?.(data);
  };

  // Define a callback function to handle form reset
  const handleOnReset = () => {
    methods.reset();
    onReset();
  };

  return (
    <FormProvider {...methods}>
      <form
        id={id}
        onSubmit={methods.handleSubmit(onSubmitHandler)}
        onReset={handleOnReset}
        className={classNames(
          "flex gap-2 w-full",
          layout === "vertical" ? "flex-col" : "flex-row",
          className
        )}
        {...rest}
      >
        {children}
      </form>
    </FormProvider>
  );
};

type AccessibleFieldValues = {
  /** Get the value of a specific field wrapped by `Form` */
  getValues: UseFormGetValues<FieldValues>;
  /** Watch the value of a specific field wrapped by `Form` */
  watch: UseFormWatch<FieldValues>;
};

interface ItemProps {
  /** The `name` of the input field */
  name: string;
  /** The child component to wrap */
  children:
    | ReactElement
    | (({ getValues, watch }: AccessibleFieldValues) => ReactElement);
  /** The `id` of the input field */
  id?: string;
  /** The validation rules applied to the input field */
  rules?: RegisterOptions<FieldValues, string>;
  /** If the wrapped field comes from an external library or it does not expose the `ref` prop include it  */
  controlled?: boolean;
  controlledProps?: {
    /** Custom value name of the input field */
    valueName?: string;
    /** Custom onChange name of the input field */
    onChangeName?: string;
    /** Transform the data received from the onChange into any format you want */
    transformData?: (...args: any) => any;
    /** Transforma the input data */
    transformInput?: (...args: any) => any;
  };
  /** Adds a label to the field item, in case the input does not provide it as a prop */
  label?: string | ReactElement;
  /** Adds a description to the item in smaller text */
  description?: string | ReactElement;
  /** Adds a star next to the label, indicating that the field is required */
  required?: boolean;
  className?: string;
  /** Optionally pass extra content to add at the bottom of the field item */
  extra?: React.ReactNode;
  hidden?: boolean;
}

const Item = (props: ItemProps): JSX.Element => {
  const {
    children,
    name,
    rules = {},
    controlled = null,
    controlledProps = {
      valueName: "value",
      onChangeName: "onChange",
    },
    id = null,
    extra = null,
    ...rest
  } = props;

  // Get the form methods from the context using the useFormContext hook
  const {
    register,
    formState: { errors, defaultValues },
    control,
    getValues,
    watch,
    setValue,
  } = useFormContext();

  // Take over the custom input field and let's hanlde it manually
  const { field } = useController({
    name,
    control,
    rules,
    defaultValue: defaultValues?.[name],
  });

  const _children =
    typeof children === "function" ? children({ getValues, watch }) : children;

  const itemError = getNestedError(errors, name);

  // If we're passing a complex custom element, wrap it in the Controller component
  if (controlled) {
    const {
      valueName = "value",
      onChangeName = "onChange",
      transformData,
      transformInput,
    } = controlledProps;

    // Optionally we can transform the onChange data if what comes out is not properly formatted
    const onChangeHandler = (event: React.ChangeEvent | any) => {
      const value = transformData
        ? transformData(event)
        : event.target?.[valueName]
        ? event.target[valueName]
        : event;

      field.onChange(value);
    };

    const hasRef = _children.props?.ref;

    return (
      <ItemWrapper name={name} errors={errors} {...rest}>
        <>
          <div ref={hasRef ? null : field.ref}>
            {React.cloneElement(_children as ReactElement, {
              ...omit(field, hasRef ? "" : "ref"),
              ...{
                [onChangeName]: onChangeHandler,
                [valueName]: transformInput
                  ? transformInput(field.value)
                  : field.value,
              },
              id,
              error: _children.props?.["error"]
                ? Boolean(itemError?.message)
                : false,
            })}
          </div>
          {extra}
        </>
      </ItemWrapper>
    );
  }

  const registerItem = register(name, rules);

  // Clone the child component and pass in the register method and any validation rules
  // Also pass down the error state
  return (
    <ItemWrapper name={name} errors={errors} {...rest}>
      <>
        {React.cloneElement(_children as ReactElement, {
          id,
          error: Boolean(itemError?.message),
          ...registerItem,
          onChange: (value: any): any => {
            return value?.target && value?.nativeEvent
              ? registerItem.onChange(value)
              : setValue(name, value);
          },
          value: getValues(name) || undefined,
        })}
        {extra}
      </>
    </ItemWrapper>
  );
};

interface ItemWrapperProps
  extends Pick<
    ItemProps,
    "label" | "name" | "className" | "required" | "children" | "description"
  > {
  errors?: FieldErrors<FieldValues>;
  hidden?: boolean;
}

const ItemWrapper = ({
  label,
  name,
  className,
  children,
  errors,
  required,
  description,
  hidden = false,
}: ItemWrapperProps): JSX.Element => {
  const displayError = errors && getNestedError(errors, name);

  return (
    <div
      hidden={hidden}
      className={classNames({ "flex flex-col my-2": label }, className)}
    >
      {(label || description) && (
        <div className="mt-2 mb-1">
          {label && (
            <p className="form-label">
              {required ? (
                <span className="font-medium mr-0.5 text-danger-main">*</span>
              ) : null}
              {label}
            </p>
          )}
          {description && (
            <p className="text-xs text-text-disabled">{description}</p>
          )}
        </div>
      )}
      {children}
      {displayError && (
        <p className="text-danger-main mt-1 mb-2 text-sm text-left">
          {getNestedError(errors, name)?.message}
        </p>
      )}
    </div>
  );
};

type CompoundedFormType = typeof BaseForm & {
  Item: typeof Item;
};

(BaseForm as CompoundedFormType).Item = Item;

export const Form = BaseForm as CompoundedFormType;
