import { t, tx } from "@kanpla/system";
import { SurveyForm, SurveyResponse } from "@kanpla/types";
import { message } from "@kanpla/ui";
import moment from "moment";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { Model } from "survey-core";
import "survey-core/modern.min.css";
import "survey-core/survey.i18n";
import "survey-creator-core/survey-creator-core.min.css";
import { Survey } from "survey-react-ui";
import { useContainer } from "unstated-next";
import { AppContext } from "../contextProvider";
import { useFeedback } from "./useFeedback";

interface Props {
  surveyForm: SurveyForm;
  closeModal: () => void;
}

const SurveyModel = ({ surveyForm, closeModal }: Props) => {
  const { userId, schoolId } = useContainer(AppContext);

  const { submitResponse } = useFeedback();

  const model = useMemo(() => {
    const localModel = new Model(surveyForm.surveyData);
    localModel.locale = tx.getCurrentLocale();
    localModel.completeText = t("Submit your answer");

    localModel.onComplete.add((sender) => {
      const loadingMsg = message.loading(t("Submitting your answer"));
      const surveyResponse = {
        surveyFormId: surveyForm.id,
        userId: userId,
        dateSecond: moment().unix(),
        responseData: sender.data,
        schoolId,
      } as SurveyResponse;

      submitResponse(surveyResponse)
        .then(() => {
          message.success({
            key: "submit-feedback-form-success",
            content: t("Thanks for your feedback!"),
          });
        })
        .catch((e: Error) => {
          console.error(e);
          message.success({
            key: "submit-feedback-form-error",
            content: t("Something went wrong, please try again"),
          });
        })
        .finally(() => toast.done(loadingMsg));
    });

    return localModel;
  }, [surveyForm.surveyData]);

  if (!model) return null;

  return <Survey model={model} />;
};

export default SurveyModel;
