type ErrorWithMessage = {
  message: string;
};

interface ValidateErrorEntity {
  values: {
    [path: string]: string;
  };
  errorFields: {
    name: (string | number)[];
    errors: string[];
  }[];
  outOfDate: boolean;
}

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  // ANTD error
  if ((maybeError as ValidateErrorEntity)?.errorFields) {
    return new Error(
      (maybeError as ValidateErrorEntity).errorFields
        ?.map((field) => field.errors.join(", "))
        .join(", ")
    );
  }

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
}

export function getErrorMessage(error: unknown) {
  return toErrorWithMessage(error).message;
}
