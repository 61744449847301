import { ReactNode } from "react";

const TabContentWrapper = ({
  children,
  mobile,
  noMessages,
}: {
  children: ReactNode;
  mobile: boolean;
  noMessages: boolean;
}) => (
  <div
    className={"md:overflow-scroll relative md:h-auto"}
    style={{
      height: !mobile && !noMessages ? "calc(100vh - 290px)" : "250px",
      maxHeight: 500,
    }}
  >
    {children}
  </div>
);

export default TabContentWrapper;
