/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Timestamp, Timezone } from "@kanpla/types";
import { getDefaultDateSeconds } from "./getDefaultDateSeconds";

interface Props {
  date?: Timestamp;
  deadline: number | false | undefined; // seconds
  timezone: Timezone;
  deadlineWeekRelative?: boolean;
  deadlineExcludingWeekends?: boolean;
  includesWeekend?: boolean;
  /**
   * To compare deadline to a different date than right now
   * This is e.g. useful to compare if print can safely be printed after deadline
   */
  customDateSeconds?: number;
}

export const isDatePastDeadline = (props: Props) => {
  const {
    date,
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    includesWeekend = false,
    timezone,
    customDateSeconds,
  } = props;

  if (!deadline) return false;
  if (!date) return true;

  // Seconds of the first day available for order
  const defaultDate = getDefaultDateSeconds({
    deadline,
    deadlineWeekRelative,
    deadlineExcludingWeekends,
    includesWeekend,
    timezone,
  });

  // @ts-ignore
  const dateSeconds = customDateSeconds || date.seconds || date._seconds;

  const isPastDeadline = dateSeconds < defaultDate;

  return isPastDeadline;
};
