import { useT } from "@kanpla/system";

export const useCalendarFormatter = (hasTranslations = true) => {
  const t = useT();

  if (!hasTranslations || false)
    return {
      sameDay: `[i dag]`,
      nextDay: `[i morgen]`,
      nextWeek: "dddd",
      lastDay: `[i går]`,
      lastWeek: `[sidste] dddd`,
      sameElse: "dddd, D/M",
    };

  const calendarFormatter = {
    sameDay: `[${t("Today").toLowerCase()}]`,
    nextDay: `[${t("tomorrow")}]`,
    nextWeek: "dddd",
    lastDay: `[${t("yesterday")}]`,
    lastWeek: `[${t("last")}] dddd`,
    sameElse: "dddd, D/M",
  };

  return calendarFormatter;
};
