import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classNames from "classnames";
import * as React from "react";
import { useState } from "react";

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={classNames(
      "z-50 overflow-hidden rounded-md border border-secondary-main bg-background-primary px-3 py-1.5 text-sm text-text-primary shadow-md animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      className
    )}
    {...props}
  />
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface TooltipProps {
  /** How the event that triggers the tooltip */
  trigger?: "hover" | "click";
  /** The content of the tooltip */
  content?: React.ReactNode;
  /** Open by default */
  defaultOpen?: boolean;
  /** Displays the tooltip with a pointing arrow to the trigger element. Defaults to `true` */
  withArrow?: boolean;
  /** Conditionally disable tooltip */
  disabled?: boolean;
}

type Props = TooltipProps &
  React.ComponentPropsWithoutRef<typeof TooltipContent>;

const Tooltip = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {
    trigger = "hover",
    children,
    content,
    defaultOpen = false,
    disabled = false,
    asChild = false,
    ...rest
  } = props;
  const [open, setOpen] = useState(defaultOpen);

  return (
    <TooltipProvider delayDuration={0}>
      <TooltipRoot open={disabled ? false : open} onOpenChange={setOpen}>
        <TooltipTrigger
          type="button"
          asChild={asChild}
          onClick={() => trigger === "click" && setOpen(true)}
        >
          {children}
        </TooltipTrigger>
        <TooltipContent ref={ref} {...rest}>
          {content}
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  );
});

export {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipRoot,
  TooltipTrigger,
};
