import { db, getUserWalletBalance } from "@kanpla/system";
import { User } from "@kanpla/types";
import { activeCurrencyAtom } from "@kanpla/ui";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { paymentGatewayIdAtom } from "../components/contextProvider";

interface Props {
  user: User;
  schoolId: string;
}

export const useBalance = ({ user, schoolId }: Props) => {
  const [databaseUser] = useDocumentData<User>(
    user?.id ? db.collection("users").doc(user?.id) : null
  );

  const [balance, setBalance] = useState<number | null>(null);
  const activeCurrency = useAtomValue(activeCurrencyAtom);
  const paymentGatewayId = useAtomValue(paymentGatewayIdAtom);

  const wallets = databaseUser?.wallets || user?.wallets;

  useEffect(() => {
    const legacyBalance =
      databaseUser?.balances?.[activeCurrency] ??
      user?.balances?.[activeCurrency] ??
      null;

    const latestBalance = wallets
      ? getUserWalletBalance({
          wallets,
          activeCurrency,
          paymentGatewayId,
        })
      : legacyBalance;

    setBalance(latestBalance || 0);
  }, [schoolId, wallets, paymentGatewayId, activeCurrency]);

  return { user, balance, setBalance };
};
